


















import { defineComponent, ref } from '@nuxtjs/composition-api'

import useDefaultMeta from '@/composables/use-default-meta'
import useI18n from '@/composables/use-i18n'
import useLayoutCommon from '@/composables/use-layout-common'

export default defineComponent({
  head: {},
  setup () {
    const i18n = useI18n()
    useDefaultMeta({
      title: String(i18n.t('head.index.title')),
      description: String(i18n.t('head.index.description')),
    })
    useLayoutCommon()

    const isShowPopup = ref(false)

    const setPopup = () => {
      isShowPopup.value = true
    }

    const refresh = () => {
      isShowPopup.value = false
      window.location.reload()
    }

    return {
      isShowPopup,
      setPopup,
      refresh,
    }
  },
})
