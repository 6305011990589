



















import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    code: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: null,
    },
  },
  setup (_, { emit }) {
    const copied = ref(false)

    const copy = () => {
      emit('copy')
      copied.value = true
    }

    return {
      copied,
      copy,
    }
  },
})
