import { Organization, WithContext } from 'schema-dts'

export const ORGANIZATION_JSONLD: WithContext<Organization> = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'gogoout',
  url: 'https://gogoout.com',
  logo: 'https://gogoout.com/images/misc/logo.png',
  image: 'https://gogoout.com/images/misc/logo.png',
  sameAs: [
    'https://www.linkedin.com/company/gogoout',
    'https://www.facebook.com/gogooutt',
    'https://www.instagram.com/gogooutcom',
    'https://www.youtube.com/channel/UCJxRh1sebjJsWmoDXNiXYRQ',
  ],
  telephone: '02-7752-3598',
  address: '104台北市中山區林森北路380號5樓之一',
  email: 'service@gogoout.com',
}
