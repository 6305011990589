import { isNumber, isString, isObject } from 'typechecker'
import dayjs, { Dayjs, UnitType } from 'dayjs'
import duration from 'dayjs/plugin/duration'

import DateHour from '@/classes/DateHour'
import Period from '@/interfaces/Period'
import Store from '@/classes/Store'

dayjs.extend(duration)

export interface QueryObject {
  startDate?: string | null
  startHour?: string | number
  endDate?: string | null
  endHour?: string | number
}

/** 日期小時區間 */
export default class DateHourRange {
  start = new DateHour()
  end = new DateHour()

  constructor (start?: DateHour, end?: DateHour) {
    if (start) this.setStart(start)
    if (end) this.setEnd(end)
  }

  setStart (dateHour: DateHour) {
    this.start = dateHour
  }

  setEnd (dateHour: DateHour) {
    this.end = dateHour
  }

  setStartEndDate ({ start, end }: { start?: Dayjs, end?: Dayjs }) {
    if (start) this.start.setDate(start)
    if (end) this.end.setDate(end)
  }

  /** 深度克隆自己以避免副作用 */
  clone () {
    return new DateHourRange(
      this.start.clone(),
      this.end.clone()
    )
  }

  /** 開始時間是否為過期 */
  isStartExpired (compareTo: Dayjs = dayjs()): boolean {
    return !!this.start.instance?.isBefore(compareTo)
  }

  /** 開始時間是否為過期 */
  isEndExpired (compareTo: Dayjs = dayjs()): boolean {
    return !!this.end.instance?.isBefore(compareTo)
  }

  /** 此日期小時區間的期間 */
  get period (): Period | undefined {
    if (!this.isValid) return
    const start = this.start.date?.set('h', this.start.hour)
    const end = this.end.date?.set('h', this.end.hour)
    if (!(start && end)) return

    const duration = dayjs.duration(end.diff(start))
    return {
      day: duration.days(),
      hour: duration.hours(),
    }
  }

  get isDateEmpty () {
    return !(this.start.date && this.end.date)
  }

  /** 起訖「日期」是否一模一樣 */
  get isDateSame (): boolean {
    return Boolean(
      this.end.date && this.start.date?.isSame(this.end.date)
    )
  }

  /** 起訖「時間」是否一模一樣 */
  get isHourSame (): boolean {
    return this.start.hour === this.end.hour
  }

  /** 起訖「日期時間」是否一模一樣 */
  get isDateHourSame (): boolean {
    return this.isDateSame && this.isHourSame
  }

  get isStartAfterEnd (): boolean {
    // 是否為同日但取車時間 >= 還車時間
    const isInvalidHour = this.end.date &&
      this.start.date?.isSame(this.end.date) &&
      this.start.hour >= this.end.hour

    // 是否為取車日期 > 還車日期
    const isInvalidDate = this.start.date &&
      this.end.date?.isBefore(this.start.date)

    return Boolean(isInvalidHour || isInvalidDate)
  }

  get isValid (): boolean {
    return !(this.isDateEmpty || this.isStartAfterEnd || this.isDateHourSame)
  }

  /** URL 查詢參數。相對於 `get object ()` 所有值都已轉換成字串 */
  get queryObject () {
    const { start, end } = this

    return {
      startDate: start?.dateString,
      startHour: String(start?.hour),
      endDate: end?.dateString,
      endHour: String(end?.hour),
    }
  }

  static queryObjectKeys: (keyof QueryObject)[] = [
    'startDate',
    'startHour',
    'endDate',
    'endHour',
  ]

  get object () {
    const { start, end } = this

    return {
      startDate: start?.dateString,
      startHour: start?.hour,
      endDate: end?.dateString,
      endHour: end?.hour,
    }
  }

  /** 從 URL 查詢參數產生 */
  static fromQueryObject (query: QueryObject): DateHourRange | undefined {
    try {
      if (!isObject(query)) throw new TypeError('`query` must be an object!')

      const { startDate, startHour, endDate, endHour } = query

      if (!isString(startDate)) {
        throw new TypeError('`startDate` must be a string!')
      }
      if (!(isString(startHour) || isNumber(startHour))) {
        throw new TypeError('`startHour` must be a string or a number!')
      }
      if (!isString(endDate)) {
        throw new TypeError('`endDate` must be a string!')
      }
      if (!(isString(endHour) || isNumber(endHour))) {
        throw new TypeError('`endHour` must be a string or a number!')
      }

      const range = new this(
        new DateHour(startDate, Number(startHour)),
        new DateHour(endDate, Number(endHour))
      )

      if (range.isValid) return range
    } catch (e) {
    }
  }

  /** 從 Store 營業時間邏輯產生 */
  static fromStore (store: Store, offset: number = 2, offsetUnit: UnitType = 'day', today = dayjs()): DateHourRange | undefined {
    if (!store.operationHour) return undefined

    try {
      const startDate = today.add(offset, offsetUnit)
      const endDate = today.add(offset + 1, offsetUnit)
      // 星期幾
      const startDayOfWeek = startDate.toDate().getDay()
      const endDayOfWeek = endDate.toDate().getDay()

      let startHour: number
      let endHour: number

      // 起始小時
      if ([6, 0].includes(startDayOfWeek)) {
        startHour = store.operationHour.weekend[0]
      } else {
        startHour = store.operationHour.weekday[0]
      }

      // 結束小時
      if ([6, 0].includes(endDayOfWeek)) {
        endHour = store.operationHour.weekend[0]
      } else {
        endHour = store.operationHour.weekday[0]
      }

      if (startHour && endHour) {
        const range = new this(
          new DateHour(startDate, Number(startHour)),
          new DateHour(endDate, Number(endHour))
        )
        if (range.isValid) return range
      }
      return undefined
    } catch (e) {

    }
  }
}
