





























import { PropType, defineComponent, onMounted, reactive, ref, toRefs, useContext, useRoute, computed } from '@nuxtjs/composition-api'

import GField from '@/components/GField.vue'
import GFieldBirthday from '@/components/GFieldBirthday.vue'

import useCheckUser from '@/composables/use-check-user'
import useConfirmForm from '@/composables/use-confirm-form'
import useConfirmUserInfo from '@/composables/use-confirm-user-info'
import useI18n from '@/composables/use-i18n'
import useRedirect from '@/composables/use-redirect'

import LoginStep from '@/enums/LoginStep'
import ThirdParty from '@/enums/ThirdParty'

import ggoApi from '@/modules/ggo-api'
import alert from '@/modules/alert'
import dataLayer from '@/modules/data-layer'
import ggoLogger from '@/modules/ggo-logger'
import { LogName } from '@/interfaces/Logger'
import { RegisterDto } from '@/types/ggo-api/dto'

export default defineComponent({
  props: {
    loginStep: {
      type: Number as PropType<LoginStep>,
      default: LoginStep.LOGIN_OR_SIGNUP,
    },
    showGoBack: {
      type: Boolean,
      default: true,
    },
    // 登入後 callback (optional)
    onLoginCb: {
      type: Function as PropType<() => void>,
    },
    // 註冊後 callback (optional)
    onSignedUpCb: {
      type: Function as PropType<() => void>,
    },
  },
  setup (props, { emit }) {
    const { query } = useContext()
    const i18n = useI18n()
    const route = useRoute()

    const { checkEmailExist } = useCheckUser()
    const { redirectToPreLink } = useRedirect()

    const { user, fetchUser } = useConfirmUserInfo()

    const step = ref<LoginStep>(LoginStep.LOGIN_OR_SIGNUP)
    const nextStep = (e: LoginStep) => {
      step.value = e
    }

    const {
      fields,
    } = useConfirmForm()

    // 第一關：檢查 email 是否存在
    const checkEmail = async () => {
      localStorage.setItem('temp-link', route.value.fullPath)

      if (!fields.email.validate()) return
      if (!fields.email.valid) return
      const exist = await checkEmailExist(fields.email.value)
      if (exist) {
        step.value = LoginStep.LOGIN
      } else {
        step.value = LoginStep.SIGNUP
        setDisabled(true)
      }
    }

    // 第二關：若不存在則註冊
    const signup = async () => {
      if (!validate()) return
      const exist = await checkEmailExist(fields.email.value)
      if (exist) {
        fields.password.value = ''
        alert(i18n.t('notice.alreadyHaveAccount'))
        step.value = LoginStep.LOGIN
        return
      }
      /** 註冊 */
      let params: RegisterDto = {
        firstName: fields.firstName.value,
        lastName: fields.lastName.value,
        email: fields.email.value,
        password: fields.password.value,
        isSendNews: fields.isSendNews.value,
        birthday: fields.birthday.value,
        locale: i18n.locale,
      }
      const invitedId = fields.invitedId.value
      if (invitedId) {
        params = {
          ...params,
          invitedId,
        }
      }
      const { success } = await ggoApi.register(params)
      if (success) {
        await dataLayer.signUp('general')

        // eslint-disable-next-line no-undef
        fbq('track', 'CompleteRegistration')

        /** 註冊完成移除存放的 invitedId */
        localStorage.removeItem('invitedId')
        /** 註冊成功後自動登入 */
        const params = {
          email: fields.email.value,
          password: fields.password.value,
        }

        const { data, success } = await ggoApi.login(params)
        if (success && data) {
          localStorage.setItem('token', data.token)

          await ggoApi.updateUserInfo({ locale: i18n.locale })
          await fetchUser()
          if (user.value?.id) {
            dataLayer.sendUserId(user.value.id)
          }
        }
      } else {
        alert(i18n.t('user.registration.failed'))
      }
      emit('close-modal')
      redirectToPreLink()
      location.reload()
    }

    onMounted(() => {
      if (query.value.id) {
        localStorage.setItem('invitedId', query.value.id as string)
        fields.invitedId.value = query.value.id as string
      }
      /** 抓到 id 後清空 params 以防止使用者更改推薦人 id */
    })

    // 第二關：若存在則登入
    const login = async () => {
      if (!fields.password.validate()) return
      if (!fields.password.valid) return
      const params = {
        email: fields.email.value,
        password: fields.password.value,
      }
      const { success, data, status } = await ggoApi.login(params)
      if (success && data) {
        localStorage.setItem('token', data.token)

        await ggoApi.updateUserInfo({ locale: i18n.locale })
        await fetchUser()

        if (user.value?.id) {
          dataLayer.sendUserId(user.value.id)

          await dataLayer.login('general')
        }

        emit('close-modal')
      } else if (status === 403) {
        ggoLogger.info(LogName.TOAST, {
          type: 'danger',
          message: String(i18n.t('user.login.banned')),
        })
        alert(i18n.t('user.login.banned'))
      } else {
        ggoLogger.info(LogName.TOAST, {
          type: 'danger',
          message: String(i18n.t('user.login.failed')),
        })
        alert(i18n.t('user.login.failed'))
      }
      location.reload()
    }

    const resetPassword = async () => {
      const params = {
        email: fields.email.value,
      }

      const { success } = await ggoApi.sendResetPasswordEmail(params)
      if (success) {
        alert(i18n.t('user.reset.success'))
        emit('close-modal')
      } else {
        alert(i18n.t('message.error'))
      }
    }

    const pressLogin = ref(false)

    /** 判斷是否從 LoginOrSignup 來的
     *  若為 true，代表此 email 已被驗證無人註冊過
     *  設置 email 欄位為 disabled
    */
    const isDisabled = ref<Boolean>(false)
    const setDisabled = (disabled:Boolean) => {
      isDisabled.value = disabled
    }

    const goBack = () => {
      step.value = LoginStep.LOGIN_OR_SIGNUP
      setDisabled(false)
    }

    const fieldTemplateRefs = reactive({
      firstName: null as InstanceType<typeof GField> | null,
      lastName: null as InstanceType<typeof GField> | null,
      email: null as InstanceType<typeof GField> | null,
      password: null as InstanceType<typeof GField> | null,
      birthday: null as InstanceType<typeof GFieldBirthday> | null,
    })

    const invalidElements = ref<Element[]>([])

    /** 所有不合法的元素隊列，待稍後滾動至該元素處用 */
    const pushInvalidElement = (el: Element | undefined | null) => {
      if (el) invalidElements.value.push(el)
    }

    const validate = () => {
      // 將上一次的錯誤清空
      invalidElements.value = []

      fields.lastName.resetField()
      if (!fields.lastName.validate()) {
        pushInvalidElement(fieldTemplateRefs.lastName?.$el)
      }

      fields.firstName.resetField()
      if (!fields.firstName.validate()) {
        pushInvalidElement(fieldTemplateRefs.firstName?.$el)
      }

      fields.email.resetField()
      if (!fields.email.validate()) {
        pushInvalidElement(fieldTemplateRefs.email?.$el)
      }

      fields.password.resetField()
      if (!fields.password.validate()) {
        pushInvalidElement(fieldTemplateRefs.password?.$el)
      }

      fields.birthday.resetField()
      if (!fields.birthday.validate()) {
        pushInvalidElement(fieldTemplateRefs.birthday?.$el)
      }

      const isValid = !invalidElements.value.length

      return isValid
    }

    onMounted(() => {
      // 初始化欄位
      fields.email.resetField()
      if (props.loginStep) step.value = props.loginStep
    })

    const cardClass = computed(() => ({
      'signup-card': step.value === LoginStep.SIGNUP,
    }))

    const onLogin = () => {
      if (props.onLoginCb instanceof Function) {
        props.onLoginCb()
      } else {
        location.reload()
      }
    }
    const onSignedUp = () => {
      if (props.onSignedUpCb instanceof Function) {
        props.onSignedUpCb()
      } else {
        redirectToPreLink()
        location.reload()
      }
    }

    return {
      ThirdParty,

      LoginStep,

      isDisabled,

      step,
      nextStep,

      fields,
      checkEmail,
      resetPassword,

      pressLogin,

      setDisabled,
      login,
      signup,
      goBack,

      firstNameField: toRefs(fieldTemplateRefs).firstName,
      lastNameField: toRefs(fieldTemplateRefs).lastName,
      emailField: toRefs(fieldTemplateRefs).email,
      passwordField: toRefs(fieldTemplateRefs).password,
      birthdayField: toRefs(fieldTemplateRefs).birthday,

      cardClass,

      onSignedUp,
      onLogin,
    }
  },
})
