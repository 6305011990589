enum LoginStatus {
  // 使用者被 ban
  USER_FORBIDDEN = 1,
  // 使用者取消授權
  USER_CANCEL = 2,
  // 第三方不明原因失敗
  THIRD_PARTY_ERROR = 3,
}

export default LoginStatus
