import { useToggle } from '@vueuse/core'

export default function () {
  const [isShow, toggle] = useToggle(false)
  const show = () => { isShow.value = true }
  const hide = () => { isShow.value = false }

  return {
    isShow,
    show,
    hide,
    toggle,
  }
}
