








import { defineComponent, watch } from '@nuxtjs/composition-api'

import useFreezeBodyScroll from '@/composables/use-freeze-body-scroll'
import useOmnichatOverride from '@/composables/use-omnichat-override'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const { freeze, unfreeze } = useFreezeBodyScroll()
    const { show: showOmnichat, hide: hideOmnichat } = useOmnichatOverride()

    watch(
      () => props.value,
      (value) => {
        if (value) {
          freeze()
          hideOmnichat()
        } else {
          unfreeze()
          showOmnichat()
        }
      },
      { immediate: true }
    )
  },
})
