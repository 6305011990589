












import { defineComponent, toRefs, watch } from '@vue/composition-api'
import useFreezeBodyScroll from '@/composables/use-freeze-body-scroll'
import useOmnichatOverride from '@/composables/use-omnichat-override'
import useProxyValue from '@/composables/use-proxy-value'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { freeze, unfreeze } = useFreezeBodyScroll()
    const { show: showOmnichat, hide: hideOmnichat } = useOmnichatOverride()
    const { proxyValue } = useProxyValue(toRefs(props).value, emit)

    const panelClass = {
      left: props.left,
      right: !props.left,
    }

    watch(
      () => proxyValue.value,
      (value) => {
        if (value) {
          freeze()
          hideOmnichat()
        } else {
          unfreeze()
          showOmnichat()
        }
      },
      { immediate: true }
    )

    return {
      panelClass,
    }
  },
})
