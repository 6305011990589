






















import { defineComponent, ref } from '@nuxtjs/composition-api'

import useConfirmForm from '@/composables/use-confirm-form'
import useConfirmUserInfo from '@/composables/use-confirm-user-info'
import useI18n from '@/composables/use-i18n'

import LoginStep from '@/enums/LoginStep'
import ThirdParty from '@/enums/ThirdParty'
import alert from '@/modules/alert'
import dataLayer from '@/modules/data-layer'
import ggoApi from '~/modules/ggo-api'
import ggoLogger from '~/modules/ggo-logger'
import { LogName } from '~/interfaces/Logger'

export default defineComponent({
  props: {
    email: {
      type: String || null,
      default: null,
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()

    const { user, fetchUser } = useConfirmUserInfo()

    const step = ref<LoginStep>(LoginStep.LOGIN_OR_SIGNUP)

    const {
      fields,
    } = useConfirmForm()
    fields.email.value = props.email

    const login = async () => {
      if (!fields.password.validate()) return
      const params = {
        email: fields.email.value,
        password: fields.password.value,
      }

      const { success, status, data } = await ggoApi.login(params)

      if (!success || !data) {
        if (status === 403) {
          ggoLogger.info(LogName.TOAST, {
            type: 'danger',
            message: String(i18n.t('user.login.banned')),
          })
          alert(i18n.t('user.login.banned'))
        } else {
          ggoLogger.info(LogName.TOAST, {
            type: 'danger',
            message: String(i18n.t('user.login.failed')),
          })
          alert(i18n.t('user.login.failed'))
        }
      } else {
        localStorage.setItem('token', data.token)

        await ggoApi.updateUserInfo({ locale: i18n.locale })
        await fetchUser()

        if (user.value?.id) {
          dataLayer.sendUserId(user.value.id)

          await dataLayer.login('general')
        }

        emit('close-modal')

        emit('on-login')
      }
    }

    const resetPassword = async () => {
      const params = {
        email: fields.email.value,
      }
      const { success } = await ggoApi.sendResetPasswordEmail(params)
      if (success) {
        alert(i18n.t('user.reset.success'))
        emit('close-modal')
      }
    }

    return {
      ThirdParty,

      LoginStep,

      step,
      fields,
      resetPassword,

      login,
    }
  },
})
