/* eslint-disable camelcase */
import {
  EcommerceItem,
  EcommerceAddToCartData,
  EcommerceBeginCheckoutData,
  EcommercePurchaseData,
  EcommerceAddPaymentInfoData,
  EcommerceSelectItemData,
  EcommerceViewItemData,
  EcommerceViewItemListData,
  EcommerceRefundData,
  SignUpMethod,
  LoginMethod,
} from '@/interfaces/DataLayer'
import PricedCar from '@/classes/PricedCar'
import Store from '@/classes/Store'
import Vendor from '@/enums/Vendor'
import HertzPricedCar from '@/classes/Hertz/HertzPricedCar'
import HertzStore from '@/classes/Hertz/HertzStore'
import Car from '@/classes/Car'
import HertzCar from '@/classes/Hertz/HertzCar'

declare global {
  interface Window { dataLayer: any; }
}

export class DataLayer {
  dataLayer: {
    push: Function
  } = {
    push: () => {},
  }

  constructor () {
    this.setDataLayer()
  }

  setDataLayer () {
    // Client side 才觸發
    if (!process.server) { this.dataLayer = window.dataLayer }
  }

  selectItem (data: EcommerceSelectItemData) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_select_item',
      ga4_event_name: 'select_item',
      ecommerce: data,
    })
  }

  viewItem (data: EcommerceViewItemData) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_view_item',
      ga4_event_name: 'view_item',
      ecommerce: data,
    })
  }

  viewItemList (data: EcommerceViewItemListData) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_view_item_list',
      ga4_event_name: 'view_item_list',
      ecommerce: data,
    })
  }

  refund (data: EcommerceRefundData) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_refund',
      ga4_event_name: 'refund',
      ecommerce: data,
    })
  }

  addPaymentInfo (data: EcommerceAddPaymentInfoData) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_add_payment_info',
      ga4_event_name: 'add_payment_info',
      ecommerce: data,
    })
  }

  beginCheckout (data: EcommerceBeginCheckoutData) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_begin_checkout',
      ga4_event_name: 'begin_checkout',
      ecommerce: data,
    })
  }

  addToCart (data: EcommerceAddToCartData) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_add_to_cart',
      ga4_event_name: 'add_to_cart',
      ecommerce: data,
    })
  }

  purchase (data: EcommercePurchaseData) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_purchase',
      ga4_event_name: 'purchase',
      ecommerce: data,
    })
  }

  signUp (method: SignUpMethod) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_sign_up',
      method,
    })
  }

  login (method: LoginMethod) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'td_login',
      method,
    })
  }

  sendUserId (userID: number) {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push({
      event: 'send_userID',
      userID,
    })
  }

  static GogooutCarToEcommerceItem (car: PricedCar | Car, store: Store, quotePrice?: number): EcommerceItem {
    const { id, brand, model } = car

    const price = (car as PricedCar).price?.value ?? quotePrice

    return {
      item_id: String(id),
      item_name: `${brand?.name}/${model?.name}`,
      item_brand: store.name ?? '',
      item_category: Vendor.CAR,
      price,
      quantity: 1,
    }
  }

  static HertzCarToEcommerceItem (car: HertzPricedCar | HertzCar, store: HertzStore, quotePrice?: number): EcommerceItem {
    const { id, model, price } = car

    return {
      item_id: String(id),
      item_name: model ?? '',
      item_brand: store.name ?? '',
      item_category: Vendor.CAR,
      price: Number(price ?? quotePrice),
      quantity: 1,
    }
  }
}

const dataLayer = new DataLayer()
export default dataLayer
