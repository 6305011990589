








import { defineComponent, nextTick, onMounted, ref, watch } from '@nuxtjs/composition-api'

import useFreezeBodyScroll from '@/composables/use-freeze-body-scroll'

export default defineComponent({
  props: {
    /** 為了正確地播放 transition，以此參數來控制組件是否顯示 */
    value: {
      type: Boolean,
      default: true,
    },
    modalClass: {
      type: String,
      default: 'lg:w-120',
    },
  },
  setup (props) {
    const { freeze, unfreeze } = useFreezeBodyScroll()
    const isLoaded = ref(false)

    onMounted(async () => {
      watch(
        () => props.value,
        (value) => {
          if (value) {
            freeze()
            area.value?.scrollTo(0, 0)
          } else {
            unfreeze()
          }
        },
        { immediate: true }
      )
      await nextTick()
      isLoaded.value = true
    })

    const area = ref<HTMLDivElement>()

    return {
      isLoaded,
      area,
    }
  },
})
