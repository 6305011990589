import ggoLogger from '@/modules/ggo-logger'
import { Plugin } from '@nuxt/types'
import { getRouteNameFromUrl, getSearchParamsFromRoute } from '@/utils/route'
import { LogName } from '@/interfaces/Logger'

export default (({ route, i18n, from, app, getRouteBaseName }) => {
  ggoLogger.setup()
  ggoLogger.updateLanguage(i18n.locale)
  ggoLogger.setCurrentRoute(route.path, getRouteBaseName(route))
  ggoLogger.updateUser(app?.store?.state?.user?.user)

  // 進 client 才發送 log
  if (process.client) {
    // TODO: 抓不到 user 資訊 (若已登入)
    // 記錄頁面加載日誌 (首次進入)
    ggoLogger.info(LogName.PAGE_VIEW, {
      location: route.path || '',
      prev_location: from?.path || '',
      router_name: getRouteBaseName(route),
      referrer: document.referrer,
      referrer_name: getRouteNameFromUrl(document.referrer),
      query: getSearchParamsFromRoute(route).toString(),
    })
  }
}) as Plugin
