import RentCountry, { Country as CountryEnum } from '@/enums/Country'
import Coordinate from '@/interfaces/Coordinate'
import Shortcut from '@/interfaces/Shortcut'

export interface QueryObject {
  lat: string
  lng: string
  location?: string
  country?: string
  maxDistance?: number
}
type Name = string
type Country = string
type PlaceResult = google.maps.places.PlaceResult
type GeocoderResult = google.maps.GeocoderResult

export default class MapLocation {
  /** 座標 */
  coordinate: Coordinate
  /** 地點名稱 */
  name?: Name
  /** 國家代號 */
  country?: Country
  /** 最大搜尋距離 */
  maxDistance?: number

  constructor (
    coordinate: Coordinate,
    name?: Name | null,
    country?: Country | null,
    maxDistance?: number
  ) {
    this.coordinate = coordinate
    if (name) this.name = name
    if (country) this.country = country
    this.maxDistance = maxDistance
  }

  /** URL 查詢參數。所有值都已轉換成字串 */
  get queryObject () {
    const object: QueryObject = {
      lat: String(this.coordinate.lat),
      lng: String(this.coordinate.lng),
    }

    if (this.name) object.location = this.name
    if (this.country) object.country = this.country
    object.maxDistance = this.maxDistance

    return object
  }

  get json () {
    return JSON.stringify(this)
  }

  /** 從 URL 查詢參數產生 */
  static fromQueryObject (
    { lat, lng, location, country, maxDistance }: QueryObject
  ): MapLocation {
    const coordinate = { lat: +lat, lng: +lng }
    return new this(coordinate, location, country, maxDistance)
  }

  /** 從 Google Maps API 的 PlaceResult 產生 */
  static fromPlaceResult (
    place: PlaceResult,
    predictionName?: string
  ): MapLocation {
    if (!place.geometry) throw new Error('`geometry` is undefined!')
    const { lat, lng } = place.geometry.location

    const coordinate: Coordinate = { lat: lat(), lng: lng() }
    const name = predictionName ?? place.formatted_address
    const country = this.getCountry(place)

    return new this(coordinate, name, country, MapLocation.getMaxDistance(country))
  }

  /** 從 Google Maps API 的 fromGeocoderResult 產生 */
  static fromGeocoderResult (
    result: GeocoderResult,
    { lat, lng }: Coordinate
  ): MapLocation {
    const coordinate: Coordinate = { lat, lng }
    const name = result.formatted_address
    const country = this.getCountry(result)

    return new this(coordinate, name, country, MapLocation.getMaxDistance(country))
  }

  /** 從 JSON 字串產生 */
  static fromJson (json: string) {
    const { coordinate, name, country, maxDistance } = JSON.parse(json)
    return new this(coordinate, name, country, maxDistance)
  }

  /** 從捷徑產生 */
  static fromShortcut (shortcut: Shortcut) {
    const { location, lat, lng, countryInfo } = shortcut

    return new this(
      { lat, lng },
      location,
      countryInfo?.country ?? RentCountry.TAIWAN,
      shortcut.maxDistance
    )
  }

  /** 從 `place` 解析國家代號 */
  static getCountry (place: PlaceResult | GeocoderResult): Country | null {
    let list = place.address_components
    if (!list) return null
    list = [...list].reverse()

    const country = list.find(i => i.types.some(type => type === 'country'))
    return country ? country.short_name : null
  }

  /** 獲取預設 max distance，提供給使用者自行輸入的地址 (非來自 LocationPicker) */
  static getMaxDistance (country?: string | null) {
    switch (country) {
      case CountryEnum.JAPAN:
        return 20
      case CountryEnum.KOREA:
        return 20
      case CountryEnum.THAILAND:
        return 20
      case CountryEnum.UNITED_STATES:
        return 30
      default:
        return 10
    }
  }
}
