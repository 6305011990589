



















import { computed, defineComponent, PropType, toRefs } from '@nuxtjs/composition-api'
import { Options as PopperOptions } from '@popperjs/core'
import useProxyValue from '@/composables/use-proxy-value'
import useShowable from '@/composables/use-showable'
import { isObject } from 'typechecker'

type Item = {
  name: string
  value: string | number
  disabled?: boolean
} & {
  [key: string]: string
} | string | number

export default defineComponent({
  props: {
    value: {
      default: null,
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'name',
    },
    label: {
      type: String,
      default: null,
    },
    list: {
      type: Array as PropType<Item[] | null>,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    menuOffset: {
      type: Array as PropType<number[]>,
      default: () => [0, 20],
    },
  },
  setup (props, { emit, attrs }) {
    const { show, isShow, hide } = useShowable()
    const { proxyValue } = useProxyValue(toRefs(props).value, emit)

    const options: PopperOptions = {
      placement: 'bottom-start',
      strategy: 'absolute',
      modifiers: [{ name: 'offset', options: { offset: props.menuOffset } }],
    }

    const bind = computed(() => ({
      ...attrs,
    }))

    const getItem = (value: Item) => {
      if (isObject(value) && typeof value === 'object') return value
      return {
        name: value,
        [props.itemValue]: value,
      }
    }

    const selectClass = computed(() => ({
      error: props.error,
    }))

    const getMenuItemClass = (item: Item) => {
      const _class = {} as {[key: string]: any}
      if (typeof item === 'object' && 'disabled' in item) {
        _class.disabled = item.disabled
      }
      if (typeof getItem(item) === 'object') {
        _class.selected = getItem(item)[props.itemValue] === props.value
      }

      return _class
    }

    const onMenuItemClick = (item: Item) => {
      hide()
      emit('input', getItem(item)[props.itemValue])
      emit('click', getItem(item)[props.itemValue])
    }

    const clickOutside = () => {
      if (isShow.value) {
        hide()
        emit('click-outside')
      }
    }

    return {
      proxyValue,
      bind,
      getItem,
      selectClass,
      options,
      show,
      isShow,
      hide,
      getMenuItemClass,
      onMenuItemClick,
      clickOutside,
    }
  },
})
