export enum Country {
  TAIWAN = 'TW',
  JAPAN = 'JP',
  CANADA = 'CA',
  GUAM = 'GU',
  UNITED_STATES = 'US',
  KOREA = 'KR',
  THAILAND = 'TH'
}

export default Country
