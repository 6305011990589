






import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    headers: {
      type: Array as PropType<String[]>,
      default: [],
    },
  },
})
