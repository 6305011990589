



















import { computed, defineComponent } from '@nuxtjs/composition-api'
import useFreezeBodyScroll from '@/composables/use-freeze-body-scroll'
import useI18n from '~/composables/use-i18n'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const i18n = useI18n()

    useFreezeBodyScroll().setup()

    const loadingImg = require('@/assets/img/car-loading.gif')
    const loadingImgAlt = computed(() =>
      props.type === 'pay'
        ? i18n.t('loading.pay.title')
        : i18n.t('loading.search.title'),
    )

    return {
      loadingImg,
      loadingImgAlt,
    }
  },
})
