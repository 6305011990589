















import { computed, defineComponent, PropType, watch } from '@nuxtjs/composition-api'
import { isArray } from 'typechecker'
import ggoLogger from '@/modules/ggo-logger'
import { LogName } from '@/interfaces/Logger'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    message: {
      type: [String, Array] as PropType<string | string[] | null>,
      default: null,
    },
  },
  setup (props, { emit }) {
    const img = 'https://cdn.gogoout.com/web/timeout_artBoard.png'

    const close = () => emit('input', false)

    const list = computed(() =>
      (props.message && isArray(props.message))
        ? props.message as string[]
        : [props.message as string]
    )

    /** 出現 Alert 時, 送 error toast logs */
    watch(list, (messages) => {
      if (props.value) {
        for (const message of messages) {
          ggoLogger.info(LogName.TOAST, { type: 'danger', message })
        }
      }
    }, { immediate: true })

    return {
      close,
      list,
      img,
    }
  },
})
