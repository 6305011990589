import { nextTick, onBeforeUnmount, onMounted, Ref, SetupContext, unref } from '@nuxtjs/composition-api'
import { useEventListener } from '@vueuse/core'
import { createPopper, Options, Instance } from '@popperjs/core'

export default function (
  activator: Ref<HTMLElement | undefined>,
  menu: Ref<HTMLElement | undefined>,
  options: Ref<Options | null> | Options,
  whitelist: Ref<Array<Element | undefined>>,
  { emit }: SetupContext,
) {
  let popper: Instance

  // 初始化 Popper
  onMounted(async () => {
    await nextTick()
    popper = createPopper(
      <HTMLElement>unref(activator),
      <HTMLElement>unref(menu),
      unref(options) ?? {}
    )
  })

  // 銷毀 Popper
  onBeforeUnmount(() => popper?.destroy?.())

  /** 處理點擊選單外事件 */
  const handler = ({ target }: Event) => {
    if (whitelist.value.every(
      el => !(el === target || el?.contains(target as Node))
    )) {
      emit('click-outside')
    }
  }
  onMounted(() => useEventListener(document.body, 'mousedown', handler))
  const update = () => popper?.update()

  return {
    update,
  }
}
