import { reactive, ref } from '@nuxtjs/composition-api'
import { whenever } from '@vueuse/core'

export default function <T> (
  defaultValue: T,
  rule?: (value: T) => string | boolean,
) {
  const value = ref<T>(defaultValue)
  const dirty = ref(false)
  const touched = ref(false)
  const disabled = ref(false)
  const valid = ref<boolean | null>(null)
  const errorMessage = ref<string | boolean | null>(null)

  const validate = (): boolean => {
    if (rule) {
      const result = rule(value.value as T)
      if (result === true) {
        resetField()
        valid.value = true
        return true
      } else {
        setError((typeof result === 'string') ? result : '')
        return false
      }
    } else {
      resetField()
      return true
    }
  }

  const resetField = () => {
    valid.value = null
    errorMessage.value = null
  }

  const setError = (message: string) => {
    valid.value = false
    errorMessage.value = message
  }

  const handleChange = () => {
    dirty.value = true
  }

  const handleBlur = () => {
    if (dirty.value) validate()
    touched.value = true
  }

  whenever(() => value.value && dirty.value && !valid.value, validate)

  return reactive({
    value,
    dirty,
    disabled,
    valid,
    errorMessage,
    validate,
    resetField,
    setError,
    handleChange,
    handleBlur,
  })
}
