/* global google */

// https://developers.google.com/maps/documentation/javascript/overview
import Coordinate from '@/interfaces/Coordinate'
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader'
export * from '@googlemaps/js-api-loader'

export const loader = (options?: LoaderOptions) => {
  return new Loader({
    apiKey: process.env.GOOGLE_MAPS_API_KEY ?? '',
    version: 'weekly',
    libraries: ['places'],
    ...options,
  })
}

/** 檢查全域的 `google` 是否已載入 */
export const checkIsGoogleLoaded = () => {
  if (typeof google === 'undefined') {
    throw new GoogleMapsError('`google` is not loaded!')
  }
}

/**
 * 僅會顯示這些國家的搜尋結果
 * 國家代號請參考 https://en.wikipedia.org/wiki/ISO_3166-1
 */
const componentRestrictions: google.maps.places.ComponentRestrictions = {
  country: [
    'tw', /* Taiwan */
    'jp', /* Japan */
    'kr', /* South Korea */
    'my', /* Malaysia */
    'us', /* United States of America */
    'ca', /* Canada */
    'gu', /* Guam */
  ],
}

export const generateAutocompleteSessionToken = () => {
  checkIsGoogleLoaded()
  return new google.maps.places.AutocompleteSessionToken()
}

export class GoogleMapsError extends Error {
  constructor (message?: string) {
    super(message)
    this.name = this.constructor.name
  }
}

/** 根據搜尋文字取得 5 筆概略預測結果 (僅地點描述，未包含經緯度) */
export const fetchPredictions = (
  input: string,
  sessionToken?: google.maps.places.AutocompleteSessionToken,
) => {
  checkIsGoogleLoaded()

  const service = new google.maps.places.AutocompleteService()
  const options: google.maps.places.AutocompletionRequest = {
    componentRestrictions,
    input,
    sessionToken,
  }

  return new Promise((resolve) => {
    service.getPlacePredictions(options, (predictions, status) => resolve(
      status === google.maps.places.PlacesServiceStatus.OK
        ? predictions
        : []
    ))
  }) as Promise<google.maps.places.AutocompletePrediction[]>
}

/** 根據某筆預測結果取得精確地點結果 */
export const fetchDetail = (
  prediction: google.maps.places.AutocompletePrediction,
  sessionToken?: google.maps.places.AutocompleteSessionToken,
) => {
  checkIsGoogleLoaded()

  const element = document.createElement('div')
  const service = new google.maps.places.PlacesService(element)
  const placeId = prediction.place_id
  const mainText = prediction.structured_formatting.main_text
  const options: google.maps.places.PlaceDetailsRequest = {
    fields: [
      'geometry',
      'address_components',
      'formatted_address',
    ],
    placeId,
    sessionToken,
  }

  return new Promise((resolve, reject) => {
    service.getDetails(options, (place, status) => {
      return (status === google.maps.places.PlacesServiceStatus.OK)
        ? resolve({ place, mainText })
        : reject(new GoogleMapsError('Failed to get PlaceResult!'))
    })
  }) as Promise<{place: google.maps.places.PlaceResult, mainText: string}>
}

/** 根據經緯度取得 GeocoderResult */
export function geocode (
  { lat, lng }: Coordinate
): Promise<google.maps.GeocoderResult> {
  checkIsGoogleLoaded()

  const latLng = new google.maps.LatLng(lat, lng)
  const geocoder = new google.maps.Geocoder()

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latLng }, (results, status) => {
      return (status === google.maps.GeocoderStatus.OK)
        ? resolve(results[0])
        : reject(new GoogleMapsError('Failed to get GeocoderResult!'))
    })
  })
}
