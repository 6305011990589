import { render, staticRenderFns } from "./GCheckbox.vue?vue&type=template&id=7893c601&scoped=true&lang=pug&"
import script from "./GCheckbox.vue?vue&type=script&lang=ts&"
export * from "./GCheckbox.vue?vue&type=script&lang=ts&"
import style0 from "./GCheckbox.vue?vue&type=style&index=0&id=7893c601&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7893c601",
  null
  
)

export default component.exports