import { Plugin } from '@nuxt/types'
import { PRESERVED_LIST_SET } from '@/composables/use-preserved-query'
import GgoTrackKey from '@/enums/GgoTrackKey'

const COOKIE_OPTION = {
  path: '/',
  sameSite: 'Lax',
  maxAge: 86400 * 30,
}

export default (({ route, app }) => {
  /**
   * 新開分頁時將發起者的參數穿透
   */
  if (process.server) {
    const preservedQuery: Record<string, string> = {}

    Object.entries(route.query).forEach(([key, value]) => {
      if (PRESERVED_LIST_SET.has(key)) preservedQuery[key] = value as string
    })

    const searchParams = new URLSearchParams(preservedQuery)

    app.$cookies.set(GgoTrackKey.UTM, searchParams.toString(), COOKIE_OPTION)
  }
}) as Plugin
