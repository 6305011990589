


























import { defineComponent, ref, toRefs, useRoute, watch } from '@nuxtjs/composition-api'

import useProxyValue from '@/composables/use-proxy-value'
import useCheckUser from '@/composables/use-check-user'
import useConfirmForm from '@/composables/use-confirm-form'

import LoginStep from '@/enums/LoginStep'
import ThirdParty from '@/enums/ThirdParty'

import { appleOauthUri, facebookOauthUri, googleOauthUri } from '@/modules/get-oauth-uri'
import useI18n from '@/composables/use-i18n'

export default defineComponent({
  props: {
    value: {
      type: String || null,
      default: null,
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()
    const { proxyValue } = useProxyValue(toRefs(props).value, emit)

    const {
      fields,
    } = useConfirmForm()
    fields.email.value = proxyValue.value

    const route = useRoute()

    const { checkEmailExist } = useCheckUser()

    const step = ref<LoginStep>(LoginStep.LOGIN_OR_SIGNUP)

    // 第一關：檢查 email 是否存在
    const checkEmail = async () => {
      localStorage.setItem('temp-link', route.value.fullPath)

      if (!fields.email.validate()) return
      const exist = await checkEmailExist(fields.email.value)
      if (exist) {
        emit('next-step', LoginStep.LOGIN)
      } else {
        emit('next-step', LoginStep.SIGNUP)
      }
    }

    const loginThirdParty = (source: ThirdParty) => {
      localStorage.setItem('temp-link', route.value.fullPath)
      pressLogin.value = true
      let redirectUrl

      switch (source) {
        case ThirdParty.GOOGLE:
          redirectUrl = googleOauthUri({ locale: i18n.locale })
          break
        case ThirdParty.APPLE:
          redirectUrl = appleOauthUri({ locale: i18n.locale })
          break
        case ThirdParty.FACEBOOK:
          redirectUrl = facebookOauthUri({ locale: i18n.locale })
          break
      }

      location.href = redirectUrl
      pressLogin.value = false
    }

    const pressLogin = ref(false)

    watch(fields.email, (v) => {
      proxyValue.value = v.value
    })

    return {
      ThirdParty,
      loginThirdParty,

      LoginStep,

      step,
      fields,
      checkEmail,

      pressLogin,
    }
  },
})
