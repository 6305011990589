import { MutationTree, ActionTree } from 'vuex'
import { loader, LoaderOptions } from '@/modules/google-maps-api'

class State {
  isLoaded: boolean = false
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  setIsLoaded (state) {
    state.isLoaded = true
  },
}

export const actions = <ActionTree<State, any>>{
  async load ({ commit }, payload: LoaderOptions) {
    if (!process.client) return

    await loader(payload).load() // 載入完成後可以在全域取用 `google`
    commit('setIsLoaded')
  },
}
