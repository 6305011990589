



























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { CheckReviewResponse } from '@/types/ggo-api/responses'

export default defineComponent({
  props: {
    review: {
      type: Object as PropType<CheckReviewResponse>,
      required: true,
    },
  },
  setup (_, { emit }) {
    const close = () => {
      emit('close-modal')
    }

    return {
      close,
    }
  },
})
