






import { computed, defineComponent, PropType } from '@vue/composition-api'
import Order from '@/classes/Order'
import Vendor from '@/enums/Vendor'
import PaymentStatus from '~/enums/PaymentStatus'

export default defineComponent({
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    /** 無法點擊單號 */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { paid, vendor, orderNum } = props.order

    const checkOrder = () => {
      if (!props.disabled) {
        emit('check-order')
      }
    }

    const orderNumClass = computed(() => ({
      href: !props.disabled,
    }))

    return {
      orderNumClass,
      Vendor,

      PaymentStatus,
      paid,
      vendor,
      orderNum,

      checkOrder,
    }
  },
})
