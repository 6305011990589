import { Ref, unref, useContext } from '@nuxtjs/composition-api'

import { isObject, isString } from 'typechecker'

import Car from '@/classes/Car'
import DateHourRange from '@/classes/DateHourRange'

import AddOn from '@/interfaces/AddOn'
import CreditCardPayment from '@/interfaces/CreditCardPayment'
import { Flight } from '@/interfaces/Flight'
import { Invoice } from '@/interfaces/Invoice'
import { ConfirmPayment } from '@/interfaces/Payment'
import { Promotion } from '@/interfaces/Promotion'

import PromotionID from '@/enums/PromotionID'
import GgoResponse from '@/helpers/ggo-response'
import ggoApi from '@/modules/ggo-api'
import { UpdateUserInfoResponse } from '@/types/ggo-api/responses'
import alert from '@/modules/alert'
import Reference from '@/classes/Reference'
import Quote from '@/classes/Quote'
import useLanguageSwitcher, { LOCAL_STORAGE_LOCALE_CODE_KEY } from './use-language-switcher'
import ggoLogger from '~/modules/ggo-logger'
import { LogName, LogToastMsg } from '~/interfaces/Logger'

type orderNumber = string

export type ErrorMessages = Record<string, string[]>

export default function ({ firstName, lastName, email, mobile, phoneCode, phoneNumber, birthday, identity, nationality, passport, carReferenceId, car, range, promotion, flight, addOn, payment, invoice, creditCardInfo, hertzMembershipId, flightNumber, quote, reference }: {
  firstName?: Ref<string>,
  lastName?: Ref<string>,
  email?: Ref<string>,
  mobile?: Ref<string>,
  phoneCode?: Ref<string>,
  phoneNumber?: Ref<string>,
  birthday?: Ref<string>,
  identity?: Ref<string>,
  nationality?: Ref<string>,
  passport?: Ref<string>,
  carReferenceId?: Ref<string>,
  payment?: Ref<ConfirmPayment | null>,

  car?: Ref<Car | null>,
  range?: Ref<DateHourRange | null>,
  promotion?: Promotion,
  flight?: Flight,
  addOn?: AddOn,
  invoice?: Ref<Invoice | null>,

  flightNumber?: Ref<string | null>,
  hertzMembershipId?: Ref<string | null>,

  creditCardInfo?: CreditCardPayment,

  quote?: Ref<Quote | null>,
  reference?: Ref<Reference | null>,
}) {
  const { query, params } = useContext()
  const { currentLocaleCode } = useLanguageSwitcher()

  /**
   * 更新會員資料
   */
  const updateUser = async () => {
    const body = {
      firstName: unref(firstName),
      lastName: unref(lastName),
      mobile: unref(mobile),
      phoneCode: unref(phoneCode),
      phoneNumber: unref(phoneNumber),
      birthday: unref(birthday),
    }

    let res: GgoResponse<UpdateUserInfoResponse> | null = null
    if (unref(passport)) res = await ggoApi.updateUserInfo({ ...body, passport: unref(passport) })
    else res = await ggoApi.updateUserInfo({ ...body, identity: unref(identity) })

    if (!res || !res?.success) {
      ggoLogger.info(LogName.TOAST, {
        type: 'danger',
        message: LogToastMsg.UPDATE_USER_PROFILE_ERROR,
      })
      throw new TypeError('Invalid parameters')
    }
  }

  const createOrder = async (): Promise<orderNumber> => {
    const body: any = {
      ...unref(range)?.object,
      payment: payment?.value,
      invoice: unref(invoice),

      promotion,
      optionalItems: addOn?.optionalItems ?? null,
      serviceItems: addOn?.serviceItems ?? null,
      referralAds: addOn?.referralAds ?? null,
      flight,
      token: query.value?.token,
      referenceCode: reference?.value?.referenceCode,
    }

    if (promotion && !promotion.id) {
      delete body.promotion
    }

    // 被阻擋任一優惠的 reference code 在使用其他 promotion 時不應帶 reference code 在下單 API 裡
    if (!quote?.value?.promotion?.enable && promotion?.id !== PromotionID.DISTRIBUTION_PARTNER) {
      delete body.referenceCode
    }

    if (!body.optionalItems?.length) {
      delete body.optionalItems
    }

    if (!body.serviceItems?.length) {
      delete body.serviceItems
    }

    if (!body.referralAds?.length) {
      delete body.referralAds
    }

    if (!body.flight.start.date || !body.flight.start.number || !body.flight.end.date || !body.flight.end.number) {
      delete body.flight
    }

    if (!body.token) {
      delete body.token
    }

    const carId = car?.value?.id

    if (carId === null || carId === undefined) {
      const error = { error: ['Missing `carId`!'] } as ErrorMessages
      throw error
    }

    if (car?.value) {
      const { data, success, error } = await ggoApi.createGogooutOrder({
        id: car.value.id,
        body,
      })

      if (!success || !data) throw error

      if (!(isObject(data) && isString(data.orderNumber))) {
        const error = { error: ['`Response.orderNumber` must be a string!'] } as ErrorMessages
        throw error
      } else return data.orderNumber
    } else throw new TypeError('Car is empty')
  }

  /**
   * 以訂單編號取得「前往金流付款頁面的必要資訊」
   * @param orderNumber 訂單編號
   * @returns 前往金流付款頁面的必要資訊
   */
  const createPayment = async (orderNumber: orderNumber) => {
    const { data, success, error } = await ggoApi.getPaymentInfo({ orderNum: orderNumber })

    if (!success) {
      const e = { error: [error] } as ErrorMessages
      throw e
    }
    if (!data || !isObject(data)) {
      const error = { error: ['Response body must be an object!'] } as ErrorMessages
      throw error
    }
    if (![data.url, data.method].every(isString)) {
      const error = { error: ['`url` and `method` should be a string!'] } as ErrorMessages
      throw error
    }
    if (data.data != null && !isObject(data.data)) {
      const error = { error: ['`data` must be a object!'] } as ErrorMessages
      throw error
    }

    return data
  }

  /**
   * 以指定方式前往金流付款頁面
   * @param urlform post 的 `action` 或是導向的網址
   * @param method "POST" 會觸發 form post；"GET" 為直接導向
   * @param data form post 的表單內容
   */
  const goToPaymentPage = async (
    url: string,
    method: 'POST' | 'GET',
    data?: Record<string, string>
  ) => {
    /**
     * 處理外部跳轉回order result的 workaround, 記 locale-code
     */
    localStorage.setItem(LOCAL_STORAGE_LOCALE_CODE_KEY, currentLocaleCode.value)

    if (method.trim().toUpperCase() === 'GET') { // 直接導向至網址
      location.href = url
    } else { // 建立 form 元素並 post
      const $form = document.createElement('form')
      $form.setAttribute('method', method)
      $form.setAttribute('action', url)
      if (data) {
        Object.entries(data)
          .map(([name, value]) => {
            const $input = document.createElement('input')
            $input.setAttribute('name', name)
            $input.setAttribute('value', value)
            return $input
          })
          .forEach($input => $form.appendChild($input))
      }
      document.body.appendChild($form)
      $form.submit()
      await new Promise(resolve => setTimeout(resolve, 5000))
    }
  }

  /**
   * 建立 Hertz 訂單
   */
  const createHertzOrder = async () => {
    const body: any = {
      ...unref(range)?.object,
      firstName: unref(firstName),
      lastName: unref(lastName),
      birthday: unref(birthday),
      mobile: unref(mobile),
      phoneCode: unref(phoneCode),
      phoneNumber: unref(phoneNumber),
      email: unref(email),
      nationality: unref(nationality),
      passport: unref(passport),
      cardType: creditCardInfo?.creditCardType,
      cardNumber: creditCardInfo?.creditCardNumber,
      cardExpireDate: creditCardInfo?.creditCardDate,
      startLocation: query.value.startLocation,
      endLocation: query.value.endLocation,
      carReferenceId: unref(carReferenceId),
      vendor: params.value.vendor,
      sipp: query.value.sipp,

      /** 可有可無的選項 */
      flightNumber: unref(flightNumber),
      hertzMembershipId: unref(hertzMembershipId),
    }

    if (promotion && promotion.id === PromotionID.PC) {
      body.pc = promotion.value
    }

    if (promotion && promotion.id === PromotionID.CDP) {
      body.cdp = promotion.value
    }

    const { data, success, error, status } = await ggoApi.createHertzOrder(body)
    if (!success || !data) {
      if (status === 422) {
        alert('Invalid CC Number or EXP Date')
      } else if (status === 400) {
        alert(error, {
          message: `${LogToastMsg.HERTZ_CREATE_ORDER_ERROR}: 404: ${error}`,
        })
      } else {
        alert('Something went wrong, please contact us')
      }
      throw new Error(error ?? '')
    } else {
      // HACK: 強制導入訂單完成頁面
      window.location.href = `${process.env.NUXT_BASE_URL}/zh-tw/car/hertz/result/${data.code}`
      return data
    }
  }

  return {
    updateUser,
    createOrder,
    createPayment,
    goToPaymentPage,

    createHertzOrder,
  }
}
