//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

import useI18n from '@/composables/use-i18n'

import ThirdParty from '@/enums/ThirdParty'

export default defineComponent({
  props: {
    from: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const i18n = useI18n()
    let img, imgSources, title
    switch (props.from) {
      case ThirdParty.GOOGLE:
        img = 'https://cdn.gogoout.com/web/login/google.png'
        title = i18n.t('user.login.withGoogle')
        break
      case ThirdParty.APPLE:
        img = 'https://cdn.gogoout.com/web/login/apple.png'
        title = i18n.t('user.login.withApple')
        break
      case ThirdParty.FACEBOOK:
        img = 'https://cdn.gogoout.com/web/login/facebook.png'
        title = i18n.t('user.login.withFacebook')
        break
    }

    return {
      img,
      imgSources,
      title,
    }
  },
})
