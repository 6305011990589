/** 再次嘗試前等待的毫秒數 */
const interval = 1000

/** 取得 Omnichat 按鈕元素 */
export const getActivator = async () => {
  let attempt = 5 // 嘗試取得剩餘次數
  while ((attempt--) >= 0) {
    const element = document.querySelector('#easychat-floating-button') as HTMLElement

    if (element) return element
    else await new Promise(resolve => setTimeout(resolve, interval))
  }
}
