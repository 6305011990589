import { useContext } from '@nuxtjs/composition-api'
import { useWindowSize } from '@vueuse/core'

import useIsMounted from '@/composables/use-is-mounted'
import { screens, Screen } from '@/modules/resolved-tailwind-config'

export default function () {
  const { width } = useWindowSize()
  const { $device } = useContext().app

  const isMounted = useIsMounted()

  /** 判斷目前視窗寬度是否大於等於此斷點 */
  const match = (breakpoint: Screen = 'xl'): boolean => {
    if (process.server || !isMounted.value) return $device.isDesktop

    // 為了當視窗寬度改變時重新執行此函式 breakpoint 而刻意做無意義計算
    width.value = width.value * 1

    const query = `(min-width: ${screens[breakpoint]})`
    return window.matchMedia(query).matches
  }

  return {
    match,
  }
}
