/* eslint-disable camelcase */
import Currency from '@/interfaces/Currency'
import Price, { Argument as PriceArgument } from '@/classes/Price'
import IdNameObject from '@/interfaces/IdNameObject'
import Car, { Age, CarImage, Id, Seats } from '@/classes/Car'
import { isNumber } from 'typechecker'
import DateHourRange from '@/classes/DateHourRange'

export type PricedCarArgument = {
  price: PriceArgument
  originalPrice?: PriceArgument

  id: Id
  currency?: Currency
  age?: Age
  seats: Seats
  images?: CarImage[]

  brand?: IdNameObject
  model?: IdNameObject
  type?: IdNameObject
  transmission?: IdNameObject
  equipments?: IdNameObject[]
  paymentMethods?: IdNameObject[]

  rank_score?: number
  goPoints?: number

  dateHourRange?: DateHourRange
}

/** 附有價格資訊的車輛 */
export default class PricedCar extends Car {
  /** 實際價格 */
  price: Price
  /** 僅供促銷顯示用的「原價」 */
  originalPrice?: Price
  /** 可獲得 GO Points */
  goPoints: number = 0
  /** 推薦系統 rank 分數 */
  rankScore: number = 0

  constructor (car: PricedCarArgument) {
    super(car)

    const price = Price.fromObject(car.price)
    if (price) {
      this.price = price
    } else throw new TypeError('Invalid `price` object!')

    if (car.originalPrice) {
      const originalPrice = Price.fromObject(car.originalPrice)
      if (originalPrice) this.originalPrice = originalPrice
    }

    if (isNumber(car.rank_score)) {
      this.rankScore = car.rank_score!
    }

    if (isNumber(car.goPoints)) {
      this.goPoints = car.goPoints!
    }
  }
}
