import { isArray } from 'typechecker'

import CarStore from '@/classes/CarStore'
import Store from '@/classes/Store'
import PricedCar from '@/classes/PricedCar'

import HertzCarStore from '@/classes/Hertz/HertzCarStore'
import HertzStore from '@/classes/Hertz/HertzStore'
import HertzPricedCar from '@/classes/Hertz/HertzPricedCar'

import PaymentMethod from '@/enums/PaymentMethod'

export type SortMethod = 'priceDesc' | 'priceAsc' | 'distanceDesc' | 'distanceAsc' | 'ratingDesc' | 'rankDesc'

export type PriceRange = [number, number]
export type AgeRange = string

export type OneWayRental = boolean
export type AcceptForeigner = boolean

export type Brand = number
export type CarType = number
export type StoreService = number
export type Equipment = number

// 定義 QueryObject 的 key 名
export interface QueryObject {
  sort?: string

  age?: string
  price?: string

  oneWayRental?: string
  acceptForeigner?: string

  brand?: string[] | string
  carType?: string[] | string
  paymentMethod?: string[] | string
  storeService?: string[] | string
  equipment?: string[] | string
}

/** 篩選條件 */
export default class SearchFilter {
  /** 排序方式 */
  sortMethod?: SortMethod

  /** 車齡區間 */
  ageRange?: AgeRange
  /** 價格區間 */
  priceRange?: PriceRange

  /** 是否提供甲租乙還 */
  oneWayRental: OneWayRental = false
  /** 是否接受國際旅客 */
  acceptForeigner: AcceptForeigner = false

  /** 廠牌列表 */
  brands: Brand[] = []
  /** 車型列表 */
  carTypes: CarType[] = []
  /** 付款方式列表 */
  paymentMethods: PaymentMethod[] = []
  /** 店家提供服務列表 */
  storeServices: StoreService[] = []

  /** 車輛本身包含配備列表 */
  equipments: Equipment[] = []

  /** 座位最小值 */
  seatMin?: number

  /** 座位最大值 */
  seatMax?: number

  setSortMethod (_?: SortMethod) { this.sortMethod = _ }
  setAgeRange (_?: AgeRange) { this.ageRange = _ }
  setPriceRange (_?: PriceRange) { this.priceRange = _ }
  setOneWayRental (_?: OneWayRental) { this.oneWayRental = Boolean(_) }
  setAcceptForeigner (_?: AcceptForeigner) { this.acceptForeigner = Boolean(_) }
  setBrands (_?: Brand[]) { this.brands = _ ?? [] }
  setCarTypes (_?: CarType[]) { this.carTypes = _ ?? [] }
  setPaymentMethods (_?: PaymentMethod[]) { this.paymentMethods = _ ?? [] }
  setStoreServices (_?: StoreService[]) { this.storeServices = _ ?? [] }
  setEquipments (_?: Equipment[]) { this.equipments = _ ?? [] }
  setSeatMin (_?: number) { this.seatMin = _ }
  setSeatMax (_?: number) { this.seatMax = _ }

  /** 清除所有篩選條件 */
  clear () {
    this.setSortMethod()
    this.setAgeRange()
    this.setPriceRange()
    this.setOneWayRental()
    this.setAcceptForeigner()
    this.setBrands()
    this.setCarTypes()
    this.setPaymentMethods()
    this.setStoreServices()
    this.setEquipments()
  }

  get isSortMethodActive () {
    return Boolean(this.sortMethod) && this.sortMethod !== SearchFilter.defaultSortMethod
  }

  get isAgeRangeActive () {
    return Boolean(this.ageRange)
  }

  get isPriceRangeActive () {
    return Boolean(this.priceRange)
  }

  get isOneWayRentalActive () {
    return Boolean(this.oneWayRental)
  }

  get isAcceptForeignerActive () {
    return Boolean(this.acceptForeigner)
  }

  get isBrandsActive () {
    return Boolean(this.brands.length)
  }

  get isCarTypesActive () {
    return Boolean(this.carTypes.length)
  }

  get isPaymentMethodsActive () {
    return Boolean(this.paymentMethods.length)
  }

  get isStoreServicesActive () {
    return Boolean(this.storeServices.length)
  }

  get isEquipmentsActive () {
    return Boolean(this.equipments.length)
  }

  /** 已套用的篩選條件數量 */
  get length () {
    return [
      this.isSortMethodActive,
      this.isAgeRangeActive,
      this.isPriceRangeActive,
      this.isOneWayRentalActive,
      this.isAcceptForeignerActive,
      this.isBrandsActive,
      this.isCarTypesActive,
      this.isPaymentMethodsActive,
      this.isStoreServicesActive,
      this.isEquipmentsActive,
    ].filter(Boolean).length
  }

  /** 深度克隆自己以避免副作用 */
  clone () {
    return SearchFilter.fromQueryObject(this.queryObject)
  }

  isBetween (
    value: number,
    [min, max]: [min: number | undefined, max: number | undefined]
  ) {
    // `min` 為 falsy 時視作負無限大；`max` 為 falsy 時視作無限大
    return (min || -Infinity) <= value && value <= (max || Infinity)
  }

  /** 檢查價格是否符合目前價格區間 */
  isBetweenPriceRange (price: PricedCar['price']) {
    if (!this.priceRange) return true
    return this.isBetween(Number(price), this.priceRange)
  }

  /** 檢查車齡是否符合目前的區間 */
  isBetweenAge (age: PricedCar['age']) {
    if (!this.ageRange || !age) return true
    // 解析「車齡區間」字串
    const [min, max] = this.ageRange.split(',')
    return this.isBetween(age, [Number(min), Number(max)])
  }

  // /** 檢查車輛 GPS 是否匹配目前的篩選條件 */
  // isIncludesGps (equipments: PricedCar['equipments']) {
  // }

  /** 檢查是否匹配目前的篩選條件 */
  test (carStore: CarStore | HertzCarStore): boolean
  test (car: PricedCar | HertzPricedCar, store?: Store | HertzStore | null): boolean

  test (
    carArgument: CarStore | HertzCarStore | PricedCar | HertzPricedCar,
    storeArgument?: Store | HertzStore | null
  ): boolean {
    let car: PricedCar, store: Store | null | undefined

    // 檢查參數型態
    ;(() => {
      if (carArgument instanceof CarStore) {
        car = carArgument.car
        store = carArgument.store
      } else if (carArgument instanceof PricedCar && (!storeArgument || storeArgument instanceof Store)) {
        car = carArgument
        store = storeArgument
      } else throw new TypeError('No overload matches this call!')
    })()

    if (!this.isBetweenPriceRange(car.price)) return false
    if (!this.isBetweenAge(car.age)) return false
    if (
      this.isAcceptForeignerActive && // 若有勾選「接受國際駕照」
      store && !store.acceptForeigner // 且店家顯式地拒絕國際駕照（為 `false` 而非 `nullish`）
    ) return false
    if (
      this.isOneWayRentalActive && // 若有勾選「甲租乙還」
      store?.oneWayRental === false // 且店家顯式地拒絕甲租乙還（為 `false` 而非 `nullish`）
    ) return false
    if (
      this.isBrandsActive && // 若有套用至少一個廠牌
      car.brand?.id // 且車輛有提供廠牌
    ) {
      // 若「套用的廠牌」和「車輛的廠牌」無交集
      if (!this.brands.includes(car.brand.id)) return false
    }
    if (
      this.isCarTypesActive && // 若有套用至少一個車型
      car.type?.id // 且車輛有提供車型
    ) {
      // 若「套用的車型」和「車輛的車型」無交集
      if (!this.carTypes.includes(car.type.id)) return false
    }
    if (
      this.isPaymentMethodsActive && // 若有套用至少一個付款方式
      car.paymentMethods.length // 且車輛有提供可用的付款方式
    ) {
      // 若「套用的付款方式」和「車輛提供的付款方式」無交集
      if (!car.paymentMethods.some(
        ({ id }) => this.paymentMethods.includes(id)
      )) return false
    }
    if (this.isStoreServicesActive && store) {
      // 若「店家服務」未包含所有「套用的店家服務」
      if (!this.storeServices.every(
        id => store!.services?.map(({ id }) => id).includes(id)
      )) return false
    }

    if (this.isEquipmentsActive) {
      // 若「車輛的設備」未包含所有「套用的設備」
      if (!this.equipments.every(
        id => car.equipments.map(({ id }) => id).includes(id)
      )) return false
    }

    return true
  }

  /** 預設排序方式 */
  static readonly defaultSortMethod: SortMethod = 'distanceAsc'
  /** 價格區間的分隔符號 */
  static readonly priceSeparator = '~'

  /** 將價格區間轉換成字串 */
  stringifyPriceRange (range: PriceRange): string | undefined {
    // 未選擇區間時（即預設情況）
    if (range[0] === 0 && range[1] === Infinity) return
    // 將 0 或 Infinity 轉換成空字串
    return range.map(i => (i === 0 || i === Infinity) ? '' : i)
      .join(SearchFilter.priceSeparator)
  }

  /** 將列表內所有「項目」轉換成字串 */
  stringifyList <T> (list: Array<T>): string[] {
    return list.map(String)
  }

  /** 轉換 SearchFilter -> QueryObject */
  get queryObject (): QueryObject {
    const stringifyList = <T> (list: Array<T>) => (list.length)
      ? this.stringifyList(list)
      : undefined

    return {
      sort: (this.sortMethod && this.sortMethod !== SearchFilter.defaultSortMethod)
        ? this.sortMethod
        : undefined,
      price: (this.priceRange)
        ? this.stringifyPriceRange(this.priceRange)
        : undefined,
      age: (this.ageRange) ? this.ageRange : undefined,
      oneWayRental: (this.oneWayRental) ? '1' : undefined,
      acceptForeigner: (this.acceptForeigner) ? '1' : undefined,
      brand: stringifyList(this.brands),
      carType: stringifyList(this.carTypes),
      paymentMethod: stringifyList(this.paymentMethods),
      storeService: stringifyList(this.storeServices),
      equipment: stringifyList(this.equipments),
    }
  }

  static get queryObjectKeys (): (keyof QueryObject)[] {
    return [
      'sort',
      'age',
      'price',
      'oneWayRental',
      'acceptForeigner',
      'brand',
      'carType',
      'paymentMethod',
      'storeService',
      'equipment',
    ]
  }

  /** 轉換 QueryObject -> SearchFilter */
  static fromQueryObject (query: QueryObject) {
    const filter = new this()

    filter.sortMethod = this.parseSortMethod(query.sort)

    if (query.price) filter.priceRange = this.parsePriceRange(query.price)
    if (query.age) filter.ageRange = query.age

    if (query.oneWayRental) filter.oneWayRental = true
    if (query.acceptForeigner) filter.acceptForeigner = true

    if (query.brand) filter.brands = this.parseQueryValue(query.brand)
    if (query.carType) filter.carTypes = this.parseQueryValue(query.carType)
    if (query.paymentMethod) filter.paymentMethods = this.parseQueryValue(query.paymentMethod)
    if (query.storeService) filter.storeServices = this.parseQueryValue(query.storeService)
    if (query.equipment) filter.equipments = this.parseQueryValue(query.equipment)

    return filter
  }

  /** 從字串解析排序方式 */
  static parseSortMethod (value?: string): SortMethod {
    const availableSortMethods: SortMethod[] = [
      'priceDesc',
      'priceAsc',
      'distanceDesc',
      'distanceAsc',
      'ratingDesc',
      'rankDesc',
    ]
    // 檢查值是否合法，否則直接回傳預設的排序方式
    return availableSortMethods.find(i => i === value) ??
      this.defaultSortMethod
  }

  /** 解析 QueryObject 的「數字字串」或「數字字串陣列」 */
  static parseQueryValue (
    value: Array<string | null> | string
  ): number[] {
    let rawList: (string | null)[] = []
    if (typeof value === 'string') rawList = [value] // 只有一個項目
    else if (isArray(value)) rawList = [...value] // 多個項目

    const list = (<number[]>rawList
      .map(i => (i != null) ? parseInt(i) : null)
      .filter(Boolean))
      .sort((a, b) => a - b)

    return [...new Set(list)]
  }

  /** 解析 QueryObject 的「數字範圍字串」 */
  static parsePriceRange (
    value: Array<string | null> | string
  ): [number, number] | undefined {
    try {
      const string = (typeof value === 'string') ? value : value?.[0]
      if (!string) return

      const [startString, endString] = string.split(this.priceSeparator)

      let start = (startString === '') ? 0 : parseInt(startString)
      let end = (endString === '') ? Infinity : parseInt(endString)

      if ([start, end].every(value => !Number.isNaN(value))) {
        if (start > end) [start, end] = [end, start]
        return [start, end]
      }
    } catch (e) {
    }
  }
}
