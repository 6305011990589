import { render, staticRenderFns } from "./IconsGoogleMapIcon.vue?vue&type=template&id=1b781525&lang=pug&"
import script from "./IconsGoogleMapIcon.vue?vue&type=script&lang=ts&"
export * from "./IconsGoogleMapIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GSihImage: require('/app/components/GSihImage.vue').default})
