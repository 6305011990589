








import { ref, defineComponent, onMounted, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    time: {
      type: Number,
      default: 0,
    },
    /**
     * 分成兩種觸發方式
     * 'mousemove': 時間內不移動鼠標就觸發
     * else: 倒數計時器 -> 進到頁面即開始倒數，不管如何都會觸發(通常用來抓登入過期)
     */
    type: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const popup = ref(false)

    const second = ref(0)
    let intervalId: NodeJS.Timeout

    const plus = () => {
      second.value++
    }

    const start = () => {
      intervalId = setInterval(plus, 1000)
    }

    const stop = () => {
      clearInterval(intervalId)
    }

    const mouseMove = () => {
      stop()
      second.value = 0
      start()
    }

    const touchMove = () => {
      stop()
      second.value = 0
      start()
    }

    watch(() => second.value, (val) => {
      if (val === props.time) {
        emit('popup')
      }
    })

    onMounted(() => {
      start()
    })

    return {
      mouseMove,
      touchMove,
      popup,
    }
  },
})
