













import { defineComponent, PropType } from '@vue/composition-api'

type OrderTableCellLinkValue = {
  value: string,
  link?: string
}

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<OrderTableCellLinkValue[]>,
      required: true,
    },
  },
})
