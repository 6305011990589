interface ResponseInput<T = any> {
  data: T | null,
  status: number,
  error?: string | null,
  success: boolean
}

/**
 * GgoResponse 封裝 api 回應處理
 * - null handler
 * - 預設處理
 * - mock data handler
 */
class GgoResponse<T> {
  data: T | null = null
  status: number = 404
  error: string | null = null
  success: boolean = false

  constructor (response: ResponseInput<T>) {
    this.data = response.data
    this.status = response.status
    this.error = response.error ?? null
    this.success = response.success
  }
}

export default GgoResponse
