



























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { Options } from '@popperjs/core'

import useBreakpoint from '@/composables/use-breakpoint'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    breakpoint: {
      type: String as PropType<'sm' | 'md' | 'lg' | 'xl'>,
      default: 'xl',
    },
    menuOptions: {
      type: Object as PropType<Options | null>,
      default: null,
    },
    clickOutsideWhitelist: {
      type: Array as PropType<Element[]>,
      default: () => [],
    },
    hideOnScroll: {
      type: Boolean,
      default: false,
    },
    closeActivatorClicked: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const proxyValue = computed({
      get: () => props.value,
      set: value => emit('input', value),
    })

    const { match } = useBreakpoint()
    const isUnderBreakpoint = computed(() => !match(props.breakpoint))

    return {
      proxyValue,
      isUnderBreakpoint,
    }
  },
})
