import { ref } from '@nuxtjs/composition-api'
import useLazyScript from '@/composables/use-lazy-script'
import { AfteePaymentData } from '@/types/third-party/aftee/Aftee'

export default function () {
  const { lazyInjectScript } = useLazyScript()
  const config = ref<object>({})
  const loading = ref(false)

  /**
   *
   * @param paymentData 來自 createPayment API 的回傳 data
   * @param orderNum 供付款成功後的 callback redirect
   */
  const setConfig = (paymentData: AfteePaymentData, orderNum: string) => {
    config.value = {
      pre_token: '',
      pub_key: process.env.AFTEE_PUB_KEY,
      payment: paymentData,
      // 認證完成同時、亦或會員註冊完成同時呼叫
      authenticated () {},
      // 付款popup畫面關閉同時呼叫
      cancelled () {
        window.location.reload()
      },
      // 審查結果NG後、按下關閉認證表格按鍵同時呼叫
      failed () {
        window.location.reload()
      },
      // 審查結果OK後自動關閉認證表格同時呼叫
      succeeded () {
        // HACK: 強制導入訂單完成頁面
        // TODO: 組合 url
        window.location.href = `${process.env.NUXT_BASE_URL}/zh-tw/car/order_result/${orderNum}`
      },
      // 發生錯誤時呼叫
      error () {},
    }
  }

  const load = () => {
    if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'testing') {
      alert(`
      這裡是測試區，輸入測試用的 Aftee 電話
      電話: 0909999981   預期: 非會員 信用審查結果OK
      `.trim())
    }

    loading.value = true

    if (!window.Aftee) {
      lazyInjectScript(process.env.AFTEE_SDK!, null, 0)

      const interval = setInterval(() => {
        if (window.Aftee) {
          window.Aftee.config(config.value)

          /**
           * 有機會還未掛載完 sdk 就執行的狀況，技術客服回應：讓他先 setTimeout 一秒
          */
          setTimeout(() => {
            loading.value = false

            window.Aftee.sync()
            window.Aftee.start()
          }, 1000)

          clearInterval(interval)
        }
      }, 1000)
    } else {
      window.Aftee.config(config.value)

      /**
       * 有機會還未掛載完 sdk 就執行的狀況，技術客服回應：讓他先 setTimeout 一秒
      */
      setTimeout(() => {
        loading.value = false

        window.Aftee.sync()
        window.Aftee.start()
      }, 1000)
    }
  }

  return {
    setConfig,
    load,
    loading,
  }
}
