


























import { computed, defineComponent, PropType, ref, toRefs } from '@nuxtjs/composition-api'
import useProxyValue from '@/composables/use-proxy-value'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number] as PropType<string | number>,
      default: null,
    },
    type: {
      type: String as PropType<HTMLInputElement['type']>,
      default: 'text',
    },
    disabled: {
      type: Boolean as PropType<HTMLInputElement['disabled']>,
      default: false,
    },
    name: {
      type: String as PropType<HTMLInputElement['name'] | null>,
      default: null,
    },
    autocomplete: {
      type: String as PropType<HTMLInputElement['autocomplete'] | null>,
      default: 'off',
    },
    hint: {
      type: String as PropType<String | null>,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number as PropType<Number | null>,
      default: null,
    },
  },
  setup (props, { emit, attrs }) {
    const { proxyValue } = useProxyValue(toRefs(props).value, emit)

    const bind = computed(() => ({
      type: props.type,
      name: props.name,
      autocomplete: props.autocomplete,
      disabled: props.disabled,
      maxlength: props.maxlength,
      ...attrs,
    }))

    const inputClass = computed(() => ({
      error: props.error,
    }))

    const labelClass = computed(() => ({
      'text-negative': props.error,
    }))

    const input = ref<HTMLInputElement>()
    const focus = () => input.value?.focus()

    return {
      proxyValue,
      bind,
      inputClass,
      labelClass,
      input,
      focus,
    }
  },
})
