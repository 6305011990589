import { Plugin } from '@nuxt/types'
import GgoTrackKey from '@/enums/GgoTrackKey'
import { v4 as uuidv4 } from 'uuid'
import { getTrackingCode } from '@/utils/tracking-code'

const DEVICE_ID_COOKIE_OPTION = {
  path: '/',
  sameSite: 'Lax',
  maxAge: 86400 * 365,
}
const SESSION_ID_COOKIE_OPTION = {
  path: '/',
  sameSite: 'Lax',
  maxAge: 86400 * 365,
}
const TRACKING_CODE_COOKIE_OPTION = {
  path: '/',
  sameSite: 'None',
  httpOnly: true,
  secure: true,
  maxAge: 86400, // 1 天
}

export default (({ req, app, query }) => {
  if (process.server) {
    // Tracking Code
    getTrackingCode(query) && app.$cookies.set(GgoTrackKey.TRACKING_CODE, getTrackingCode(query), TRACKING_CODE_COOKIE_OPTION)

    let deviceId = app.$cookies.get(GgoTrackKey.DEVICE_ID_STORE_KEY)
    if (!deviceId || deviceId === '') {
      deviceId = uuidv4()
    }
    app.$cookies.set(GgoTrackKey.DEVICE_ID_STORE_KEY, deviceId, DEVICE_ID_COOKIE_OPTION)

    // 沒有 referer 代表 直接拜訪
    // -> 因此給予新的 session_id
    if (!req.headers.referer) {
      app.$cookies.set(GgoTrackKey.SESSION_ID_STORE_KEY, uuidv4(), SESSION_ID_COOKIE_OPTION)
      return
    }

    const referrer: URL = new URL(req.headers.referer)
    // 同源 代表 新分頁站內跳轉/blog到主站
    // -> 沿用舊的 session_id
    if (referrer.host === req.headers.host) {
      const sessionIdMatch = req.headers.cookie?.match(/ggo_session_id=([^;]+)/)
      if (sessionIdMatch) {
        const sessionId = sessionIdMatch[1]
        app.$cookies.set(GgoTrackKey.SESSION_ID_STORE_KEY, sessionId, SESSION_ID_COOKIE_OPTION)
        return
      }
    }

    // 其他情況
    // -> 給予新的 session_id
    app.$cookies.set(GgoTrackKey.SESSION_ID_STORE_KEY, uuidv4(), SESSION_ID_COOKIE_OPTION)
  }
}) as Plugin
