import { onBeforeUnmount, onMounted, watch } from '@nuxtjs/composition-api'

import useShowable from '@/composables/use-showable'

import { getActivator } from '@/modules/omnichat-activator'

type ClassType = 'hide' | 'offset'

export default function (offset: number = 0) {
  const { isShow, show, hide } = useShowable()
  const { isShow: isMoved, show: move, hide: reset } = useShowable()

  const classPrefix = `omnichat-${Math.floor(Math.random() * 10 ** 10)}`
  const getClassName = (type: ClassType) => `${classPrefix}-${type}`

  onMounted(() => {
    const createStyle = (type: ClassType, styleBody: string) => {
      const style = `.${getClassName(type)} { ${styleBody} }`
      const styleElement = document.createElement('style')
      styleElement.appendChild(document.createTextNode(style))
      document.head.appendChild(styleElement)

      return styleElement
    }

    const list = [
      createStyle('hide', 'display: none !important;'),
      createStyle('offset', `transform: translate(0, ${-offset}px) !important`),
    ]

    onBeforeUnmount(async () => {
      const activator = await getActivator()
      activator?.classList.remove(classPrefix)
      list.forEach(el => document.head.removeChild(el))
    })

    watch(isShow, async (value) => {
      const className = getClassName('hide')
      const activator = await getActivator()
      if (value) {
        activator?.classList.remove(className)
      } else {
        activator?.classList.add(className)
      }
    }, { immediate: true })

    watch(isMoved, async (value) => {
      const className = getClassName('offset')
      const activator = await getActivator()
      if (value) {
        activator?.classList.add(className)
      } else {
        activator?.classList.remove(className)
      }
    }, { immediate: true })
  })

  return {
    show,
    hide,

    move,
    reset,
  }
}
