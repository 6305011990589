












import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  setup () {
    const isShowPopup = ref(false)

    const setPopup = () => {
      isShowPopup.value = true
    }

    const refresh = () => {
      isShowPopup.value = false
      window.location.reload()
    }

    return {
      isShowPopup,
      setPopup,
      refresh,
    }
  },
})
