











import { defineComponent, PropType } from '@vue/composition-api'

export type ActionItem = {
  value: string,
  onClick?: () => void,
  type: 'LINK' | 'BTN',
}

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<ActionItem[]>,
      default: () => [],
    },
  },
})
