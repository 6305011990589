























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import useBtn from '@/composables/use-btn'

export default defineComponent({
  props: {
    color: {
      type: String as PropType< 'primary' | 'secondary' | 'orange' | 'white' | 'facebook'>,
      default: 'secondary',
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | ''>,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean || String,
      default: false,
    },
    href: {
      type: String as PropType<string | null>,
      default: null,
    },
    target: {
      type: String as PropType<HTMLAnchorElement['target'] | null>,
      default: null,
    },
    to: [Object, String],
    id: {
      type: String || null,
      default: null,
    },
  },
  setup (props, context) {
    const tag = computed(() => {
      if (props.href) return 'a'
      else if (props.to) return 'NuxtLink'
      else return 'button'
    })

    const buttonClass = computed(() => {
      const { color, size, outlined, rounded, block, disabled } = props

      return {
        // 背景
        ...(!disabled
          ? {
              'bg-primary': color === 'primary' && !outlined,
              'bg-secondary': color === 'secondary' && !outlined,
              'bg-orange': color === 'orange' && !outlined,
              'bg-white': color === 'white' && !outlined,
              'bg-facebook': color === 'facebook' && !outlined,
              'bg-transparent': outlined,
            }
          : {
              'bg-neutral-300': !outlined,
            }
        ),

        // 框線
        border: outlined,
        ...(!disabled
          ? {
              'border-primary': color === 'primary' && outlined,
              'border-secondary': color === 'secondary' && outlined,
              'border-orange': color === 'orange' && outlined,
              'border-white': color === 'white' && outlined,
              'border-facebook': color === 'facebook' && outlined,
            }
          : {
              'border-neutral-200': true,
            }
        ),

        // 文字
        ...(!disabled
          ? {
              'text-primary':
                (color === 'primary' && outlined) ||
                (color === 'white' && !outlined),
              'text-secondary': color === 'secondary' && outlined,
              'text-orange': color === 'orange' && outlined,
              'text-facebook': color === 'facebook' && outlined,
              'text-white': (color === 'white' && outlined) || !outlined,
            }
          : {
              'text-white': !outlined,
              'text-neutral-200': outlined,
            }
        ),

        // 大小
        'px-5 py-1': size === 'xs',
        'px-3 py-1 min-w-24': size === 'sm',
        'px-4 py-2 min-w-30': size === 'md',
        'px-12 py-3': size === 'lg',

        // 滑鼠移入和按下
        ...(!disabled
          ? {
              'can-hover:hover:bg-secondary-dark active:bg-secondary-dark': color === 'secondary',

              'can-hover:hover:bg-opacity-20 active:bg-opacity-20': outlined,
              'can-hover:hover:bg-primary active:bg-primary': (color === 'primary') && outlined,
              'can-hover:hover:bg-secondary active:bg-secondary': (color === 'secondary') && outlined,
              'can-hover:hover:bg-white active:bg-white': (color === 'white') && outlined,
            }
          : {}
        ),
        // 圓角
        'rounded-full': rounded,
        'rounded-lg': !rounded,

        // 行內或塊級
        'inline-flex items-center justify-center': !block,
        'block w-full': block,

        'cursor-default': disabled,
        'whitespace-nowrap select-none text-center truncate': true,
      }
    })

    const bind = computed(() => ({
      ...context.attrs,
      disabled: props.disabled,
      class: buttonClass.value,
    }))

    return {
      tag,
      bind,
      ...useBtn(context),
    }
  },
})
