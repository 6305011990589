/* eslint-disable camelcase */
import { getDistance, isValidCoordinate } from 'geolib'
import { isArray, isBoolean, isNumber, isObject, isString } from 'typechecker'

import Coordinate from '@/interfaces/Coordinate'
import IdNameObject from '@/interfaces/IdNameObject'
import Rating from '@/interfaces/Rating'

import { getOperationHourByOperationTime } from '@/modules/operation-time'
import Score from '@/interfaces/Score'
import DateHour from '@/classes/DateHour'
import CarSource from '@/enums/CarSource'
import HertzStore from '@/classes/Hertz/HertzStore'

type OperationTimeRange = [string, string]
export type OperationTime = {
  weekend: OperationTimeRange
  weekday: OperationTimeRange
}

export type Service = IdNameObject & {
  content: string;
  list: Array<IdNameObject & {
    image_url?: string | null
  }>
}

/** 店家。這個指的並非 Vuex 的 Store */
export default class Store {
  source: CarSource = CarSource.GOGOOUT
  /** 唯一的識別符。不採用 `id` 是因為並非所有店家都擁有 id，例如 Hertz 的店家  */
  key: Symbol = Symbol('')
  /** 所謂 Store ID */
  id: number
  /** 店家名稱 */
  name?: string
  /** 店家地址 */
  address?: string
  /** 店家圖片。這個指的並非 Store 頁出現的封面圖片 */
  img?: string
  /** 此店家是否接受國際旅客 */
  acceptForeigner: boolean
  /** 此店家是否支援甲租乙還 */
  oneWayRental?: boolean
  /** 租令地國家 */
  countryCode?: string
  /** 店家座標 */
  coordinate: Coordinate
  /** 店家評分 */
  rating?: Rating
  /** 評分細項 */
  score?: Score

  /** 店家和某座標的距離，單位為 KM。 */
  distance: number = 0

  /** 店家服務列表 */
  services?: Service[]
  /** 店家政策列表 */
  policies?: IdNameObject[]

  /** 超里程說明 */
  overDistanceRule?: string
  /** 燃油政策 */
  fuelRule?: string
  /** 退訂政策 */
  cancelRule?: string

  /** 營業時間 */
  operationTime?: OperationTime

  /** 描述 */
  description?: string

  /** 店家的話 */
  announce?: string

  /** 稅籍登記名 */
  taxRegistrationName?: string

  /** 統一編號 */
  taxID?: string

  /** 所在城市 */
  city?: string

  constructor (
    store: {
      id: number
      name: string
      address: string
      image?: string
      acceptForeigner: boolean
      oneWayRental?: boolean
      countryCode?: string
      lat: number
      lng: number
      rating?: {
        score: number
        count: number
      },
      /** 評分細項 */
      score?: Score
      services?: Service[]
      policies?: IdNameObject[]
      overDistanceRule?: string
      fuelRule?: string
      cancelRule?: string
      operationTime?: OperationTime,
      description?: string,
      announce?: string,
      taxRegistrationName?: string,
      taxID?: string,
      city?: string
    },
    origin?: Coordinate
  ) {
    if (!isObject(store)) throw new TypeError('`store` must be an object!')

    if (isNumber(store.id)) {
      this.id = store.id
    } else throw new TypeError('`store.id` must be a number!')
    if (isString(store.name)) this.name = store.name
    if (isString(store.address)) this.address = store.address
    if (isString(store.image) && store.image) this.img = store.image
    this.acceptForeigner = Boolean(store.acceptForeigner)

    if (isBoolean(store.oneWayRental)) {
      this.oneWayRental = store.oneWayRental
    }

    if (isString(store.countryCode)) this.countryCode = store.countryCode

    const coordinate = { lat: store.lat, lng: store.lng }
    if (isObject(coordinate) && isValidCoordinate(coordinate)) {
      this.coordinate = { lat: store.lat, lng: store.lng }
    } else throw new Error('`store.coordinate` is not valid!')
    if (store.rating) this.rating = store.rating
    if (store.score) this.score = store.score

    if (isArray(store.services) && store.services?.every(isObject)) {
      this.services = store.services
    }
    if (isArray(store.policies) && store.policies?.every(isObject)) {
      this.policies = store.policies
    }
    if (isString(store.overDistanceRule)) this.overDistanceRule = store.overDistanceRule
    if (isString(store.fuelRule)) this.fuelRule = store.fuelRule
    if (isString(store.cancelRule)) this.cancelRule = store.cancelRule

    if (
      store.operationTime &&
      isObject(store.operationTime) &&
      Object.values(store.operationTime).every(
        item => isArray(item) && item.length === 2
      )
    ) {
      this.operationTime = store.operationTime
    }

    if (isString(store.description)) this.description = store.description
    if (isString(store.announce)) this.announce = store.announce
    if (isString(store.taxRegistrationName)) this.taxRegistrationName = store.taxRegistrationName
    if (isString(store.taxID)) this.taxID = store.taxID

    if (isString(store.city)) this.city = store.city

    if (origin) this.calcDistance(origin)
  }

  /** 根據某座標來計算並設定 `distance` */
  calcDistance (origin: Coordinate) {
    if (!(isObject(origin) && isValidCoordinate(origin))) {
      throw new Error('`origin` is not valid!')
    }

    const distance = getDistance(origin, this.coordinate) / 1000
    this.distance = Number(distance.toFixed(1))
  }

  get operationHour () {
    if (!this.operationTime) return
    const { weekend, weekday } = this.operationTime

    return {
      weekend: getOperationHourByOperationTime(weekend),
      weekday: getOperationHourByOperationTime(weekday),
    }
  }

  /** 至此店家 Store 頁的連結 */
  get storeLink () {
    return `/store/${this.id}`
  }

  /** 至此店家 Store 頁的連結 */
  get fullStoreLink () {
    return new URL(`/store/${this.id}`, process.env.NUXT_BASE_URL).toString()
  }

  /** 至此店家 Comment 頁的連結 */
  get commentLink () {
    return `/comment/${this.id}`
  }

  /** Google Map Link */
  get GoogleMapLink () {
    if (!this.address) return `http://www.google.com/maps/place/${this.coordinate.lat},${this.coordinate.lng}`
    if (!this.coordinate) return `https://www.google.com/maps/search/${this.address}`

    return `https://www.google.com/maps/search/${this.address}/@${this.coordinate.lat},${this.coordinate.lng}`
  }

  /** 拿取營業時間區間 */
  public getHourRange (numOfWeek?: number): [number, number] {
    if (!this.operationHour) return DateHour.hourRange as [number, number]

    if (numOfWeek === 6 || numOfWeek === 0) {
      return this.operationHour.weekend
    } else {
      return this.operationHour.weekday
    }
  }
}

export const isStore = (value: Store | HertzStore | null): value is Store => {
  return value?.source === CarSource.GOGOOUT
}
