import { useContext } from '@nuxtjs/composition-api'

export default function () {
  const { app: { localePath } } = useContext()
  const redirectToPreLink = () => {
    const link = localStorage.getItem('temp-link')
    localStorage.removeItem('temp-link')

    if (link) {
      window.location.href = localePath(link)
    } else {
      window.location.href = localePath('/')
    }
  }

  return {
    redirectToPreLink,
  }
}
