import GgoTrackKey from '@/enums/GgoTrackKey'
import Cookies from 'js-cookie'

export const PRESERVED_LIST_SET = new Set([
  'referenceCode',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
])

export default function () {
  /**
   * @param {string} url - http://example.com or http://example.com?query1=1 or /abc
   * @returns {string} - http://example.com?query2=2 or http://example.com?query1=1&query2=2 or /abc?query2=2
   */
  const generateUrl = (url: string) => {
    const searchParams = Cookies.get(GgoTrackKey.UTM) ?? ''

    const separator = url.includes('?') ? '&' : '?'

    return searchParams.toString() ? `${url}${separator}${searchParams.toString()}` : url
  }

  return {
    generateUrl,
  }
}
