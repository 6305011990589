export enum OrderStatus {
  // 訂單狀態, 0=>未取車, 1=>已取車, 2=>已還車, 3=>已取消, 4=>過期, 5=>保留
  ALIVE = 'alive',
  DELIVERED = 'delivered',
  COMPLETE = 'complete',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  HOLD = 'hold',
}

export default OrderStatus
