import { onBeforeUnmount, onMounted, onUnmounted } from '@nuxtjs/composition-api'

export default function () {
  const className = `frozen-${Math.floor(Math.random() * 10 ** 10)}`

  if (process.client) {
    const { head } = document

    const styleElement = document.createElement('style')

    // `padding-right` 是為了維持桌面裝置原有的捲軸寬度
    const style = `body.${className} {
      overflow: hidden;
    }`

    styleElement.appendChild(document.createTextNode(style))
    head.appendChild(styleElement)

    onUnmounted(() => head.removeChild(styleElement))
  }

  const classList = process.client
    ? document.querySelector('body')?.classList
    : null

  /** 禁止 body 捲軸滾動 */
  const freeze = () => classList?.add(className)
  /** 恢復 body 捲軸滾動 */
  const unfreeze = () => setTimeout(() => {
    classList?.remove(className)
  }, 300)

  onUnmounted(unfreeze)

  const setup = () => {
    onMounted(freeze)
    onBeforeUnmount(unfreeze)
  }

  return {
    freeze,
    unfreeze,
    setup,
  }
}
