









import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'

import useDefaultMeta from '@/composables/use-default-meta'
import useI18n from '@/composables/use-i18n'
import useLayoutCommon from '@/composables/use-layout-common'
import useSeoDisabled from '@/composables/SEO/use-seo-disabled'

export default defineComponent({
  head: {},
  setup () {
    const { app: { store } } = useContext()
    const user = computed(() => store?.state.user.user)
    const isFetching = computed(() => store?.state.user.isFetching)

    const i18n = useI18n()

    useDefaultMeta({
      title: String(i18n.t('head.index.title')),
      description: String(i18n.t('head.index.description')),
    })
    // 不讓爬蟲爬到
    useSeoDisabled()

    useLayoutCommon()

    return {
      user,
      isFetching,
    }
  },
})
