


















import { defineComponent, PropType } from '@vue/composition-api'
import Order from '@/classes/Order'
import useOrderListData from '@/composables/use-order-list-data'

export default defineComponent({
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup (props) {
    const {
      orderTime,
      productName,
      startStoreLink,
      price,
      refundRate,
      refund,
    } = useOrderListData(props.order)

    return {
      orderTime,
      productName,
      startStoreLink,
      price,
      refundRate,
      refund,
    }
  },
})
