import { ActionTree, MutationTree } from 'vuex'
import gbClient from '@/modules/growthbook/gb-client'

class State {
  flags = {} as Record<string, any>
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  setFlags (state, flags: Record<string, any>) {
    state.flags = flags
  },
}

export const actions = <ActionTree<State, any>>{
  init ({ commit }) {
    if (process.client) {
      const fetchFlags = () => {
        commit('setFlags', gbClient.getFeatureMap())
      }

      gbClient.init(fetchFlags)

      fetchFlags()
    }
  },
}
