import { computed, unref, useMeta, useRoute } from '@nuxtjs/composition-api'
import { MaybeRef } from '@vueuse/core'

export default function ({ title, description }: {
  title: MaybeRef<string>,
  description: MaybeRef<string>,
}) {
  const route = useRoute()
  const path = computed(() => route.value.fullPath)

  const metaImg = require('@/assets/img/meta/og-image.png')

  useMeta(() => ({
    title: unref(title),
    meta: [
      // General
      { hid: 'description', name: 'description', content: unref(description) },

      // Open Graph (og:*)
      { hid: 'og:site_name', property: 'og:site_name', content: 'gogoout' },
      { hid: 'og:title', property: 'og:title', content: unref(title) },
      { hid: 'og:url', property: 'og:url', content: getFullUrl(unref(path)) },
      { hid: 'og:description', property: 'og:description', content: unref(description) },

      // Twitter (twitter:*)
      { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
      { hid: 'twitter:title', name: 'twitter:title', content: unref(title) },
      { hid: 'twitter:description', name: 'twitter:description', content: unref(description) },
      { hid: 'twitter:url', name: 'twitter:url', content: getFullUrl(unref(path)) },

      { hid: 'og:image', property: 'og:image', content: metaImg },
      { hid: 'twitter:image', name: 'twitter:image', content: metaImg },
    ],
  }))
}

/** 取得包含 domain 和 path 的完整 URL */
export function getFullUrl (path: string): string {
  const base = process.env.NUXT_BASE_URL ?? 'https://gogoout.com'
  return String(new URL(path, base))
}
