import { computed, Ref, SetupContext, unref } from '@nuxtjs/composition-api'

export default function (checked: Ref | undefined, { emit }: SetupContext) {
  const proxyChecked = computed({
    get: () => unref(checked),
    set: value => emit('change', value),
  })

  return {
    proxyChecked,
  }
}
