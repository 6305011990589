








import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    hint: {
      type: String as PropType<String | null>,
      default: null,
    },
    error: {
      type: [Boolean, String, null] as PropType<Boolean | String | null>,
      default: null,
    },
  },
  setup (props) {
    const hintClass = computed(() => ({
      'text-negative': props.error,
      'text-neutral-400': !props.error,
    }))

    return {
      hintClass,
    }
  },
})
