export enum InvoiceType {
  PERSON = 'person',
  COMPANY = 'company'
}

export enum InvoicePersonTo {
  EMAIL = 1,
  MOBILE_CODE = 2,
  NATURE = 3,
  DONATE = 4,
}
