import { isNumber, isString, isObject } from 'typechecker'
import Currency from '@/interfaces/Currency'

const { format } = new Intl.NumberFormat('en-US')

export interface Argument {
  value: number
  currency: Currency
}

export default class Price {
  value: number
  currency: Currency = {
    code: '',
    sign: '$',
  }

  constructor (
    value: number,
    currency: Currency
  ) {
    if (isNumber(value)) {
      this.value = Number(value)
    } else throw new TypeError('`value` must be a number!')

    if (isObject(currency)) {
      if (isString(currency.code)) this.currency.code = currency.code
      if (isString(currency.sign)) this.currency.sign = currency.sign
    }
  }

  valueOf () {
    return this.value
  }

  get formattedValue (): string {
    return format(this.value)
  }

  get fullFormattedValue (): string {
    // hard code assign sign
    return `${this.currency.code} ${this.formattedValue}`
  }

  static fromObject (object: Argument) {
    if (!isObject(object)) throw new TypeError('`object` must be an object!')
    if (!isNumber(object.value)) throw new TypeError('`object.value` must be a number!')
    if (!isObject(object.currency)) throw new TypeError('`object.currency` must be an object!')
    return new this(Number(object.value), object.currency)
  }
}
