



















































import User from '@/classes/User'
import useI18n from '@/composables/use-i18n'
import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import alert from '@/modules/alert'

export default defineComponent({
  props: {
    mobile: Boolean,
    userLoaded: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object as PropType<User | null>,
      default: null,
    },
  },
  setup (props, { emit }) {
    const { app: { localePath } } = useContext()
    const i18n = useI18n()

    const links = [
      {
        name: String(i18n.t('link.profile')),
        link: localePath('/member/account'),
      },
      {
        name: String(i18n.t('link.order')),
        link: localePath('/member/order'),
      },
      {
        name: String(i18n.t('link.review')),
        link: localePath('/member/rating'),
      },
      // {
      //   name: String(i18n.t('link.paymentMethod')),
      //   link: localePath('/member/payment'),
      // },
    ]

    const copyLink = () => {
      const id = props.user?.id
      const link = process.env.NUXT_BASE_URL + localePath(`/member/signup?id=${id}`)
      navigator.clipboard.writeText(link).then(() => {
        alert(String(i18n.t('message.success.copy')))
      })
    }

    const signOut = () => {
      emit('sign-out')
    }

    return {
      links,
      copyLink,
      signOut,
    }
  },
})
