import { isBoolean, isNumber, isObject } from 'typechecker'

import IdNameObject from '@/interfaces/IdNameObject'
import HertzCar from '@/classes/Hertz/HertzCar'
import CarSource from '@/enums/CarSource'
const { isArray } = Array

export type Id = number
export type Age = number
export type Seats = number
export type CarImage = string
export type IsDomestic = boolean

export type CarArgument = {
  id: Id
  age?: Age
  seats: Seats
  images?: CarImage[]
  brand?: IdNameObject
  model?: IdNameObject
  type?: IdNameObject
  transmission?: IdNameObject
  equipments?: IdNameObject[]
  paymentMethods?: IdNameObject[]
  insurances?: IdNameObject[]
  isDomestic?: IsDomestic
}

/** 車輛 */
export default class Car {
  source: CarSource = CarSource.GOGOOUT
  key: Symbol = Symbol('')
  /** 所謂的 Car ID */
  id: Id
  /** 車齡 */
  age?: Age
  /** 座位數 */
  seats?: Seats
  /** 車輛圖片列表 */
  images: CarImage[] = []
  /**
   * 廠牌
   * @example { id: 1, name: "三菱/MITSUBISHI" }
   */
  brand?: IdNameObject
  /**
   * 車款
   * @example { id: 1, name: "COLT+" }
   */
  model?: IdNameObject
  /**
   * 車型
   * @example { id: 1, name: "轎車" }
   */
  type?: IdNameObject
  /**
   * 排檔
   * @example { id: 1, name: "自排" }
   */
  transmission?: IdNameObject
  /**
   * 設備列表
   * @example [ { id: 1, name: "行車記錄器" }, ... ]
   */
  equipments: IdNameObject[] = []
  /**
   * 支援的付款方式列表
   * @example [ { id: 1, name: "信用卡" }, ... ]
   */
  paymentMethods: IdNameObject[] = []
  /** 保險列表 */
  insurances: IdNameObject[] = []
  /** 是否為國產車 */
  isDomestic?: IsDomestic

  constructor (car: CarArgument) {
    if (!isObject(car)) throw new TypeError('`car` must be an object!')

    if (isNumber(car.id)) {
      const { id } = car
      this.id = id
      this.key = Symbol(id)
    } else throw new TypeError('`car.id` must be a number!')
    if (isNumber(car.age)) this.age = car.age
    if (isNumber(car.seats)) {
      this.seats = car.seats
    } else throw new TypeError('`car.seats` must be a number!')

    if (isArray(car.images)) this.images = car.images
    if (isObject(car.brand)) this.brand = car.brand
    if (isObject(car.model)) this.model = car.model
    if (isObject(car.type)) this.type = car.type
    if (isObject(car.transmission)) this.transmission = car.transmission
    if (
      isArray(car.equipments) && car.equipments.every(isObject)
    ) this.equipments = car.equipments
    if (
      isArray(car.paymentMethods) && car.paymentMethods.every(isObject)
    ) this.paymentMethods = car.paymentMethods
    if (
      isArray(car.insurances) && car.insurances.every(isObject)
    ) this.insurances = car.insurances
    if (isBoolean(car.isDomestic)) this.isDomestic = car.isDomestic
  }

  /** 至 Confirm 頁的連結 */
  get link () {
    return `/car/confirm/${this.id}`
  }
}

export const isCar = (value: Car | HertzCar | null): value is Car => {
  return value?.source === CarSource.GOGOOUT
}
