
































































import Vendor from '@/enums/Vendor'
import useBreakpoint from '@/composables/use-breakpoint'
import useI18n from '@/composables/use-i18n'
import useOrder from '@/composables/use-order'
import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { GetCancelOrderResponse } from '@/types/ggo-api/responses'

export default defineComponent({
  props: {
    refundDetail: {
      type: Object as PropType<GetCancelOrderResponse>,
      default: {},
    },
  },
  setup (props, { emit }) {
    const { match } = useBreakpoint()
    const isMobile = !match('lg')

    const i18n = useI18n()
    const { getRefundPercentage } = useOrder()

    const isRead = ref(true)

    const refund = computed(() => props.refundDetail)
    const rentPrice = computed(() => props.refundDetail?.price?.value)
    const refundPrice = computed(() => props.refundDetail?.refund?.value)
    const hasCancellationProtection = computed(() => props.refundDetail?.hasCancellationProtection)

    const getRefundContext = (e:number) => {
      switch (e) {
        case 1:
          return String(i18n.t('cancelTerms.flexible'))
        case 2:
          return String(i18n.t('cancelTerms.moderate'))
        case 3:
          return String(i18n.t('cancelTerms.strict'))
        case 4:
          return String(i18n.t('cancelTerms.super.flexible'))
      }
    }

    const refundGuaranteeLink = computed(() => {
      switch (i18n.locale) {
        case 'zh-tw':
          return 'https://gogoout.com/blog/flexible-cancellation-protection/'
        default:
          return 'https://gogoout.com/blog/en/flexible-cancellation-protection/'
      }
    })

    const confirm = () => {
      emit('close-modal')
      // 只有 gogoout 車款會跳出「退款比例」視窗，因此這邊可以直接寫 'car'
      emit('cancel-order', props.refundDetail.order_no, Vendor.CAR)
    }

    /** 暴力跳轉到聯繫我們的 fb messenger */
    const contactUs = () => {
      window.location.href = 'https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F1112879292097753%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink%26source_id%3D1441792%26recurring_notification%3D0'
    }

    return {
      isMobile,

      confirm,

      isRead,

      refund,
      rentPrice,
      refundPrice,
      hasCancellationProtection,

      getRefundContext,
      getRefundPercentage,

      refundGuaranteeLink,

      contactUs,
    }
  },
})
