

































































































































import useBreakpoint from '@/composables/use-breakpoint'
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import OrderDetail from '@/classes/OrderDetail'
import useCancelTerms from '@/composables/use-cancel-terms'
import useI18n from '@/composables/use-i18n'

export default defineComponent({
  props: {
    orderDetail: {
      type: Object as PropType<OrderDetail>,
      required: true,
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()
    const { match } = useBreakpoint()
    const isMobile = !match('lg')

    const detail = computed(() => {
      return props.orderDetail
    })

    const closeModal = () => {
      emit('close-modal')
    }

    const orderNum = computed(() => detail.value.orderNum)
    const startStorePhone = computed(() => detail.value.startStorePhone)
    const startStoreAddress = computed(() => detail.value.startStoreAddress)
    const startTime = computed(() => detail.value.startTime)
    const endTime = computed(() => detail.value.endTime)
    // FIXME: Payment methods 是array, 有可能會有兩個值的情況發生嗎？
    const paymentMethods = computed(() => detail.value.paymentMethods[0]?.name)
    const invoiceInfo = computed(() => detail.value.invoiceInfo)
    const storeServices = computed(() => {
      if (detail.value?.storeServices?.length !== 0) {
        return detail.value.storeServices
      } else {
        return null
      }
    })
    const optionalItems = computed(() => {
      if (detail.value?.optionalItems?.length !== 0) {
        return detail.value.optionalItems
      } else {
        return null
      }
    })

    const name = computed(() => detail.value.name)
    const identity = computed(() => detail.value.identity)
    const passport = computed(() => detail.value.passport)
    const birthday = computed(() => detail.value.birthday)
    const mobile = computed(() => detail.value.mobile)
    const email = computed(() => detail.value.email)

    const carImage = computed(() => detail.value.carImage)
    const carEquip = computed(() => detail.value.carEquipments)
    const carInsurance = computed(() => detail.value.carInsurances)
    const productName = computed(() => detail.value.productName)
    const storePolicy = computed(() => detail.value.storePolicies)
    const paymentPrecaution = computed(() => detail.value.paymentPrecaution)
    const distanceRule = computed(() => detail.value.distanceRule)
    const fuelRule = computed(() => detail.value.fuelRule)
    const cancelRule = computed(() => detail.value.cancelRule ? useCancelTerms(String(detail.value.cancelRule))?.content : '')

    // 訂單金額組成
    const totalPrice = computed(() => detail.value.totalPrice?.fullFormattedValue)
    // 純粹針對 coupon 折抵呈現
    const couponDiscount = computed(() => detail.value.coupon ? String(i18n.t('orderDetail.couponDiscount.content', [detail.value.discountPrice?.fullFormattedValue])) : null)
    // 純粹針對 promoCode 折抵呈現
    const promoCodeDiscount = computed(() => detail.value.promocode ? String(i18n.t('orderDetail.promoCodeDiscount.content', [detail.value.discountPrice?.fullFormattedValue])) : null)
    // 純粹針對 go points 的折抵呈現
    const goPointsDiscount = computed(() => detail.value.useGoPoints ? String(i18n.t('orderDetail.goPointsDiscount.content', [detail.value.discountPrice?.fullFormattedValue, detail.value.useGoPoints])) : null)
    const payPrice = computed(() => detail.value.payPrice?.fullFormattedValue)
    const goPointsFeedback = computed(() => String(i18n.t('orderDetail.goPointsFeedback.content', [detail.value.feedbackGoPoints])))

    return {
      isMobile,
      detail,

      closeModal,

      orderNum,
      startStorePhone,
      startStoreAddress,
      startTime,
      endTime,
      paymentMethods,
      invoiceInfo,
      storeServices,
      optionalItems,

      name,
      identity,
      passport,
      birthday,
      mobile,
      email,

      carImage,
      carEquip,
      carInsurance,
      productName,
      storePolicy,
      paymentPrecaution,
      distanceRule,
      fuelRule,
      cancelRule,

      totalPrice,
      couponDiscount,
      promoCodeDiscount,
      goPointsDiscount,
      payPrice,
      goPointsFeedback,
    }
  },
})
