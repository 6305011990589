











import { computed, defineComponent, nextTick, PropType, ref, toRefs, watch } from '@nuxtjs/composition-api'
import { useWindowScroll } from '@vueuse/core'
import useMenuable from '@/composables/use-menuable'
import { Options } from '@popperjs/core'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<Options | null>,
      default: null,
    },
    clickOutsideWhitelist: {
      type: Array as PropType<Element[]>,
      default: () => [],
    },
    hideOnScroll: {
      type: Boolean,
      default: false,
    },
    closeActivatorClicked: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, context) {
    const activator = ref<HTMLElement>()
    const menu = ref<HTMLElement>()
    const { options, clickOutsideWhitelist } = toRefs(props)

    const whitelist = computed(() => [
      ...clickOutsideWhitelist.value,
      ...(!props.closeActivatorClicked ? [activator.value] : []),
      menu.value,
    ])

    const { y: top } = useWindowScroll()
    watch(top, () => {
      // 滾動畫面時時隱藏選單
      if (props.hideOnScroll) context.emit('click-outside')
    })
    watch(() => props.value, async () => {
      await nextTick()
      update()
    }, { immediate: true })

    const { update } = useMenuable(activator, menu, options, whitelist, context)

    return {
      activator,
      menu,
    }
  },
})
