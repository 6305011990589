
























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import useBtn from '@/composables/use-btn'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md',
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean || String,
      default: false,
    },
    href: {
      type: String as PropType<string | null>,
      default: null,
    },
    target: {
      type: String as PropType<HTMLAnchorElement['target'] | null>,
      default: null,
    },
    to: {
      type: String || null,
      default: null,
    },
    id: {
      type: String || null,
      default: null,
    },
    color: {
      type: String || null,
      default: null,
    },
  },
  setup (props, context) {
    const tag = computed(() => {
      if (props.href) return 'a'
      else if (props.to) return 'NuxtLink'
      else return 'action'
    })

    const linkClass = computed(() => {
      const { size, block, disabled, color } = props
      return {

        // 背景
        'bg-transparent': true,

        // 文字
        'font-xs': size === 'sm',
        'font-sm': size === 'md',
        'font-base': size === 'lg',

        ...(!disabled
          ? {
              'hover:text-primary-hover active:text-primary-click': true,
              ...(
                color
                  ? {
                      [`text-${color}`]: true,
                    }
                  : {
                      'text-primary': true,
                    }
              ),
            }
          : {
              'text-neutral-300': true,
            }
        ),

        // 行內或塊級
        'inline-flex items-center justify-center': !block,
        'block w-full': block,

        ...(!disabled
          ? {
              'cursor-pointer': !disabled,
            }
          : {
              'cursor-disabled': disabled,
            }
        ),

        'whitespace-nowrap select-none text-center truncate': true,
      }
    })

    const bind = computed(() => ({
      ...context.attrs,
      disabled: props.disabled,
      class: linkClass.value,
    }))

    return {
      tag,

      bind,
      ...useBtn(context),
    }
  },
})
