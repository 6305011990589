import Birthday from '@/classes/Birthday'
import { isBoolean, isNumber, isObject, isString } from 'typechecker'

type Name = string
type Email = string
type PhoneNumber = string
type Identity = string
type Passport = string
type CouponCount = number

/** 客戶 */
export default class User {
  /** id */
  id?: number
  /** 姓名 */
  name?: Name
  /** 護照姓氏 */
  lastName?: Name
  /** 護照名字 */
  firstName?: Name
  /** 電子郵件 */
  email?: Email
  /** 身分證字號 */
  identity?: Identity
  /** 身分證字號 */
  passport?: Passport
  /** 生日 */
  birthday?: Birthday
  /** 國家代碼 */
  phoneCode?: PhoneNumber
  /** 手機號碼 */
  phoneNumber?: PhoneNumber
  /** 剩餘的滿千折百優惠券張數 */
  couponCount: CouponCount = 0
  /** 點數 */
  goPoints?: number
  /** 是否綁定第三方登入 */
  isFacebook?: boolean = false
  isGoogle?: boolean = false
  /** 信箱是否已驗證 */
  isMailConfirmed?: boolean
  /** 手機號碼是否已驗證 */
  isPhoneConfirmed?: boolean
  /** 是否傳送優惠訊息 */
  isSendNews?: boolean
  /** 是否租過車 */
  hasOrderCompleted?: boolean

  constructor (user: {
    id?: number
    name?: Name
    lastName?: Name
    firstName?: Name
    email?: Email
    identity?: Identity
    passport?: Passport
    birthday?: string
    phoneCode?: PhoneNumber
    phoneNumber?: PhoneNumber
    couponCount: CouponCount
    goPoints?: number
    isFacebook?: boolean
    isGoogle?: boolean
    isMailConfirmed?: boolean
    isPhoneConfirmed?: boolean
    isSendNews?: boolean
    hasOrderCompleted?: boolean
  }) {
    if (!isObject(user)) throw new TypeError('`user` must be an object!')

    if (isNumber(user.id)) this.id = user.id
    if (isString(user.name)) this.name = user.name
    if (isString(user.lastName)) this.lastName = user.lastName
    if (isString(user.firstName)) this.firstName = user.firstName
    if (isString(user.email)) this.email = user.email
    if (isString(user.identity)) this.identity = user.identity
    if (isString(user.passport)) this.passport = user.passport

    if (user.birthday && isString(user.birthday)) {
      const birthday = new Birthday(user.birthday)
      if (birthday.isValid()) this.birthday = birthday
    }

    if (isString(user.phoneCode)) this.phoneCode = user.phoneCode
    if (isString(user.phoneNumber)) this.phoneNumber = user.phoneNumber

    if (isNumber(user.couponCount)) this.couponCount = user.couponCount
    if (isNumber(user.goPoints)) this.goPoints = user.goPoints

    if (isBoolean(user.isFacebook)) this.isFacebook = Boolean(user.isFacebook)
    if (isBoolean(user.isGoogle)) this.isGoogle = Boolean(user.isGoogle)
    if (isBoolean(user.isMailConfirmed)) this.isMailConfirmed = Boolean(user.isMailConfirmed)
    if (isBoolean(user.isPhoneConfirmed)) this.isPhoneConfirmed = Boolean(user.isPhoneConfirmed)
    if (isBoolean(user.isSendNews)) this.isSendNews = Boolean(user.isSendNews)
    if (isBoolean(user.hasOrderCompleted)) this.hasOrderCompleted = Boolean(user.hasOrderCompleted)
  }

  /** 根據 `birthday` 計算出的年齡 */
  get age () {
    return this.birthday?.age
  }
}
