import { useContext, ref, computed, watch, onMounted, useRoute } from '@nuxtjs/composition-api'
import { LocaleObject } from 'nuxt-i18n/types'
import ggoApi from '@/modules/ggo-api'
import { FeatureFlagKey } from '@/modules/growthbook/feature-flag'
export const LOCAL_STORAGE_LOCALE_CODE_KEY = 'locale-code'

/**
 * key: route name
 * value: set of available locales
 */
const WHITELIST: Record<string, Set<string>> = {
  'about-recruit': new Set(['zh-tw', 'en', 'ja']),
}

export default function () {
  const { store } = useContext().app
  const { locale, locales, setLocale } = useContext().app.i18n
  const currentLocaleCode = ref(locale as 'zh-tw' | 'en' | 'ja')
  const localeSet = ref(new Set())

  const filteredLocales = computed(() => {
    return (locales as LocaleObject[])
    ?.filter(localeObject => localeSet.value.has(localeObject.code))
  })

  const currentLocale = computed(() => {
    return (filteredLocales.value as LocaleObject[])
      ?.find(localeObject => localeObject.code === currentLocaleCode.value)
  })

  const currentLocaleName = computed(() => {
    return typeof currentLocale.value !== 'string'
      ? currentLocale.value?.name
      : currentLocale.value
  })

  onMounted(() => {
    /**
     * 處理外部跳轉的 workaround, 如果 localstorage 有存 locale-code 就更改 locale
     */
    if (localStorage.getItem(LOCAL_STORAGE_LOCALE_CODE_KEY)) {
      currentLocaleCode.value = localStorage.getItem(LOCAL_STORAGE_LOCALE_CODE_KEY) as typeof currentLocaleCode.value
      localStorage.removeItem(LOCAL_STORAGE_LOCALE_CODE_KEY)
    }

    ggoApi.setLocale(currentLocaleCode.value)
  })
  watch(currentLocaleCode, async (locale) => {
    // 更改 i18n 語系
    await setLocale(locale)
    // 更改 api module 語系
    ggoApi.setLocale(locale)
    // 已登入狀態切換語系更換使用者語系偏好, 避免未登入切換 call updateUserInfo
    if (store?.state.user.user) { ggoApi.updateUserInfo({ locale }) }
  })

  const { getRouteBaseName } = useContext()
  const route = useRoute()
  watch([route, () => store?.state.growthbook.flags], ([route, flags]) => {
    if (WHITELIST[getRouteBaseName(route)!]) localeSet.value = WHITELIST[getRouteBaseName(route)!]
    else if (flags[FeatureFlagKey.VISIBLE_LOCALES]) localeSet.value = new Set((flags[FeatureFlagKey.VISIBLE_LOCALES] as string).split(','))
  }, { immediate: true })

  return {
    currentLocaleCode,
    currentLocaleName,
    locales: filteredLocales,
  }
}
