import { GrowthBook, Attributes } from '@growthbook/growthbook'
import GgoTrackKey from '@/enums/GgoTrackKey'
import User from '@/classes/User'
import Cookies from 'js-cookie'
import ggoLogger from '@/modules/ggo-logger'
import { LogName } from '@/interfaces/Logger'
import ggoApi from '@/modules/ggo-api'
import { isBoolean } from 'typechecker'
import FeatureFlags from './feature-flag'

export type UpdateContextOption = {
  user?: User,
  isMobile?: boolean
}

export class GbClient {
  client: GrowthBook<Record<string, any>> | undefined = undefined

  /**
   * 實驗Map
   * { experimentId: variationValue }
   */
  experimentMap: Record<string, any> = {}

  /**
   * Feature Map
   * { featureKey: featureValue }
   */
  featureMap: Record<string, any> = {}

  attribute: Attributes = {}

  /**
   *
   * @param renderCb 透過 DevTool 更改 feature/exp variation 後的回呼函式
   */
  init (renderCb?: () => void) {
    const growthbook = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: process.env.GB_CLIENT_KEY,
      enableDevMode: process.env.NODE_ENV !== 'production',
      subscribeToChanges: true,
      trackingCallback: (experiment, result) => {
        this.experimentMap[experiment.key] = result.value

        ggoLogger.info(LogName.EXP_VIEWED, {
          experimentId: experiment.key,
          variationId: result.variationId,
        })
      },
      features: FeatureFlags,
    })

    // 直接在 DevTool 切換 feature/exp variation 後叫回呼函式
    growthbook.setRenderer(() => {
      renderCb?.()
    })

    this.client = growthbook
  }

  async updateContext (option?: UpdateContextOption) {
    this.attribute = {
      ...this.attribute,
      ...await GbClient.GetAttribute(option),
    }

    await this.client?.setAttributes(this.attribute)
    await this.client?.loadFeatures()
  }

  getFeatureMap () {
    const featureMap = {} as Record<string, any>
    if (!this.client) return featureMap

    for (const [key, value] of Object.entries(this.client.getFeatures())) {
      featureMap[key] = this.client.getFeatureValue(key, value.defaultValue)
    }

    this.featureMap = featureMap
    return featureMap
  }

  static async GetAttribute (option?: UpdateContextOption): Promise<Attributes> {
    const deviceId = Cookies.get(GgoTrackKey.DEVICE_ID_STORE_KEY)

    let ip: string | undefined
    if (process.env.NODE_ENV === 'local') ip = process.env.INTERNAL_IP
    else ip = (await ggoApi.getIp()).data ?? undefined

    return {
      deviceId,
      ...(option?.user && {
        userId: option.user?.id,
      }),
      ...(isBoolean(option?.isMobile) && {
        isMobile: option?.isMobile,
      }),
      ip,
    }
  }
}

export default new GbClient()
