import User from '@/classes/User'
import { clearConfirmField } from '@/modules/confirm-fields-storage'
import { ActionTree, MutationTree } from 'vuex'

import ggoApi from '@/modules/ggo-api'
import ggoLogger from '@/modules/ggo-logger'
import { LogName, LogToastMsg } from '@/interfaces/Logger'
import gbClient from '@/modules/growthbook/gb-client'

class State {
  user: User | null = null
  isFetching: boolean = true // 讓需要auth的頁面一開始為loading, 而不會是login form, 增加 User experience
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  setUser (state, user: User) {
    state.user = user
  },
  setIsFetching (state, value: boolean) {
    state.isFetching = value
  },
  clearUser (state) {
    state.user = null
    clearConfirmField()
  },
}

export const actions = <ActionTree<State, any>>{
  async fetch ({ commit }) {
    // server side不執行: 拿不到localStorage
    if (!process.server) {
      commit('setIsFetching', true)
      const { success, data } = await ggoApi.getUserProfile()
      if (data && success) {
        const user = new User(data)
        commit('setUser', user)

        ggoLogger.updateUser(user)

        gbClient.updateContext({
          user,
        })
      }
      commit('setIsFetching', false)

      ggoLogger.update()
    }
  },
  async signOut ({ commit }) {
    // server side不執行: 拿不到localStorage
    if (!process.server) {
      commit('setIsFetching', true)

      const { success } = await ggoApi.deleteSession()

      if (success) {
        try {
          localStorage.removeItem('token')
          commit('clearUser')
          commit('setIsFetching', false)

          ggoLogger.info(LogName.TOAST, {
            type: 'success',
            message: LogToastMsg.LOGOUT_SUCCESS,
          })
          ggoLogger.clearUser()
        } catch {
          ggoLogger.info(LogName.TOAST, {
            type: 'danger',
            message: LogToastMsg.LOGOUT_ERROR,
          })
        }

        gbClient.updateContext({
          user: undefined,
        })
        location.reload()
      }
    }
  },
}
