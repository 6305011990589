import dayjs, { Dayjs } from 'dayjs'

type DateArgument = string | number | Dayjs
type HourArgument = number

/** 「日期」和「小時」的組合 */
export default class DateHour {
  date: Dayjs | null = null
  hour: number = 10
  instance: Dayjs | null = null

  /** 像在記錄該datehour的最大最小值 */
  hourRange: number[] = DateHour.hourRange

  readonly dateFormat = 'YYYY-MM-DD'

  constructor (date?: DateArgument | null, hour?: HourArgument) {
    if (date != null) this.setDate(date)
    if (hour != null) this.setHour(hour)
  }

  /** 設定日期 */
  setDate (date: DateArgument) {
    let instance: Dayjs

    if (typeof date === 'string') {
      instance = dayjs(date, this.dateFormat)
    } else if (typeof date === 'number') {
      instance = dayjs(date, 'x')
    } else {
      instance = dayjs(date, 'x')
    }

    if (instance.isValid()) {
      this.date = DateHour.normalizeDate(instance)
      this.instance = instance
    } else {
      throw new Error('不合法的 date!')
    }
  }

  /** 設定小時 */
  setHour (hour: HourArgument) {
    if (DateHour.checkIsHourValid(hour)) {
      this.hour = hour
      if (this.instance) { this.instance = this.instance?.set('h', hour) }
    } else {
      throw new Error('hour 應為 0~24 的整數!')
    }
  }

  /** 設定最大最小值 */
  setHourRange (hourRange: typeof DateHour.hourRange) {
    this.hourRange = hourRange
  }

  clearDate () {
    this.date = null
  }

  /** 克隆自己以避免副作用 */
  clone () {
    return new DateHour(this.date, this.hour)
  }

  /**
   * 日期字串
   * @example "2021-01-01"
   */
  get dateString () {
    return this.date?.format(this.dateFormat) ?? null
  }

  /**
   * 日期和小時字串
   * @example "05-10 13:00"
   */
  get dateHourString () {
    const dateString = this.date?.format('MM-DD') ?? ''
    const hourString = this.hour ? `${this.hour}:00` : ''
    return `${dateString} ${hourString}`
  }

  /** 檢查小時數是否在合法區間內 */
  static checkIsHourValid (hour: number) {
    return Number.isInteger(hour) && hour >= 0 && hour <= 24
  }

  /** 將日期歸位至當天的開始，即 00:00:00 */
  static normalizeDate (date: Dayjs) {
    return date.startOf('date')
  }

  // 不同地區的租車時間區間
  static hourRange: [number, number] = [8, 20]
  static USHourRange: [number, number] = [0, 23]

  /** 取得目前允許選擇的最早的 DateHour */
  static getMinDateHour (now = dayjs(), isUs = true): DateHour {
    const hour = now.hour()
    const { normalizeDate } = this

    /**
     * 現在date 區分為美國及非美國 並在非美國地區加入若今日時間已無可選時間，則直接禁止今日被選擇
     * 另外，後端本身有擋非美國區域的一小時內的搜尋導致預設搜出來的車輛為0台
     * 因此修改為兩小時可以消除直接點預設的時間搜尋出0台的情況消失
     * FYI https://gogoout.atlassian.net/wiki/x/MIATCw
     */

    if (isUs) {
      return (hour + 1 >= this.USHourRange[1])
        // 隔天的初始小時
        ? new this(
            normalizeDate(now.add(1, 'd')),
            this.USHourRange[0]
          )
          // 今天的小時數 + 1 小時
        : new this(
          normalizeDate(now),
          /** +1 */
          hour + 1
        )
    } else {
      return (hour + 2 >= this.hourRange[1])
        // 隔天的初始小時
        ? new this(
            normalizeDate(now.add(1, 'd')),
            this.hourRange[0]
          )
          // 今天的小時數 + 2 小時
        : new this(
          normalizeDate(now),
          /** +2 */
          hour + 2
        )
    }
  }
}
