import { computed, Ref, SetupContext } from '@nuxtjs/composition-api'

export default function (
  value: Ref,
  emit: SetupContext['emit']
) {
  const proxyValue = computed({
    get: () => value.value,
    set: value => emit('input', value),
  })

  return {
    proxyValue,
  }
}
