/** 列舉付款方式 */
enum PaymentMethod {
  /** 未知 */
  Unknown = 0,
  /** 信用卡 (TapPay/Payments91App) */
  CreditCard = 1,
  /** 中租零卡 App 線上付款 */
  ZeroCard = 2,
  /** LINE Pay */
  LinePay = 3,
  /** Aftee */
  Aftee = 4,
  /** 金融卡 (TapPay/Payments91App) */
  DebitCard = 5,
}

export default PaymentMethod
