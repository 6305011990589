/** 原生 alert 的替代, 同時送 toast log */

import { LogName, ToastLogData } from '@/interfaces/Logger'
import ggoLogger from '@/modules/ggo-logger'

const alert = (
  /** 可接受 string 及任何可以轉換成 string 的型態
   * e.x. string | VueI18n.TranslateResult
   * */
  message: {
    toString: () => string
  } | null,
  /** 額外設定 toast log data
   * default:
   * type: 'danger'
   * message: arguments[0]
   */
  toastLogData?: Partial<ToastLogData>
) => {
  const _toastLogData: ToastLogData = {
    type: 'danger',
    message: (message || '').toString(),
    ...toastLogData,
  }

  ggoLogger.info(LogName.TOAST, _toastLogData)

  window.alert(message)
}

export default alert
