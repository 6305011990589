const key = 'confirm-fields-storage'

/** 從 sessionStorage 取用已儲存的 confirm 欄位 */
export function getConfirmField () {
  try {
    const json = sessionStorage.getItem(key)
    if (json) return JSON.parse(json)
  } catch (e) {
  }
}

/** 將 confirm 欄位儲存至 sessionStorage */
export function setConfirmField (fields: Record<string, unknown>) {
  try {
    sessionStorage.setItem(key, JSON.stringify(fields))
  } catch (e) {
  }
}

/** 清空已儲存的 confirm 欄位 */
export function clearConfirmField () {
  try {
    sessionStorage.removeItem(key)
  } catch (e) {
  }
}
