import { render, staticRenderFns } from "./about.vue?vue&type=template&id=29e6cf92&lang=pug&"
import script from "./about.vue?vue&type=script&lang=ts&"
export * from "./about.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheNavbar: require('/app/components/the/TheNavbar.vue').default,TheFooter: require('/app/components/the/TheFooter.vue').default,CookieInfo: require('/app/components/CookieInfo.vue').default})
