













import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  setup () {
    const list = [
      {
        name: 'Facebook',
        link: 'https://www.facebook.com/gogooutt',
        icon: 'icon-facebook1',
      },
      {
        name: 'LINE',
        link: 'line://ti/p/%40zmi7792n',
        icon: 'icon-line',
      },
      {
        name: 'Facebook Messenger',
        link: 'https://m.me/gogooutt',
        icon: 'icon-messenger',
      },
      {
        name: 'YouTube',
        link: 'https://www.youtube.com/channel/UCJxRh1sebjJsWmoDXNiXYRQ',
        icon: 'icon-youtube',
      },
    ]

    return {
      list,
    }
  },
})
