import { Plugin } from '@nuxt/types'
import GgoTrackKey from '@/enums/GgoTrackKey'
import type { GetReferenceResponse } from '@/types/ggo-api/responses'
import { LogLevel, LogName } from '@/interfaces/Logger'

const TEMP_COOKIE_AGE_DAY = 1

export default (({ query, app, $axios, route, redirect }) => {
  if (!process.server) {
    // 這邊使用原生 axios 不使用 api, logger module 為減少服務器回應時間,
    // 初始化 class 會使用較久時間 -> 差到 10 倍
    try {
      /**
       * 將 referenceCode 從 query 中提出
       * 當 referenceCode 已被存進 cookie 後
       * 後面 url 不再帶 referenceCode 以免讓第二手拿到 url 的人也會被下 credit
       */
      const { referenceCode, ...rest } = query

      if (referenceCode) {
        // 取得 reference code 資訊
        const apiEndpoint = new URL('api/reference', process.env.NUXT_BASE_URL)
        apiEndpoint.searchParams.append('referenceCode', referenceCode as string)
        // 將目前 query 裡的 referenceCode 設定短暫的 cookie
        // 防止與 server 問 referenceCode 的溝通中間使用者已做下一步動作, e.x. 註冊, 下單
        app.$cookies.set(GgoTrackKey.REFERENCE_CODE, referenceCode, {
          path: '/',
          maxAge: 86400 * TEMP_COOKIE_AGE_DAY, // 1 天時間作為短暫 cookie 存活時間
        })
        $axios.get(apiEndpoint.toString())
          .then((res) => {
            const data: GetReferenceResponse | null = res.data
            data && data.referenceCode && app.$cookies.set(GgoTrackKey.REFERENCE_CODE, data.referenceCode, {
              path: '/',
              maxAge: 86400 * (data.referencePeriod ?? TEMP_COOKIE_AGE_DAY),
            })
          })
          .finally(() => {
            redirect(301, route.path, rest)
          })
      }
    } catch (err) {
      const apiEndpoint = new URL('log', process.env.NUXT_BASE_URL)
      fetch(apiEndpoint.toString(), {
        method: 'POST',
        body: JSON.stringify({
          log_name: LogName.ERROR,
          log_level: LogLevel.ERROR,
          client_type: 'server',
          service: 'Gogoout Web',
          host: process.env.NUXT_BASE_URL,
          ip: '0.0.0.0',
          timestamp: Date.now(),
          location: route.path,
          message: JSON.stringify({
            err,
          }),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    }
  }
}) as Plugin
