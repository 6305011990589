






























































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import OrderStatus from '@/enums/OrderStatus'
import Order from '@/classes/Order'
import useOrderList from '@/composables/use-order-list'
import useBreakpoint from '@/composables/use-breakpoint'
import useI18n from '@/composables/use-i18n'
import Vendor from '@/enums/Vendor'
import CarSource from '@/enums/CarSource'
import useOrder from '@/composables/use-order'
import OrderDetail from '@/classes/OrderDetail'
import dataLayer from '@/modules/data-layer'
import { Alert } from '@/types/alert'
import { CheckReviewResponse, GetCancelOrderResponse } from '@/types/ggo-api/responses'

/**
 * @deprecated: 'changeInvoice': 在下訂立即開立發票後，即無法修改發票，UI 已拔除，功能保留，未來不需使用再拔除
 */
type ModalType = 'changeInvoice' | 'getReview' | 'getRefund' | 'checkOrder' | 'failed'

type OrderListType = {
  alive: Order[],
  delivered: Order[],
  complete: Order[],
  canceled: Order[],
  hold: Order[],
}

export default defineComponent({
  props: {
    status: {
      type: String as PropType<OrderStatus>,
      default: OrderStatus.ALIVE,
    },
    cropped: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const { match } = useBreakpoint()
    const isMobile = !match('lg')

    const i18n = useI18n()

    const statusRef = computed(() => props.status)
    const { headers, orders: originalOrders, fetchOrderList } = useOrderList(statusRef)
    const orders = computed(() => props.cropped ? originalOrders.value.slice(0, 1) : originalOrders.value)
    // Mobile Navbar 的訂單列是裁切以及簡化呈現
    const simplifiedCard = computed(() => isMobile && props.cropped)

    const isLoading = ref(false)

    const { getOrderDetail, checkReview, getCancelOrder, cancelOrder, resendMail } = useOrder()

    const modalType = ref<ModalType | null>(null)
    const orderNum = ref<string | null>(null)
    const orderVendor = ref<Vendor | null>(null)
    const review = ref<CheckReviewResponse | null>(null)
    const alerts = ref<Array<Alert>>([])
    const holdOrder = ref<boolean>(false)

    const haveHoldOrder = (e: boolean) => {
      holdOrder.value = e
    }

    const showModal = ref(false)
    const openModal = () => {
      showModal.value = true
    }
    const closeModal = () => {
      modalType.value = null
      showModal.value = false

      orderNum.value = null
      review.value = null
      alerts.value = []
      holdOrder.value = false

      refundDetail.value = null
      orderDetail.value = null
    }

    const closeModalAndRefresh = () => {
      showModal.value = false
      window.location.reload()
    }

    // Actions
    const orderDetail = ref<OrderDetail | null>(null)
    const refundDetail = ref<GetCancelOrderResponse | null>(null)

    const confirmCancelOrder = async (orderNum: string, vendor: string) => {
      isLoading.value = true
      const result = await cancelOrder(orderNum, vendor)
      isLoading.value = false
      if (result) {
        const message = vendor === CarSource.HERTZ
          ? String(i18n.t('notice.alert.hertzCancelSuccess'))
          : String(i18n.t('message.refund.success'))

        alerts.value.push({ messages: message, unread: true, id: Symbol('') })

        dataLayer.refund({
          transaction_id: orderNum,
        })

        await fetchOrderList(statusRef.value)
      } else {
        alerts.value.push({ messages: String(i18n.t('message.refund.failed')), unread: true, id: Symbol('') })
      }
    }

    const cancel = async (orderNum: string, vendor: string) => {
      if (vendor === Vendor.CAR) {
        isLoading.value = true
        const res = await getCancelOrder(orderNum, vendor)
        isLoading.value = false
        if (res) {
          modalType.value = 'getRefund'
          refundDetail.value = res
          openModal()
          return
        } else {
          alerts.value.push({ messages: String(i18n.t('message.refund.failed')), unread: true, id: Symbol('') })
          return
        }
      }
      await confirmCancelOrder(orderNum, vendor)
    }

    const resendEmail = async (orderNum: string, vendor: string) => {
      if (await resendMail(orderNum, vendor)) {
        alerts.value.push({ messages: String(i18n.t('orderDetail.resend.success')), unread: true, id: Symbol('') })
      }
    }

    const checkOrder = async (orderNum: string, vendor: string) => {
      isLoading.value = true
      const res = await getOrderDetail(orderNum, vendor)
      isLoading.value = false
      if (res) {
        modalType.value = 'checkOrder'
        orderDetail.value = res
        openModal()
      } else {
        orderDetail.value = null
        alerts.value.push({ messages: String(i18n.t('message.error')), unread: true, id: Symbol('') })
      }
    }

    const checkOrderReview = async (orderNum: string) => {
      isLoading.value = true
      const res = await checkReview(orderNum)
      isLoading.value = false
      if (res) {
        modalType.value = 'getReview'
        review.value = res
        openModal()
      } else {
        modalType.value = 'failed'
        alerts.value.push({ messages: String(i18n.t('notice.bookingError')), unread: true, id: Symbol('') })
      }
    }

    /**
     * @deprecated: 在下訂立即開立發票後，即無法修改發票，UI 已拔除，功能保留，未來不需使用再拔除
     */
    const changeInvoice = (num: string, vendor: Vendor) => {
      openModal()
      modalType.value = 'changeInvoice'
      orderNum.value = num
      orderVendor.value = vendor
    }

    return {
      OrderStatus,

      headers,
      orders,
      simplifiedCard,

      isMobile,

      modalType,
      orderNum,
      orderVendor,
      review,
      alerts,

      holdOrder,
      haveHoldOrder,

      showModal,
      openModal,
      closeModal,
      closeModalAndRefresh,

      isLoading,

      cancel,
      resendEmail,
      checkOrder,
      checkOrderReview,
      changeInvoice,

      orderDetail,
      refundDetail,
      confirmCancelOrder,
    }
  },
})
