






















import { computed, defineComponent, nextTick, PropType, ref, watch } from '@nuxtjs/composition-api'
import dayjs from 'dayjs'
import objectSupport from 'dayjs/plugin/objectSupport'
import useSelectRange from '@/composables/use-select-range'

import useI18n from '@/composables/use-i18n'

dayjs.extend(objectSupport)

export default defineComponent({
  props: {
    value: {
      type: String as PropType<string | null>,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String as PropType<string | null>,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const labelClass = computed(() => ({
      'text-negative': props.error,
    }))

    const format = 'YYYY-MM-DD'

    const parse = (string: string | null) => {
      if (!string) throw new TypeError('`string` should be a string!')
      const instance = dayjs(string, format)
      if (!instance.isValid()) throw new Error('`instance` is not valid!')

      return {
        year: instance.get('year'),
        month: instance.get('month') + 1,
        day: instance.get('date'),
      }
    }

    const year = ref<number | null>(null)
    const month = ref<number | null>(null)
    const day = ref<number | null>(null)

    const isValid = ref(false)
    const isAllSelected = computed(
      () => [year.value, month.value, day.value].every(i => i !== null)
    )

    // 監聽日期字串參數的變化
    watch(() => props.value, (value) => {
      try {
        const object = parse(value)
        year.value = object.year
        month.value = object.month
        day.value = object.day

        isValid.value = true
      } catch (e) {
        isValid.value = false
      }
    }, { immediate: true })

    // 監聽選單的變化
    watch([year, month, day], async () => {
      try {
        const instance = dayjs(
          `${year.value}-${month.value}-${day.value}`,
          'YYYY-M-D',
          true
        )

        if (!instance.isValid()) throw new Error('`instance` is not valid!')
        const string = instance.format(format)
        emit('input', string)
        isValid.value = true
      } catch (e) {
        isValid.value = false
      }
      await nextTick()
      emit('change')
    })

    const getDefaultItem = (name: string) => (
      { value: null, name, disabled: true }
    )

    const { getRange } = useSelectRange()

    const i18n = useI18n()

    // 拔掉年齡限制，改成以 alert 方式提醒
    const years = [
      getDefaultItem(String(i18n.t('field.birthday.year.placeholder'))),
      ...getRange(1930, new Date().getFullYear())
        .reverse()
        .map(value => ({ value, name: String(i18n.t('field.birthday.year.option', [value])) })),
    ]
    const months = [
      getDefaultItem(String(i18n.t('field.birthday.month.placeholder'))),
      ...getRange(1, 12)
        .map(value => ({ value, name: String(i18n.t('field.birthday.month.option', [value])) })),
    ]
    const days = [
      getDefaultItem(String(i18n.t('field.birthday.day.placeholder'))),
      ...getRange(1, 31)
        .map(value => ({ value, name: String(i18n.t('field.birthday.day.option', [value])) })),
    ]

    return {
      labelClass,
      year,
      month,
      day,
      years,
      months,
      days,
      isValid,
      isAllSelected,
    }
  },
})
