/** 座位數區間 */
export default class SeatRange {
  start: number
  end: number

  constructor (start: number, end: number) {
    this.start = start
    this.end = end
  }

  get 0 () {
    return this.start
  }

  get 1 () {
    return this.end
  }

  /** 分隔符號 */
  static readonly separator = ','

  /** 從字串產生 */
  static fromString (string: string): SeatRange {
    if (!(typeof string === 'string')) throw new TypeError('`seats` must be a string')
    const [startString, endString] = string.split(this.separator)

    let [start, end] = (startString && endString == null)
      // 當字串裡未包含分隔符而只有數字的情況會視為 `start` 和 `end` 相同。
      // 這是為了相容舊版網站參數。
      ? [parseInt(startString), parseInt(startString)]
      : [
          (startString === '') ? 0 : parseInt(startString),
          (endString === '') ? Infinity : parseInt(endString),
        ]

    if ([start, end].every(value => !Number.isNaN(value))) {
      if (start > end) [start, end] = [end, start]
      return new this(start, end)
    } else throw new Error('Failed to parse seats string!')
  }

  toString (): string {
    return [this.start, this.end]
      .map(i => ([0, Infinity, -Infinity].includes(i)) ? '' : i)
      .join(SeatRange.separator)
  }
}
