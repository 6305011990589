/* eslint-disable camelcase */
import Price from '@/classes/Price'
import { isArray, isNumber, isObject, isString, isBoolean } from 'typechecker'
import PaymentStatus from '@/enums/PaymentStatus'
import IdNameObject from '@/interfaces/IdNameObject'
import Vendor from '@/enums/Vendor'
import Order, { OrderStatus } from './Order'

type OptionalItemArg = IdNameObject & {
  price: number
  quantity: number
  refundable: number
}
type OptionalItem = IdNameObject & {
  price: Price
  quantity: number
  refundable: boolean
}

export type OrderDetailArgument = {
  status?: OrderStatus
  order_status?: OrderStatus
  vendor?: Vendor;
  member_id?: number;
  trackingCode?: string;
  referenceCode?: any;
  store_city?: string;
  name?: string;
  identity?: string;
  passport?: string;
  birthday?: string;
  mobile?: string;
  email?: string;
  car_license?: string;
  invoiceInfo?: string;
  car_id?: number;
  startStoreId?: number | string;
  startStoreName?: string;
  endStoreId?: number | string;
  endStoreName?: string;
  cancelNote?: {
    show: boolean,
    text: string
  };
  optional_items?: OptionalItemArg[];
  paymentMethods?: IdNameObject[];
  payment_precaution?: string[];
  created_at: string; // orderTime
  pay_time? : string;
  paid?: PaymentStatus;
  coupon?: number;
  promocode?: string;
  payment_price?: number; // paymentPrice
  credit_last_digits?: any; // string to number
  invoice_no? : string;
  discount?: number;
  car_price?: number // carPrice
  countryCode?: string
  pay_price?: number; // payPrice
  cancelledAt?: string; // 需要轉換 : cancelledAt -> cancel_at
  cancel_at?: string;
  refund?: Price; // 需要轉換 to Price
  refundPercentage?: number;
  start_flight?: {
    date: string,
    number: string
  }; // startFlight
  end_flight?: {
    date: string,
    number: string
  }; // endFlight
  start_pick_up?: boolean; // startPickUp
  end_pick_up?: boolean; // endPickUp
  referralAds? : [];
  isInvoiceUpdatable?: boolean;
  orderNum?: string;
  startTime?: string;
  endTime?: string;
  productName?: string;
  startStorePhone?: string;
  endStorePhone?: string;
  startStoreAddress?: string;
  endStoreAddress?: string;
  storeServices?: any[];
  car_brand?: string;
  car_model?: string;
  car_age?: number;
  seats?: number;
  doors?: number;
  exhaust?: string;
  car_type?: string;
  car_image?: string;
  car_equipments?: IdNameObject[];
  car_insurances?: IdNameObject[];
  transmission?: string;

  store_policies?: IdNameObject[];
  distance_rule?: string;
  overDistanceRule?: string;
  fuelRule?: string;
  cancelRule?: number;

  price_currency_code?: string;
  price_currency_sign?: string;

  useGoPoints?: number;
  feedbackGoPoints: number;
}

export default class OrderDetail extends Order {
  key?: Symbol = Symbol('')
  /** 付款狀態 */
  paymentStatus: PaymentStatus
  /** 訂單會員編號 */
  member_id?: number
  /** 下單處 */
  trackingCode?: string
  /** referenceCode */
  referenceCode?: any
  /** 店家所在地 */
  store_city?: string
  /** 訂單人名稱 */
  name?: string
  /** 訂單人身分證 */
  identity?: string
  /** 訂單人護照 */
  passport?: string
  /** 訂單人生日 */
  birthday?: string
  /** 訂單人手機 */
  mobile?: string
  /** 訂單人email */
  email?: string
  /** 車牌 */
  car_license?: string
  /** 收據資訊 */
  invoiceInfo?: string

  // 去車API撈
  carBrand?: string
  /** 車輛型號 */
  carModel?: string
  /** 車齡 */
  carAge?: number
  /** 座位數量 */
  seats?: number
  /** 車門 */
  doors?: number
  /** 排氣量 */
  exhaust?: string
  /** 車型 */
  carType?: string
  /** 車輛照片 */
  carImage?: string
  /** 車輛設備 */
  carEquipments: IdNameObject[] = []
  /** 車輛保險 */
  carInsurances: IdNameObject[] = []

  transmission?: string

  /** 取消備註 */
  cancelNote?: {
    show: boolean,
    text: string
  }

  optionalItems: OptionalItem[] = []

  /** 商店政策 */
  storePolicies: IdNameObject[] = []
  distanceRule?: string
  overDistanceRule?: string
  fuelRule?: string
  cancelRule?: number

  /** 付款方式 */
  paymentMethods: IdNameObject[] = []

  /** 注意事項 */
  paymentPrecaution: string[] = []

  /** 優惠券 */
  coupon?: number

  /** 促銷碼 */
  promocode?: string

  /** 付款價格 */
  paymentPrice?: Price

  /** 付款價格 */
  payPrice?: Price

  /** 折扣 */
  discount?: number

  /** 折扣 */
  discountPrice?: Price

  /** 車價 */
  carPrice?: Price

  /** 租令地國家 */
  countryCode?: string

  /** 去程航班 */
  startFlight?: {
    date: string,
    number: string
  }

  /** 返程航班 */
  endFlight?: {
    date: string,
    number: string
  }

  /** 去程機場接送 */
  startPickUp?: boolean

  /** 返程機場接送 */
  endPickUp?: boolean

  /** 收據可以變更 */
  isInvoiceUpdatable?: boolean

  startStorePhone?: string
  endStorePhone?: string
  startStoreAddress?: string
  endStoreAddress?: string
  storeServices: any[] = []

  refund?: Price

  /** 原始價格(折扣前) */
  totalPrice?: Price
  /** 使用的 go points 數量 */
  useGoPoints?: number;
  /** 回饋的 go points 數量 */
  feedbackGoPoints?: number;

  constructor (orderDetail: OrderDetailArgument) {
    super(orderDetail)

    this.key = Symbol('')

    if (isNumber(orderDetail.paid)) this.paymentStatus = orderDetail.paid ?? PaymentStatus.PENDING
    else this.paymentStatus = PaymentStatus.PENDING

    if (orderDetail.member_id && isNumber(orderDetail.member_id)) this.member_id = orderDetail.member_id
    if (orderDetail.trackingCode && isString(orderDetail.trackingCode)) this.trackingCode = orderDetail.trackingCode
    if (orderDetail.referenceCode) this.referenceCode = orderDetail.referenceCode
    if (orderDetail.store_city && isString(orderDetail.store_city)) this.store_city = orderDetail.store_city
    if (orderDetail.name && isString(orderDetail.name)) this.name = orderDetail.name
    if (orderDetail.identity && isString(orderDetail.identity)) this.identity = orderDetail.identity
    if (orderDetail.passport && isString(orderDetail.passport)) this.passport = orderDetail.passport
    if (orderDetail.birthday && isString(orderDetail.birthday)) this.birthday = orderDetail.birthday
    if (orderDetail.mobile && isString(orderDetail.mobile)) this.mobile = orderDetail.mobile
    if (orderDetail.email && isString(orderDetail.email)) this.email = orderDetail.email
    if (orderDetail.car_license && isString(orderDetail.car_license)) this.car_license = orderDetail.car_license
    if (orderDetail.invoiceInfo && isString(orderDetail.invoiceInfo)) this.invoiceInfo = orderDetail.invoiceInfo
    if (orderDetail.cancelNote && isObject(orderDetail.cancelNote)) this.cancelNote = orderDetail.cancelNote
    if (orderDetail.optional_items && isArray(orderDetail.optional_items)) {
      this.optionalItems = orderDetail.optional_items.map((item: OptionalItemArg) => {
        return {
          id: item.id,
          name: item.name,
          price: new Price(item.price, {
            code: orderDetail.price_currency_code ?? 'NTD',
            sign: orderDetail.price_currency_sign ?? '$',
          }),
          quantity: item.quantity,
          refundable: Boolean(item.refundable),
        }
      })
    }
    if (orderDetail.paymentMethods && isArray(orderDetail.paymentMethods)) this.paymentMethods = orderDetail.paymentMethods
    if (orderDetail.payment_precaution && isArray(orderDetail.payment_precaution)) this.paymentPrecaution = orderDetail.payment_precaution
    if (orderDetail.coupon && isNumber(orderDetail.coupon)) this.coupon = orderDetail.coupon
    if (orderDetail.promocode && isString(orderDetail.promocode)) this.promocode = orderDetail.promocode
    if (orderDetail.payment_price && isNumber(orderDetail.payment_price)) {
      this.paymentPrice = new Price(orderDetail.payment_price, {
        code: orderDetail.price_currency_code ?? 'NTD',
        sign: orderDetail.price_currency_sign ?? '$',
      })
    }
    if (orderDetail.pay_price && isNumber(orderDetail.pay_price)) {
      this.payPrice = new Price(orderDetail.pay_price, {
        code: orderDetail.price_currency_code ?? 'NTD',
        sign: orderDetail.price_currency_sign ?? '$',
      })
    }
    if (orderDetail.discount !== undefined && orderDetail.discount !== null && isNumber(orderDetail.discount)) {
      this.discount = orderDetail.discount
      this.discountPrice = new Price(orderDetail.discount, {
        code: orderDetail.price_currency_code ?? 'NTD',
        sign: orderDetail.price_currency_sign ?? '$',
      })
    }
    if (orderDetail.car_price && isNumber(orderDetail.car_price)) {
      this.carPrice = new Price(orderDetail.car_price, {
        code: orderDetail.price_currency_code ?? 'NTD',
        sign: orderDetail.price_currency_sign ?? '$',
      })
    }
    if (orderDetail.countryCode && isString(orderDetail.countryCode)) this.countryCode = orderDetail.countryCode
    if (orderDetail.start_flight && isObject(orderDetail.start_flight)) this.startFlight = orderDetail.start_flight
    if (orderDetail.end_flight && isObject(orderDetail.end_flight)) this.endFlight = orderDetail.end_flight
    if (orderDetail.start_pick_up && isBoolean(orderDetail.start_pick_up)) this.startPickUp = orderDetail.start_pick_up
    if (orderDetail.end_pick_up && isBoolean(orderDetail.end_pick_up)) this.endPickUp = orderDetail.end_pick_up
    if (orderDetail.isInvoiceUpdatable && isBoolean(orderDetail.isInvoiceUpdatable)) this.isInvoiceUpdatable = orderDetail.isInvoiceUpdatable
    if (orderDetail.startStorePhone && isString(orderDetail.startStorePhone)) this.startStorePhone = orderDetail.startStorePhone
    if (orderDetail.endStorePhone && isString(orderDetail.endStorePhone)) this.endStorePhone = orderDetail.endStorePhone
    if (orderDetail.startStoreAddress && isString(orderDetail.startStoreAddress)) this.startStoreAddress = orderDetail.startStoreAddress
    if (orderDetail.endStoreAddress && isString(orderDetail.endStoreAddress)) this.endStoreAddress = orderDetail.endStoreAddress
    if (orderDetail.storeServices && isArray(orderDetail.storeServices)) this.storeServices = orderDetail.storeServices
    if (orderDetail.car_brand && isString(orderDetail.car_brand)) this.carBrand = orderDetail.car_brand
    if (orderDetail.car_model && isString(orderDetail.car_model)) this.carModel = orderDetail.car_model
    if (orderDetail.car_age && isNumber(orderDetail.car_age)) this.carAge = orderDetail.car_age
    if (orderDetail.seats && isNumber(orderDetail.seats)) this.seats = orderDetail.seats
    if (orderDetail.doors && isNumber(orderDetail.doors)) this.doors = orderDetail.doors
    if (orderDetail.exhaust && isString(orderDetail.exhaust)) this.exhaust = orderDetail.exhaust
    if (orderDetail.car_type && isString(orderDetail.car_type)) this.carType = orderDetail.car_type
    if (orderDetail.car_image && isString(orderDetail.car_image)) this.carImage = orderDetail.car_image
    if (orderDetail.car_equipments && isArray(orderDetail.car_equipments)) this.carEquipments = orderDetail.car_equipments
    if (orderDetail.car_insurances && isArray(orderDetail.car_insurances)) this.carInsurances = orderDetail.car_insurances
    if (orderDetail.transmission && isString(orderDetail.transmission)) this.transmission = orderDetail.transmission

    if (orderDetail.store_policies && isArray(orderDetail.store_policies)) this.storePolicies = orderDetail.store_policies
    if (orderDetail.distance_rule && isString(orderDetail.distance_rule)) this.distanceRule = orderDetail.distance_rule
    if (orderDetail.overDistanceRule && isString(orderDetail.overDistanceRule)) this.overDistanceRule = orderDetail.overDistanceRule
    if (orderDetail.fuelRule && isString(orderDetail.fuelRule)) this.fuelRule = orderDetail.fuelRule
    if (orderDetail.cancelRule && isNumber(orderDetail.cancelRule)) this.cancelRule = orderDetail.cancelRule

    if (orderDetail.referralAds && isArray(orderDetail.referralAds)) this.referralAds = orderDetail.referralAds

    /** 把實際付款和折扣金額加總回去 */
    if (isNumber(orderDetail.pay_price) && isNumber(orderDetail.discount)) {
      this.totalPrice = new Price(orderDetail.pay_price! + orderDetail.discount!, {
        code: orderDetail.price_currency_code ?? 'NTD',
        sign: orderDetail.price_currency_sign ?? '$',
      })
    }
    if (isNumber(orderDetail.useGoPoints)) this.useGoPoints = orderDetail.useGoPoints
    if (isNumber(orderDetail.feedbackGoPoints)) this.feedbackGoPoints = orderDetail.feedbackGoPoints
  }
}
