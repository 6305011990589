import { render, staticRenderFns } from "./AccountSignup.vue?vue&type=template&id=0410d903&scoped=true&lang=pug&"
import script from "./AccountSignup.vue?vue&type=script&lang=ts&"
export * from "./AccountSignup.vue?vue&type=script&lang=ts&"
import style0 from "./AccountSignup.vue?vue&type=style&index=0&id=0410d903&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0410d903",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GField: require('/app/components/GField.vue').default,GFieldBirthday: require('/app/components/GFieldBirthday.vue').default,GCheckbox: require('/app/components/GCheckbox.vue').default,GLink: require('/app/components/GLink.vue').default,GBtn: require('/app/components/GBtn.vue').default})
