import { reactive } from '@nuxtjs/composition-api'

import User from '@/classes/User'
import ggoApi from '@/modules/ggo-api'

/** 用於 Confirm 頁取得完整用戶資料的相關邏輯 */
export default function () {
  const user = reactive({
    value: null as User | null,
    error: null,
    isFetching: false as boolean,
  })

  /** 載入完整用戶資料 */
  const fetchUser = async () => {
    user.isFetching = true
    user.error = null

    const { success, data } = await ggoApi.getUserProfile()

    if (data && success) {
      user.value = new User(data)
    } else {
      user.value = null
    }
    user.isFetching = false
  }

  return {
    user,
    fetchUser,
  }
}
