
















import { computed, defineComponent, PropType, toRefs } from '@nuxtjs/composition-api'
import useCheckbox from '@/composables/use-checkbox'

export default defineComponent({
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    type: {
      type: String as PropType<'default' | 'card'>,
      default: 'default',
    },
    value: {
      default: null,
    },
    label: {
      type: String as PropType<string | null>,
      default: null,
    },
    checked: {
      type: [Array, Boolean],
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    alignTop: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, context) {
    const { proxyChecked } = useCheckbox(toRefs(props).checked, context)
    const isChecked = computed(() => proxyChecked.value?.includes(props.value))

    const labelClass = computed(() => ({
      flex: props.block,
      'inline-flex': !props.block,
      'items-start': props.alignTop,
      'items-center': !props.alignTop,
      disabled: props.disabled,
    }))

    const cardClass = computed(() => ({
      active: isChecked.value,
      disabled: props.disabled,
    }))

    return {
      proxyChecked,
      isChecked,
      labelClass,
      cardClass,
    }
  },
})
