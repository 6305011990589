/* eslint-disable camelcase */
import Price from '@/classes/Price'
import { isArray, isBoolean, isNumber, isObject, isString } from 'typechecker'
import ReferralAds from '@/enums/ReferralAds'
import Vendor from '@/enums/Vendor'
import PaymentStatus from '@/enums/PaymentStatus'
import PaymentType from '@/enums/PaymentType'
import PaymentMethod from '@/enums/PaymentMethod'

export type OrderStatus = 'alive' | 'delivered' | 'complete' | 'canceled' | 'hold'

export type OrderArgument = {
  orderNum?: string;
  startStoreId?: number | string;
  startStoreName?: string;
  endStoreId?: number | string;
  endStoreName?: string;
  car_id?: number;
  orderTime?: string;
  startTime?: string;
  endTime?: string;
  insuranceFee?: number;
  status?: OrderStatus
  cancel_at?: string;
  vendor?: Vendor;
  productName?: string;

  email?: string;
  price?: Price;
  insuranceDataFiled?: number
  paid?: PaymentStatus
  insuranceIndex?: string
  invoice_to? : string
  invoice_code? : number
  invoice_name? : string
  invoice_no? : string
  pay_time? : string
  credit_last_digits? : number
  payment_methods_id? : number
  refundPercentage?: number
  refund? : Price
  referralAds?: Array<number>

  isCustomerFilled?: boolean;
  isStoreFilled?: boolean;
  isOverDeadline?: boolean;
  customerScore?: any;

  payment_type?: number;
}

export default class Order {
  key?: Symbol = Symbol('')
  /** 訂單編號 */
  orderNum?: string
  /** 租車店家id */
  startStoreId?: number | string
  /** 租車店家名稱 */
  startStoreName?: string
  /** 還車店家id */
  endStoreId?: number | string
  /** 還車店家名稱 */
  endStoreName?: string
  /** 車輛id */
  carId?: number
  /** 下單時間 */
  orderTime?: string
  /** 租車時間 */
  startTime?: string
  /** 還車時間 */
  endTime?: string
  /** 取消時間 */
  cancelAt?: string
  /** 保險金額 */
  insuranceFee?: number
  /** 訂單狀態 */
  status?: OrderStatus
  /** vendor */
  vendor?: Vendor
  /** 車輛型號 */
  productName?: string

  email?: string
  price?: Price
  insuranceDataFiled?: boolean
  paid?: number
  insuranceIndex?: string
  invoiceTo?: string
  invoiceCode?: number
  invoiceName?: string
  invoiceNum?: string
  payTime?: string
  creditLastDigits?: number
  paymentMethodsId?: number
  refundPercentage?: number
  refund?: Price
  referralAds: Array<ReferralAds> = []

  isCustomerFilled?: boolean
  isStoreFilled?: boolean
  isOverDeadline?: boolean
  // TODO: 型態確認
  customerScore?: any

  paymentMethod?: PaymentMethod
  paymentType?: PaymentType

  constructor (order: OrderArgument) {
    this.key = Symbol('')
    if (!isObject(order)) throw new TypeError('`order` must be an object!')

    if (order.orderNum && isString(order.orderNum)) this.orderNum = order.orderNum
    if (order.startStoreId && (isNumber(order.startStoreId) || isString(order.startStoreId))) this.startStoreId = order.startStoreId
    if (order.startStoreName && isString(order.startStoreName)) this.startStoreName = order.startStoreName
    if (order.endStoreId && (isNumber(order.endStoreId) || isString(order.endStoreId))) this.endStoreId = order.endStoreId
    if (order.endStoreName && isString(order.endStoreName)) this.endStoreName = order.endStoreName

    if (order.car_id && isNumber(order.car_id)) this.carId = order.car_id

    if (order.orderTime && isString(order.orderTime)) this.orderTime = order.orderTime
    if (order.startTime && isString(order.startTime)) this.startTime = order.startTime
    if (order.endTime && isString(order.endTime)) this.endTime = order.endTime
    if (order.cancel_at && isString(order.cancel_at)) this.cancelAt = order.cancel_at
    if (order.insuranceFee && isNumber(order.insuranceFee)) this.insuranceFee = order.insuranceFee
    if (order.status && isString(order.status)) this.status = order.status

    if (order.vendor && isString(order.vendor)) this.vendor = order.vendor
    if (order.productName && isString(order.productName)) this.productName = order.productName
    if (order.email && isString(order.email)) this.email = order.email
    if (order.price && isObject(order.price)) this.price = new Price(order.price.value, order.price.currency)
    if (order.insuranceDataFiled && isNumber(order.insuranceDataFiled)) this.insuranceDataFiled = !!order.insuranceDataFiled
    if (isNumber(order.paid)) this.paid = order.paid
    if (order.insuranceIndex && isString(order.insuranceIndex)) this.insuranceIndex = order.insuranceIndex

    if (order.invoice_to && isString(order.invoice_to)) this.invoiceTo = order.invoice_to
    if (order.invoice_code && isNumber(order.invoice_code)) this.invoiceCode = order.invoice_code
    if (order.invoice_name && isString(order.invoice_name)) this.invoiceName = order.invoice_name
    if (order.invoice_no && isString(order.invoice_no)) this.invoiceNum = order.invoice_no

    if (order.pay_time && isString(order.pay_time)) this.payTime = order.pay_time
    if (order.credit_last_digits && isNumber(order.credit_last_digits)) this.creditLastDigits = order.credit_last_digits
    if (order.payment_methods_id && isNumber(order.payment_methods_id)) this.paymentMethodsId = order.payment_methods_id
    if (order.refundPercentage) this.refundPercentage = order.refundPercentage
    if (order.refund && isObject(order.refund)) this.refund = new Price(order.refund?.value, order.refund?.currency)

    if (order.referralAds && isArray(order.referralAds)) this.referralAds = order.referralAds

    if (isBoolean(order.isCustomerFilled)) this.isCustomerFilled = order.isCustomerFilled
    if (isBoolean(order.isStoreFilled)) this.isStoreFilled = order.isStoreFilled
    if (isBoolean(order.isOverDeadline)) this.isOverDeadline = order.isOverDeadline
    if (order.customerScore) this.customerScore = order.customerScore

    if (order.payment_methods_id && isNumber(order.payment_methods_id)) this.paymentMethod = order.payment_methods_id
    if (order.payment_type && isNumber(order.payment_type)) this.paymentType = order.payment_type
  }
}
