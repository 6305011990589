import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=70833148&scoped=true&lang=pug&"
import script from "./TheFooter.vue?vue&type=script&lang=ts&"
export * from "./TheFooter.vue?vue&type=script&lang=ts&"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=70833148&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70833148",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GSihImage: require('/app/components/GSihImage.vue').default,TheFooterSocialMediaList: require('/app/components/the/TheFooterSocialMediaList.vue').default})
