












import useI18n from '@/composables/use-i18n'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: ['error'],
  setup () {
    const i18n = useI18n()

    const img = `https://cdn.gogoout.com/web/error/${i18n.locale}_404.png`

    return {
      img,
    }
  },
})
