import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export default class Birthday {
  dayjs: Dayjs
  readonly format = 'YYYY-MM-DD'

  constructor (string: string) {
    this.dayjs = dayjs(string, this.format).clone()
  }

  isValid () {
    return this.dayjs.isValid()
  }

  toString () {
    return this.dayjs.format(this.format)
  }

  get age (): number | undefined {
    if (!this.isValid()) return
    return dayjs.duration(dayjs().diff(this.dayjs)).years()
  }
}
