







import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    require: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const required = computed(() => props.require)

    return {
      required,
    }
  },
})
