




















import { computed, defineComponent, PropType } from '@vue/composition-api'
import Order from '@/classes/Order'
import useOrderListData from '@/composables/use-order-list-data'
import { ActionItem } from '@/components/order/table/cell/OrderTableCellAction.vue'
import useI18n from '@/composables/use-i18n'
import Vendor from '@/enums/Vendor'

export default defineComponent({
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()

    const {
      orderTime,
      productName,
      startStoreLink,
      startTime,
      endTime,
      price,
      checkOrder,
      checkCommercialInvoice,
    } = useOrderListData(props.order, emit)

    const actions = computed(() => {
      const { order } = props
      const _actions: Array<ActionItem> = []
      if (order.vendor !== Vendor.HERTZ) {
        _actions.push({
          value: i18n.t('orderDetail.checkCommercialInvoice').toString(),
          onClick: () => checkCommercialInvoice(),
          type: 'LINK',
        })
      }
      return _actions
    })

    return {
      orderTime,
      productName,
      startStoreLink,
      startTime,
      endTime,
      price,
      checkOrder,
      actions,
    }
  },
})
