import useI18n from '@/composables/use-i18n'
import ReferralAds from '@/enums/ReferralAds'

export type ReferralAd = {
  value: number
  name: string
  content: string
  img: string
  orderResultLink: string
}

export default function () {
  const i18n = useI18n()

  const getInsuranceInfo = (type: ReferralAds) => {
    switch (type) {
      case ReferralAds.LUCKY_BO:
        return {
          name: i18n.t('insurance.luckybo').toString(),
          href: 'https://gogoout.pse.is/luckybo_confirm',
        }
      case ReferralAds.MING_TAI:
        return {
          name: i18n.t('insurance.mingtai').toString(),
          href: 'https://gogoout.pse.is/397wc9',
        }
    }
  }

  const list = [
    {
      value: ReferralAds.LUCKY_BO,
      name: i18n.t('field.referralAd.option.luckybo.title', [i18n.t('insurance.luckybo')]),
      content: i18n.t('field.referralAd.option.luckybo.content'),
      img: 'https://cdn.gogoout.com/web/referral-ad/luckybo.jpg',
      orderResultLink: 'https://gogoout.pse.is/luckybo_confirm',
    },
    {
      value: ReferralAds.MING_TAI,
      name: i18n.t('field.referralAd.option.mingtai.title', [[i18n.t('insurance.mingtai')]]),
      content: i18n.t('field.referralAd.option.mingtai.content'),
      img: 'https://cdn.gogoout.com/web/referral-ad/mingtai.jpg',
      orderResultLink: 'https://gogoout.pse.is/397wc9',
    },
  ] as ReferralAd[]

  return {
    getInsuranceInfo,
    list,
  }
}
