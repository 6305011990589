import { Route } from 'vue-router'

/**
 * 開新分頁時需要拿取url對應的route name
 * 因此該只記錄會另開新分頁的路由
 * e.x. store頁會另開confirm頁, 透過referrer抓到store頁的route name
 * @param url
 * @returns
 */
export const getRouteNameFromUrl = (url?: string): string | undefined => {
  if (!url || url === '') return

  const _url = new URL(url)
  const origin = process.env.APP_BASE_URL
  if (_url.origin === origin) {
    const pathname = _url.pathname
    if (pathname.match(/\/[^/]+\/store\/\d+/)) return 'store-id'
    if (pathname.match(/\/[^/]+\/car\/store_hertz\//)) return 'car-storeHertz-vendor'
    if (pathname.match(/\/[^/]+\/car\/search$/)) return 'car-search'
    if (pathname.match(/\/[^/]+\/car\/searchHertz$/)) return 'car-searchHertz'
  }
}

export const getSearchParamsFromRoute = (route: Route) => {
  if (!(typeof route.query === 'object') || route.query === null) return new URLSearchParams()

  const obj: Record<string, string> = {}
  for (const [key, value] of Object.entries(route.query)) {
    // 當 value 為 null 時 .toString() 會爆
    obj[key] = String(value)
  }
  return new URLSearchParams(obj)
}
