import { Plugin } from '@nuxt/types'

export default (({ app: { store, i18n } }) => {
  const { locale: language } = i18n

  store?.dispatch('google/load', { language })
  // FIXME: 延遲加載
  // setTimeout(() => {
  //   store?.dispatch('google/load', { language })
  // }, 4000)
}) as Plugin
