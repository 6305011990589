

































































import { computed, ComputedRef, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import Order from '@/classes/Order'
import GSelectMenu from '@/components/GSelectMenu.vue'
import useI18n from '@/composables/use-i18n'
import OrderStatus from '@/enums/OrderStatus'
import useOrderListData from '@/composables/use-order-list-data'
import { isObject } from 'typechecker'
import Vendor from '@/enums/Vendor'
import alert from '@/modules/alert'
import PaymentStatus from '@/enums/PaymentStatus'
type OrderCardMoreSelectAction = 'CANCEL' | 'RESEND_EMAIL' | 'MODIFY_RECEIPT' | 'CHECKOUT_AGAIN'

type OrderCardMoreSelectItem = {
  name: string,
  value: OrderCardMoreSelectAction,
}

export default defineComponent({
  props: {
    status: {
      type: String as PropType<OrderStatus>,
      default: OrderStatus.ALIVE,
    },
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    simplified: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const {
      orderTime,
      productName,
      startStoreLink,
      startTime,
      endTime,
      price,
      insurances,
      refund,
      refundRate,

      checkOrder,
      cancel,
      resendEmail,
      changeInvoice,
      payAgain,

      startStore,

      afteeLoading,
    } = useOrderListData(props.order, emit)

    watch(afteeLoading, () => {
      if (afteeLoading.value) {
        isLoading.value = true
      }
    }, { immediate: true })

    const i18n = useI18n()
    const selectMenu = ref<InstanceType<typeof GSelectMenu> | null>(null)

    const showMenu = () => {
      selectMenu.value?.show()
    }

    const list: ComputedRef<Array<OrderCardMoreSelectItem>> = computed(() => {
      const _actions: Array<OrderCardMoreSelectItem> = []
      if (props.order.paid === PaymentStatus.SUCCESS) {
        _actions.push({
          name: String(i18n.t('orderDetail.cancel.apply')),
          value: 'CANCEL',
        })
        if (props.order.vendor === Vendor.CAR) {
          _actions.push(
            {
              name: String(i18n.t('orderDetail.resend.mail')),
              value: 'RESEND_EMAIL',
            })
        }
      } else if (props.order.vendor === Vendor.HERTZ) {
        _actions.push({
          name: String(i18n.t('orderDetail.cancel.apply')),
          value: 'CANCEL',
        })
      }

      return _actions
    })

    const clickSelectMenuItem = (action: OrderCardMoreSelectAction) => {
      switch (action) {
        case 'CANCEL':
          cancel()
          break
        case 'RESEND_EMAIL':
          resendEmail()
          break
        case 'MODIFY_RECEIPT':
          changeInvoice()
          break
      }
    }

    const _payAgain = async () => {
      try {
        isLoading.value = true

        await payAgain(props.order.orderNum!)
      } catch (e: any) {
        const error = (isObject(e?.response?.data))
          ? e.response.data.message
          : { error: e.error ?? [i18n.t('message.confirm.error')] }
        alert(typeof error === 'string' ? error : JSON.stringify(error))
      } finally {
        isLoading.value = false
      }
    }

    const orderNumDisabled = computed(() => props.status === OrderStatus.CANCELED)

    const orderNumClass = computed(() => ({
      'text-primary': !orderNumDisabled,
    }))

    return {
      Vendor,
      isLoading,
      OrderStatus,

      orderNumClass,
      orderTime,
      productName,
      price,
      startStoreLink,
      startTime,
      endTime,
      insurances,
      refund,
      refundRate,

      selectMenu,
      list,
      showMenu,

      clickSelectMenuItem,
      checkOrder,
      _payAgain,

      startStore,

      PaymentStatus,
    }
  },
})
