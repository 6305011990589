import dayjs from 'dayjs'

import useI18n from '@/composables/use-i18n'

export default function (cancelID: string | null) {
  const i18n = useI18n()
  switch (cancelID) {
    case '1':
      return {
        content: String(i18n.t('cancelTerms.flexible')),
        img: `https://cdn.gogoout.com/web/cancel-terms/${i18n.locale}_cancel01.jpeg`,
      }
    case '2':
      return {
        content: String(i18n.t('cancelTerms.moderate')),
        img: `https://cdn.gogoout.com/web/cancel-terms/${i18n.locale}_cancel02.jpeg`,
      }
    case '3':
      return {
        content: String(i18n.t('cancelTerms.strict')),
        img: `https://cdn.gogoout.com/web/cancel-terms/${i18n.locale}_cancel03.jpeg`,
      }
    case '4':
      return {
        content: String(i18n.t('cancelTerms.super.flexible')),
        img: `https://cdn.gogoout.com/web/cancel-terms/${i18n.locale}_cancel00.jpeg`,
      }
    default:
      return null
  }
}

export function calcCancelDate (
  cancelID:string | null,
  rentDate: string | null = null) {
  if (rentDate) {
    const rentDay = dayjs(rentDate, 'YYYY-MM-DD')
    let cancelLastDay
    switch (cancelID) {
      // flexible: 3, 2
      case '1':
        cancelLastDay = rentDay.subtract(3, 'day')
        if (dayjs().isAfter(cancelLastDay)) {
          return null
        } else {
          return dayjs(cancelLastDay).format('YYYY-MM-DD')
        }
      // moderate: 6, 3
      case '2':
        cancelLastDay = rentDay.subtract(6, 'day')
        if (dayjs().isAfter(cancelLastDay)) {
          return null
        } else {
          return dayjs(cancelLastDay).format('YYYY-MM-DD')
        }
      // strict: 9, 4
      case '3':
        cancelLastDay = rentDay.subtract(9, 'day')
        if (dayjs().isAfter(cancelLastDay)) {
          return null
        } else {
          return dayjs(cancelLastDay).format('YYYY-MM-DD')
        }
      // super flexible 1
      case '4':
        return dayjs(rentDay.subtract(1, 'day')).format('YYYY-MM-DD')
      default:
        return null
    }
  } else {
    return null
  }
}
