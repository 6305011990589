import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

/** 判斷是否為「週末」 */
export function getIsWeekend (date: Dayjs) {
  const [sunday, saturday] = [0, 6] // 週日和週六
  return [sunday, saturday].includes(dayjs(date).day())
}

export function getWeekDays (date: Dayjs) {
  return dayjs(date).day()
}

/** 根據營業時間字串起訖取得小時範圍 */
export function getOperationHourByOperationTime (
  [start, end]: [string, string]
): [number, number] {
  const format = 'HH:mm'

  /** 無條件進位至小時 */
  const ceil = (time: string) => dayjs(time, format)
    .add(59, 'minute').startOf('hour').get('hour')

  /** 無條件捨去至小時 */
  const floor = (time: string) => dayjs(time, format)
    .startOf('hour').get('hour')

  return [
    Math.max(ceil(start), 0),
    Math.min(floor(end), 23),
  ]
}
