export default function () {
  const getRange = (from: number, to: number) => {
    const list = []
    for (let i = from; i <= to; i++) list.push(i)
    return list
  }

  return {
    getRange,
  }
}
