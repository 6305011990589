

























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { isString, isObject } from 'typechecker'

export type ListItem = string | { content: string, link?: string }

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<ListItem[] | null>,
      default: null,
    },
    twoRows: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    return {
      isString,
      isObject,
    }
  },
})
