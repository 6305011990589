/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

import PaymentMethod from '@/enums/PaymentMethod'
import { CLSMetric, FCPMetric, INPMetric, LCPMetric, TTFBMetric } from 'web-vitals'

export enum LogLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
}

export enum ErrorType {
  API_ERROR = 'api_error',
  SSO_ERROR = 'sso_error',
  BIND_CARD_ERROR = 'bind_card_error',
  ERROR_BOUNDARY = 'error_boundary',
  BROWSER_ERROR = 'browser_error',
  THIRD_PARTY_PAYMENT_TOKEN_ERROR = 'third_party_payment_token_error', // 獲取 token 錯誤
}

export enum LogName {
  /** 錯誤日誌 */
  ERROR = 'ggo-error',
  /** toast 日誌 */
  TOAST = 'c-ggo-toast',
  /** 頁面訪問日誌 */
  PAGE_VIEW = 'c-ggo-page-view',
  /** 頁面離開日誌 */
  PAGE_DISAPPEAR = 'c-ggo-page-disappear',
  /** 訂單日誌 (temp), 未來由後端來記錄 */
  ORDER = 'ggo-order',
  // TODO: 點擊日誌
  /** 點擊日誌 */
  CLICK = 'c-ggo-click',
  /** 搜尋日誌 */
  SEARCH = 'c-ggo-search',
  /** API 日誌 */
  API_REQUEST = 'c-ggo-api-request',
  /** 實驗開始 日誌 */
  EXP_VIEWED = 'ggo-exp-viewed',
  /** 地圖互動 日誌 */
  MAP_INTERACT = 'c-ggo-map-interact',
  /** Web Vitals */
  WEB_VITALS = 'c-ggo-web-vitals',
  /** 搜尋結果0台車 行為分析 */
  SEARCH_ZERO='c-ggo-zero-car-search'
}

export enum Severity {
  Emergency = 0,
  Alert = 1,
  Critical = 2,
  Error = 3,
  Warning = 4,
  Notice = 5,
  Informational = 6,
  Debug = 7
}

export interface HttpRequest {
  /** 請求完整 url. e.g. 'https://gogoout.com/search' */
  url: string;
  /** 主要請求 url, 剔除 id 部分. e.g. 'https://gogoout.com/quote/123' -> 'https://gogoout.com/quote' */
  primary_url?: string;
  /** 請求方法 e.g. 'GET' */
  method: string;
  /** 請求 Headers */
  headers?: Record<string, string | number | boolean>;
  /** 請求參數 query string */
  params?: Record<string, string | number | boolean>;
  /** 請求參數 request body */
  data?: Record<string, string | number | boolean>;
}

export interface User {
  /** 用戶id, 未登入為 '-1' */
  user_id: string;
  /** 用戶當前語言配置 */
  language: string;
  /** 用戶ip */
  ip: string;
  /** 用戶設備id */
  device_id: string;
  /** 瀏覽器 */
  browser?: string;
  /** 瀏覽器版本 */
  browser_version?: string;
  /** User Agent */
  user_agent: string;
  /** 用戶設備 OS e.g. 'iOS' */
  os: string;
  /** 用戶設備 OS 版本 e.g. '16.4' */
  os_version: string;
  /** 官網版本 */
  web_version: string;
  /** session id */
  session_id: string;
}

export interface Experiment {
  value: Record<string, any>
}

export interface Feature {
  value: Record<string, any>
}

export interface LogCommon {
  /** 日誌名 (用於在 OpenSearch 建立 index) */
  log_name: LogName;
  /** 日誌等級 */
  log_level: LogLevel;
  /** 客戶端類型 e.g. iOS/Android/web */
  client_type: string;
  /** 服務名 */
  service: string;
  /** 發起請求的ip (考慮到後端會有服務向服務發起請求) */
  ip: string;
  /** 請求的 host e.g. 'https://gogoout.com' */
  host: string;
  /** 日誌記錄當下的時間戳 */
  timestamp: number;
  /** http 請求相關 (方便取用) */
  http_request?: HttpRequest;
  /** Feature toggling 相關 */
  experiment?: Experiment
  /** Feature toggling 相關 */
  feature?: Feature
}

export interface DynamicErrorLog {
  severity: Severity;
  error_type: ErrorType;
  http_request?: HttpRequest;
  status_code: number;
  message: string;
  error_stack?: string;
  /** 其他資料 (目前用於 TapPay getPrime / 91APP getTxnToken) */
  data?: Record<string, any>;
}

export interface ErrorLogData extends LogCommon {
  /** 錯誤嚴重等級 */
  severity: Severity;
  /** 錯誤類型 */
  error_type: ErrorType;
  /** 錯誤發生位置, 在 App 為 routeName */
  location: string;
  /** 錯誤碼 */
  status_code: number;
  /** 錯誤訊息 */
  message: string;
  /** 錯誤詳情 */
  error_stack: string;
  /** http 請求相關 */
  http_request?: HttpRequest;
  /** 用戶相關 */
  user: User;
  /** 其他資料 (目前用於 TapPay getPrime) */
  data?: Record<string, any>;
}

export interface ToastLogData {
  type?: 'success' | 'warning' | 'danger';
  message: string;
}

export interface PageViewLogData {
  /** 當前頁面 */
  location: string;
  /** 上一頁面 */
  prev_location: string;
  /** 上一路由名稱 */
  prev_router_name?: string;
  /** referrer */
  referrer?: string;
  /** 路由名稱 */
  router_name?: string;
  /** referrer名稱 */
  referrer_name?: string;
  /** query string */
  query?: string
}

export interface PageDisappearLogData {
  /** 當前頁面 */
  location: string;
  /** 停留時長 (ms) */
  duration: number;
}

export interface OrderLogData {
  order: {
    order_id: string;
    order_status: 'PENDING' | 'RESERVED';
    price: number;
    currency: string;
    payment_method: keyof typeof PaymentMethod | '';
    order_time: string;
  },
  payment?: {
    payment_method: keyof typeof PaymentMethod | '';
    pay_time: string;
  }
}

export interface SearchLogData {
  /** 日期時間 */
  datetime: {
    start_date: string;
    start_time: string;
    end_date: string;
    end_time: string;
  };
  /** 搜尋地點 */
  search_location: {
    start_latitude: number;
    start_longitude: number;
    start_location?: string;
    end_latitude: number;
    end_longitude: number;
    end_location?: string;
  };
  /** 篩選器 */
  filter?: {
    /** 價格 */
    price_min?: number;
    price_max?: number;
    /** 車齡 */
    age_min?: number;
    age_max?: number;
    /** 座位數 */
    seat_min?: number;
    seat_max?: number;
    /** 付款方式 id 列表 */
    payment_method_ids?: number[];
    /** 車輛類型 id 列表 */
    car_type_ids?: number[];
    /** 車輛品牌 id 列表 */
    brand_ids?: number[];
    /** 店家服務 id 列表 */
    service_ids?: number[];
    /** 車輛配備 id 列表 */
    equipment_ids?: number[];
    /** 是否接受國際駕照 */
    intl_driving_permit?: boolean;
    /** 排序方式 */
    order_by?: string;
  };
  /** 搜尋結果 */
  result: {
    /** 店家數 */
    store_count: number;
    /** 車輛數 */
    vehicle_count: number;
    // 推薦系統分辨每次查詢的 query id
    qid?: string
  };
}

export interface ClickLogData {
  /** 按鈕/模塊id */
  bid: string;
}

export interface MapInteractLogData {
  /** 互動類型 */
  interact_type: 'drag' | 'zoom-in' | 'zoom-out' | 'marker-click';
  /** 店家id */
  store_id?: string;
  /** 店家數量 */
  store_count?: number;
  /** marker文字 */
  marker_text?: string;
}

export interface WebVitalsLogData {
  /** web vitals */
  web_vitals: {
    cls?: CLSMetric;
    inp?: INPMetric;
    lcp?: LCPMetric;
    fcp?: FCPMetric;
    ttfb?: TTFBMetric;
  }
}

export interface ApiRequestLogData {
  /** 請求開始時間戳 */
  request_start_time: number;
  /** 請求結束時間戳 */
  request_end_time: number;
  /** API 耗時 (ms) */
  request_duration: number;
}

export interface ExpViewedLogData {
  /** 實驗名稱 */
  experimentId: string;
  /** 實驗變量ID */
  variationId: number
  /** 實驗變量值 */
  variationValue?: any
}

export type LogData = ErrorLogData | ToastLogData | PageViewLogData | PageDisappearLogData | OrderLogData | SearchLogData | ClickLogData | ApiRequestLogData | ExpViewedLogData | MapInteractLogData | WebVitalsLogData;

export enum LogToastMsg {
  SEARCH_LOCATION_INVALID = 'Search: location is invalid',
  SEARCH_DATE_EMPTY = 'Search: date is empty',
  SEARCH_DATE_INVALID = 'Search: date is invalid',
  SEARCH_DATE_VALID = 'Search: date is valid',

  CONFIRM_FIELDS_INVALID = 'Confirm: fields are invalid',
  CONFIRM_PAYMENT_ERROR = 'Confirm: payment error',

  CONFIRM_HERTZ_FETCH_ASYNC_DATA_ERROR = 'Confirm Hertz: fetch async data error',

  REGISTER_ERROR = 'Register error',
  REGISTER_SUCCESS = 'Register success',
  LOGIN_REGISTER_SUCCESS = 'Login after register success',
  LOGIN_REGISTER_ERROR = 'Login after register error',
  LOGIN_SUCCESS = 'Login success',
  LOGIN_ERROR = 'Login error',
  LOGOUT_SUCCESS = 'Logout success',
  LOGOUT_ERROR = 'Logouts error',

  UPDATE_USER_PROFILE_SUCCESS = 'Update user profile success',
  UPDATE_USER_PROFILE_ERROR = 'Update user profile error',

  HERTZ_CREATE_ORDER_ERROR = 'Hertz: create order error',

  ORDER_RESEND_EMAIL_ERROR = 'Order: resend email error',
  ORDER_CANCEL_ORDER_ERROR = 'Order: cancel order error',
  ORDER_GET_CANCEL_ORDERS_SUCCESS = 'Order: get cancel orders success',
  ORDER_GET_CANCEL_ORDERS_ERROR = 'Order: get cancel orders error',
  ORDER_GET_ORDER_DETAIL_SUCCESS = 'Order: get order detail success',
  ORDER_GET_ORDER_DETAIL_ERROR = 'Order: get order detail error',
  ORDER_GET_ORDERS_SUCCESS = 'Order: get orders success',
  ORDER_GET_ORDERS_ERROR = 'Order: get orders error',
  ORDER_CHECK_REVIEW_SUCCESS = 'Order: check review success',
  ORDER_CHECK_REVIEW_ERROR = 'Order: check review error',
  ORDER_CHANGE_INVOICE_SUCCESS = 'Order: change invoice success',
  ORDER_CHANGE_INVOICE_ERROR = 'Order: change invoice error',

  ORDER_ORDER_RATING_LIST_MODAL_ERROR = 'Order: OrderRatingList open modal error',

  CARD_SET_DEFAULT_ERROR = 'Card: set default card error',
  CARD_DELETE_ERROR = 'Card: delete error',

}
