export default function () {
  const injectScript = (src: string, option: Record<keyof HTMLScriptElement, any> | null = null) => {
    // IIFE 學 gtm 埋入方式
    (function (_w, d, s) {
      const firstScriptElem = d.getElementsByTagName(s)[0]
      const scriptElem = d.createElement(s) as HTMLScriptElement

      scriptElem.async = true
      scriptElem.src = src

      if (option) {
        for (const [key, value] of Object.entries(option)) {
          scriptElem.setAttribute(key, value)
        }
      }

      firstScriptElem.parentNode?.insertBefore(scriptElem, firstScriptElem)
    })(window, document, 'script')
  }

  const lazyInjectScript = (src: string, option: Record<keyof HTMLScriptElement, any> | null = null, timeout: number = 4000) => {
    setTimeout(() => {
      injectScript(src, option)
    }, timeout)
  }

  return {
    injectScript,
    lazyInjectScript,
  }
}
