



















import { computed, defineComponent, PropType, toRefs } from '@nuxtjs/composition-api'
import useProxyValue from '@/composables/use-proxy-value'
import { isObject } from 'typechecker'

type Item = {
  name: string
  value: string | number
  disabled?: boolean
} | string | number

export default defineComponent({
  props: {
    value: {
      default: null,
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'name',
    },
    label: {
      type: String,
      default: null,
    },
    list: {
      type: Array as PropType<Item[] | null>,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String as PropType<String | null>,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pointer: {
      type: Boolean,
      default: false,
    },
    selectClass: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit, attrs }) {
    const { proxyValue } = useProxyValue(toRefs(props).value, emit)

    const bind = computed(() => ({
      ...attrs,
      disabled: props.disabled,
      class: {
        active: props.active,
      },
    }))

    const getItem = (value: Item) => {
      if (isObject(value)) return value
      return {
        name: value,
        value,
      }
    }

    const _selectClass = computed(() => ({
      error: props.error,
      'cursor-pointer': props.pointer,
      [props.selectClass]: true,
    }))

    const labelClass = computed(() => ({
      'text-negative': props.error,
    }))

    return {
      proxyValue,
      bind,
      getItem,
      _selectClass,
      labelClass,
    }
  },
})
