import { render, staticRenderFns } from "./TheNavbar.vue?vue&type=template&id=4fe45996&lang=pug&"
import script from "./TheNavbar.vue?vue&type=script&lang=ts&"
export * from "./TheNavbar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GSihImage: require('/app/components/GSihImage.vue').default,LanguageSwitcher: require('/app/components/LanguageSwitcher.vue').default,GBtn: require('/app/components/GBtn.vue').default,TheNavbarBody: require('/app/components/the/TheNavbarBody.vue').default,GSidePanel: require('/app/components/GSidePanel.vue').default,GDialogOrMenu: require('/app/components/GDialogOrMenu.vue').default,AccountLoginOrSignup: require('/app/components/account/AccountLoginOrSignup.vue').default,GModal: require('/app/components/GModal.vue').default})
