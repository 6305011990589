import { render, staticRenderFns } from "./OrderChangeInvoice.vue?vue&type=template&id=5c98ddf2&scoped=true&lang=pug&"
import script from "./OrderChangeInvoice.vue?vue&type=script&lang=ts&"
export * from "./OrderChangeInvoice.vue?vue&type=script&lang=ts&"
import style0 from "./OrderChangeInvoice.vue?vue&type=style&index=0&id=5c98ddf2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c98ddf2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GFieldInvoice: require('/app/components/GFieldInvoice.vue').default,GBtn: require('/app/components/GBtn.vue').default,GCard: require('/app/components/GCard.vue').default})
