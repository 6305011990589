import User from '@/classes/User'
import useI18n from '@/composables/use-i18n'
import LoginStatus from '@/enums/LoginStatus'

import ggoApi from '@/modules/ggo-api'

// 驗證使用者的各種 function
export default function () {
  const i18n = useI18n()

  const checkEmailExist = async (email: string) => {
    const params = {
      email,
    }
    const { success } = await ggoApi.emailExist(params)

    return success
  }

  const checkMissingField = (data: Partial<ConstructorParameters<typeof User>[0]>) => {
    const { firstName, lastName, birthday, email } = data

    if (firstName && lastName && birthday && email) {
      return false
    } else {
      return true
    }
  }

  const checkLoginStatus = (state: string) => {
    if (state === String(LoginStatus.USER_FORBIDDEN)) {
      return i18n.t('user.login.banned')
    } else if (state === String(LoginStatus.USER_CANCEL)) {
      return i18n.t('user.authorization.failed')
    } else if (state === String(LoginStatus.THIRD_PARTY_ERROR)) {
      return i18n.t('message.error')
    }

    return i18n.t('message.error')
  }

  return {
    checkEmailExist,
    checkMissingField,
    checkLoginStatus,
  }
}
