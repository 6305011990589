import { Context } from '@nuxt/types'
import { isValidCoordinate } from 'geolib'
import { isArray, isString } from 'typechecker'

import DateHourRange, { QueryObject as DateHourRangeQueryObject } from '@/classes/DateHourRange'
import MapLocation, { QueryObject as MapLocationQueryObject } from '@/classes/MapLocation'
import SearchFilter from '@/classes/SearchFilter'
import SeatRange from '@/classes/SeatRange'

const { parseQueryValue } = SearchFilter

type Query = Context['query']

export const getFirstItem = (value: Context['query']['query']) => {
  if (isArray(value)) return (value as Array<string>)[0]
  else if (isString(value)) return value as string
}

export function parseSearchUrlQuery (query: Query) {
  const mapLocation = parseMapLocation(query)
  const mapReturnLocation = parseMapReturnLocation(query)
  const dateHourRange = parseDateHourRange(query)
  const seats = parseSeats(query)

  return { mapLocation, mapReturnLocation, dateHourRange, seats }
}

/** 解析 query 物件取得 MapLocation */
export function parseMapLocation (query: Query): MapLocation | undefined {
  try {
    const { location, country, maxDistance } = query

    const lat = getFirstItem(query.rentLat)
    const lng = getFirstItem(query.rentLng)

    if (!(lat && lng)) {
      throw new Error('Missing `lat` or `lng`!')
    }

    if (!isValidCoordinate({ latitude: +lat, longitude: +lng })) {
      throw new Error('Invalid coordinate!')
    }

    const queryObject: MapLocationQueryObject = { lat, lng }

    if (typeof location === 'string') queryObject.location = location
    if (typeof country === 'string') queryObject.country = country
    if (typeof maxDistance === 'string') queryObject.maxDistance = Number(maxDistance)

    return MapLocation.fromQueryObject(queryObject)
  } catch (e) {
  }
}

/** 解析 query 物件取得 MapReturnLocation */
export function parseMapReturnLocation (query: Query): MapLocation | undefined {
  try {
    const { returnLocation, country } = query

    const lat = getFirstItem(query.returnLat)
    const lng = getFirstItem(query.returnLng)

    if (!(lat && lng)) {
      throw new Error('Missing `lat` or `lng`!')
    }

    if (!isValidCoordinate({ latitude: +lat, longitude: +lng })) {
      throw new Error('Invalid coordinate!')
    }

    const queryObject: MapLocationQueryObject = { lat, lng }

    if (typeof returnLocation === 'string') queryObject.location = returnLocation
    if (typeof country === 'string') queryObject.country = country

    return MapLocation.fromQueryObject(queryObject)
  } catch (e) {
  }
}

/** 解析 query 物件取得 DateHourRange */
export function parseDateHourRange (query: Query): DateHourRange | undefined {
  return DateHourRange.fromQueryObject(query as DateHourRangeQueryObject)
}

/** 解析 query 物件取得 Seats */
export function parseSeats (query: Query): SeatRange | undefined {
  try {
    const { seats } = query
    if (!(typeof seats === 'string')) throw new TypeError('`seats` must be a string')
    return SeatRange.fromString(seats)
  } catch (e) {
  }
}

/** 解析 query 物件取得 SearchFilter */
export function parseSearchFilter (query: Query): SearchFilter | undefined {
  return SearchFilter.fromQueryObject(query)
}

/** 解析 query 物件取得 activeStore */
export function parseActiveStore (query: Query): number | string | undefined {
  const href = window.location.href
  try {
    if (href.toLowerCase().includes('hertz')) {
      const locationCode = String(getFirstItem(query.activeStore))
      return locationCode || undefined
    } else {
      const id = Number(getFirstItem(query.activeStore))
      return id || undefined
    }
  } catch (e) {
  }
}

/** 解析 query 物件取得 locationCode */
export function parseLocationCode (query: Query): string | undefined {
  if (query.activeStoreId) return String(getFirstItem(query.activeStore))
}

/** 解析 query 物件取得 search mode */
export function parseSearchMode <T> (
  query: Query,
  list: Readonly<T[]>
): T {
  const defaultMode = list[0]
  try {
    const mode = getFirstItem(query.mode) as unknown as T
    return (mode && list.includes(mode)) ? mode : defaultMode
  } catch (e) {
    return defaultMode
  }
}

/** 解析 query 物件取得 optionalItems */
export function parseOptionalItems (query: Query) {
  return parseQueryValue(query.optionalItems)
}

/** 解析 query 物件取得 serviceItems */
export function parseServiceItems (query: Query) {
  return parseQueryValue(query.serviceItems)
}

/** 解析 query 物件取得 referralAds */
export function parseReferralAds (query: Query) {
  return parseQueryValue(query.referralAds)
}
