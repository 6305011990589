import useI18n from '@/composables/use-i18n'
import Vendor from '@/enums/Vendor'
import { Invoice } from '@/interfaces/Invoice'
import ggoApi from '@/modules/ggo-api'
import Order, { OrderArgument } from '@/classes/Order'
import OrderDetail from '@/classes/OrderDetail'
import Price from '@/classes/Price'
import alert from '@/modules/alert'
import { LogToastMsg } from '~/interfaces/Logger'

export default function () {
  const i18n = useI18n()

  const getOrders: () => Promise<Order[]> = async () => {
    return await ggoApi.getOrders()
      .then(({ data }) => {
        return data ? data.map((order: OrderArgument) => new Order(order)) : []
      })
  }

  const getOrderDetail = async (orderNum: string, vendor?: string) => {
    const params = {
      orderNum,
      vendor: vendor ?? Vendor.CAR,
    }
    const { data, success, error } = await ggoApi.getOrderDetail(params)

    if (!success || !data) {
      throw new Error(error ?? `Cannot find order detail with order num ${orderNum}`)
    }

    // 轉換 credit_last_digits string to number
    data.credit_last_digits = Number(data.credit_last_digits)
    // 轉換 : cancelledAt -> cancel_at
    data.cancel_at = data.cancelledAt
    // 需要轉換 to Price
    data.refund = new Price((data.refund as any) ?? 0, {
      code: 'NTD',
      sign: '$',
    })
    // 轉換 status
    data.status = data.order_status

    return new OrderDetail(data)
  }

  const getCancelOrder = async (orderNum: string, vendor?: string) => {
    const params = {
      orderNum,
      vendor: vendor ?? Vendor.CAR,
    }
    const { data } = await ggoApi.getCancelOrder(params)

    return data
  }

  const getRefundPercentage = (e:number) => {
    switch (e) {
      case 100:
        return String(i18n.t('cancelTerms.refundPercentage100'))
      case 0:
        return String(i18n.t('cancelTerms.refundPercentage0'))
      default:
        return String(i18n.t('cancelTerms.refundPercentageElse', [e]))
    }
  }

  const cancelOrder = async (orderNum: string, vendor: string) => {
    const params = {
      orderNum,
      vendor,
    }
    const { success } = await ggoApi.cancelOrder(params)

    return success
  }

  const resendMail = async (orderNum: string, vendor: string) => {
    const params = {
      orderNum,
      vendor,
    }
    const { success } = await ggoApi.resendMail(params)

    return success
  }

  const checkReview = async (orderNum: string) => {
    const params = {
      orderNum,
    }
    const { data } = await ggoApi.checkReview(params)

    return data
  }

  const getReviewFromNumber = (filled:boolean, n: number) => {
    if (!filled) return i18n.t('orderDetail.order.review.none')
    switch (n) {
      case 1:
        return i18n.t('orderDetail.order.review.poor')
      case 2:
        return i18n.t('orderDetail.order.review.good')
      case 3:
        return i18n.t('orderDetail.order.review.excellent')
      default:
        return i18n.t('orderDetail.order.review.good')
    }
  }

  /**
   * @deprecated: 在下訂立即開立發票後，即無法修改發票，UI 已拔除，功能保留，未來不需使用再拔除
   */
  const changeInvoice = async (orderNum: string, invoice: Invoice, vendor: string | null) => {
    const { success, status } = await ggoApi.changeInvoice({
      orderNum,
      body: {
        invoice,
      },
      params: {
        vendor,
      },
    })
    if (success) {
      const alertMsg = String(i18n.t('orderDetail.changeInvoiceSuccess'))
      alert(alertMsg, {
        type: 'success',
        message: `${LogToastMsg.ORDER_CHANGE_INVOICE_SUCCESS}: ` + alertMsg,
      })
    } else if (status === 404) {
      const alertMsg = String(i18n.t('orderDetail.cantFindOrder'))
      alert(alertMsg, {
        message: `${LogToastMsg.ORDER_CHANGE_INVOICE_ERROR}: ` + alertMsg,
      })
    } else {
      const alertMsg = String(i18n.t('message.confirm.fetchUserError'))
      alert(alertMsg, {
        message: `${LogToastMsg.ORDER_CHANGE_INVOICE_ERROR}: ` + alertMsg,
      })
    }
  }

  const getStore = (storeID: string) => `${process.env.NUXT_BASE_URL}/store/${storeID}`

  return {
    getOrders,
    getOrderDetail,
    getCancelOrder,
    cancelOrder,
    getRefundPercentage,
    resendMail,
    checkReview,

    getReviewFromNumber,
    changeInvoice,

    getStore,
  }
}
