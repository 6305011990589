import { render, staticRenderFns } from "./OrderGetReview.vue?vue&type=template&id=62ffb5e4&scoped=true&lang=pug&"
import script from "./OrderGetReview.vue?vue&type=script&lang=ts&"
export * from "./OrderGetReview.vue?vue&type=script&lang=ts&"
import style0 from "./OrderGetReview.vue?vue&type=style&index=0&id=62ffb5e4&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62ffb5e4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GScore: require('/app/components/GScore.vue').default,GBtn: require('/app/components/GBtn.vue').default,GCard: require('/app/components/GCard.vue').default})
