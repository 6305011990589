import { Plugin } from '@nuxt/types'
import { onLCP, onINP, onCLS, onFCP, onTTFB } from 'web-vitals'
import ggoLogger from '@/modules/ggo-logger'
import { LogName } from '@/interfaces/Logger'

export default (() => {
  if (process.client) {
    onCLS(cls => ggoLogger.info(LogName.WEB_VITALS, {
      web_vitals: {
        cls,
      },
    }))
    onINP(inp => ggoLogger.info(LogName.WEB_VITALS, {
      web_vitals: {
        inp,
      },
    }))
    onLCP(lcp => ggoLogger.info(LogName.WEB_VITALS, {
      web_vitals: {
        lcp,
      },
    }))
    onFCP(fcp => ggoLogger.info(LogName.WEB_VITALS, {
      web_vitals: {
        fcp,
      },
    }))
    onTTFB(ttfb => ggoLogger.info(LogName.WEB_VITALS, {
      web_vitals: {
        ttfb,
      },
    }))
  }
}) as Plugin
