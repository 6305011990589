import CarSource from '@/enums/CarSource'
import { useRoute } from '@nuxtjs/composition-api'

/** 判斷租車地點 */
export default function () {
  const route = useRoute()
  const path = route.value.path

  if (path.toLowerCase().includes('hertz')) return CarSource.HERTZ
  else return CarSource.GOGOOUT
}
