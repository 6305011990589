

























import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import Order from '@/classes/Order'
import useOrderListData from '@/composables/use-order-list-data'
import Vendor from '@/enums/Vendor'
import useI18n from '@/composables/use-i18n'
import { isObject } from 'typechecker'
import alert from '@/modules/alert'
import PaymentStatus from '@/enums/PaymentStatus'
import { ActionItem } from '../cell/OrderTableCellAction.vue'

export default defineComponent({
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()
    const isLoading = ref(false)

    const {
      orderTime,
      productName,
      startStoreLink,
      startTime,
      endTime,
      price,
      insurances,
      checkOrder,

      cancel,
      resendEmail,

      payAgain,

      afteeLoading,
    } = useOrderListData(props.order, emit)

    watch(afteeLoading, () => {
      if (afteeLoading.value) {
        isLoading.value = true
      }
    }, { immediate: true })

    const actions = computed(() => {
      const { order } = props
      const _actions: Array<ActionItem> = []

      if (order.paid === PaymentStatus.SUCCESS) {
        _actions.push({
          value: i18n.t('orderDetail.cancel.apply').toString(),
          onClick: () => cancel(),
          type: 'LINK',
        })
        if (order.vendor === Vendor.CAR) {
          _actions.push({
            value: i18n.t('orderDetail.resend.mail').toString(),
            onClick: () => resendEmail(),
            type: 'LINK',
          })
        }
      } else if (order.vendor === Vendor.HERTZ) {
        _actions.push({
          value: i18n.t('orderDetail.cancel.apply').toString(),
          onClick: () => cancel(),
          type: 'LINK',
        })
      } else {
        _actions.push({
          value: i18n.t('action.checkoutAgain').toString(),
          onClick: () => _payAgain(props.order.orderNum!),
          type: 'BTN',
        })
      }
      return _actions
    })

    const _payAgain = async (orderNum: string) => {
      try {
        isLoading.value = true

        await payAgain(orderNum)
      } catch (e: any) {
        const error = (isObject(e?.response?.data))
          ? e.response.data.message
          : { error: e.error ?? [i18n.t('message.confirm.error')] }
        alert(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      isLoading,

      orderTime,
      productName,
      startStoreLink,
      startTime,
      endTime,
      price,
      insurances,
      actions,

      checkOrder,
    }
  },
})
