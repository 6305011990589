





import { defineComponent, PropType } from '@vue/composition-api'

type OrderTableCellLinkValue = {
  value: string,
  href?: string
}

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<OrderTableCellLinkValue>,
      required: true,
    },
  },
})
