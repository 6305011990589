import { render, staticRenderFns } from "./GFieldBirthday.vue?vue&type=template&id=7116f7cc&lang=pug&"
import script from "./GFieldBirthday.vue?vue&type=script&lang=ts&"
export * from "./GFieldBirthday.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GLabel: require('/app/components/GLabel.vue').default,GSelect: require('/app/components/GSelect.vue').default,GFieldHint: require('/app/components/GFieldHint.vue').default})
