












import { computed, defineComponent } from '@nuxtjs/composition-api'

import useLanguageSwitcher from '@/composables/use-language-switcher'

export default defineComponent({
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const textClass = computed(() => props.dark ? 'text-white' : 'text-black')

    const {
      currentLocaleCode,
      currentLocaleName,
      locales,
    } = useLanguageSwitcher()

    return {
      currentLocaleCode,
      currentLocaleName,
      locales,
      textClass,
    }
  },
})
