


















































import User from '@/classes/User'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import OrderStatus from '@/enums/OrderStatus'
import { useContext } from '@nuxtjs/composition-api'

type LinkType = {
  name: string,
  link: string,
}

export default defineComponent({
  props: {
    links: {
      type: Array as PropType<LinkType[]>,
      default: () => [],
    },
    user: {
      type: Object as PropType<User | null>,
      default: null,
    },
  },
  setup (props, { emit }) {
    const { app: { localePath } } = useContext()

    // Invite
    const inviteCode = computed(() => String(props.user?.id))
    const inviteContent = ref<string | null>(null)

    const signOut = () => emit('sign-out')

    const copyLink = () => {
      const id = props.user?.id
      const link = process.env.NUXT_BASE_URL + localePath(`/member/signup?id=${id}`)
      navigator.clipboard.writeText(link)
    }

    return {
      signOut,
      OrderStatus,

      copyLink,

      inviteCode,
      inviteContent,
    }
  },
})
