export const googleOauthUri = (options: { locale: string }) => {
  const clientId = process.env.GOOGLE_CLIENT_ID
  const redirectUri = `${process.env.NUXT_BASE_URL}/api/oauth/google/callback`
  const scope = 'https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+openid'
  const state = `web,${options.locale}`

  return `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&response_type=code`
}

export const facebookOauthUri = (options: { locale: string }) => {
  const clientId = process.env.FACEBOOK_CLIENT_ID
  const redirectUri = `${process.env.NUXT_BASE_URL}/api/oauth/facebook/callback`
  const scope = 'email%2Cpublic_profile'
  const state = `web,${options.locale}`

  return `https://www.facebook.com/v14.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&response_type=code`
}

export const appleOauthUri = (options: { locale: string }) => {
  const redirectUri = `${process.env.NUXT_BASE_URL}/api/oauth/apple/callback`
  const scope = 'email%20name'
  const state = `web,${options.locale}`

  return `https://appleid.apple.com/auth/authorize?client_id=com.gogoout.auth&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&response_mode=form_post&response_type=code`
}
