/* eslint-disable no-unused-vars */
import { DynamicErrorLog, ErrorLogData, ErrorType, LogCommon, LogData, LogLevel, LogName, Severity, User } from '@/interfaces/Logger'
import PaymentMethod from '@/enums/PaymentMethod'
import Price from '@/classes/Price'
import dayjs from 'dayjs'
import UAParser from 'ua-parser-js'
/** 存在 store 的 user */
import StoreUser from '@/classes/User'
import GgoTrackKey from '@/enums/GgoTrackKey'
import Cookies from 'js-cookie'
import gbClient from '@/modules/growthbook/gb-client'
import { getRouteNameFromUrl } from '@/utils/route'
import { isbot } from 'isbot'

/**
 * Utils
 */
/** 從 url 取出 primary url (過濾 endpoint 上的 id)  */
export const getPrimaryUrl = (url: string): string => {
  const regexList = [
    // 純數字
    /\/\d+$/,
    // 訂單 pattern
    /[A-Z]{1,3}\d{6}[A-Z\d]{5}$/,
  ]
  for (const regex of regexList) {
    if (regex.test(url)) {
      return url.replace(regex, '')
    }
  }
  return url
}

class GgoLogger {
  /** 將 logger 放入 plugin, middleware
   * 會出現每次切換路由都會呼叫更改 currentRoutePath
   * 更改 currentRoutePath 時可能還沒 setup
   * 而為防止每次進到 middleware 都呼叫 setup
   * 用 boolean 紀錄是否已 setup
   * */
  #doneSetup = false

  /** 客戶端類型 e.g. iOS/Android/web */
  #client_type = 'web'
  /** 服務名 */
  #service = 'Gogoout Web'
  /** 發起請求的ip (考慮到後端會有服務向服務發起請求) */
  #ip: string = ''
  #user!: User

  #host: string = ''
  #fluentBitPath: string = ''

  #currentRouteName: string | undefined
  #currentRoutePath: string = ''

  /**
   * 獲取用戶 IP 地址
   * @returns {Promise<string>} ip address
   */
  private async getIpAddress (): Promise<string> {
    let ip = '0.0.0.0'
    const res = await fetch(new URL('ip', process.env.NUXT_BASE_URL).toString())
    if (res.ok) {
      ip = await res.text()
    } else {
      this.error({
        severity: Severity.Warning,
        error_type: ErrorType.API_ERROR,
        http_request: {
          url: res.url,
          method: 'GET',
        },
        status_code: res.status || 0,
        message: 'Get IP address failed',
      })
    }
    return ip
  }

  /**
   * 獲取 sessionId, 常存在 sessionStorage
   */
  private getSessionId (): string {
    if (!process.server) {
      return Cookies.get(GgoTrackKey.SESSION_ID_STORE_KEY) || ''
    }

    return ''
  }

  /**
   * 獲取 deviceId, 常存在 localStorage
   */
  private getDeviceId (): string {
    if (!process.server) {
      return Cookies.get(GgoTrackKey.DEVICE_ID_STORE_KEY) || ''
    }

    return ''
  }

  setup () {
    /**
     * 在 Nuxt 的 logger 主要是發送 c-log
     * 因此非 client 端 (即 server 端) 不送 log
     */
    if (process.server) return
    if (this.#doneSetup) return

    this.#host = process.env.NUXT_BASE_URL ?? ''
    this.#fluentBitPath = `${this.#host}/log`

    this.#ip = '0.0.0.0'
    this.#user = {
      user_id: '-1',
      language: 'en', // default
      web_version: process.env.NUXT_VERSION || '',
      ip: '0.0.0.0',
      device_id: this.getDeviceId(),
      session_id: this.getSessionId(),
      ...this.getDeviceInfo(),
    }

    this.update()

    this.#doneSetup = true
  }

  getDeviceInfo () {
    const parser = new UAParser()
    const parserResults = parser.getResult()

    return {
      browser: parserResults?.browser?.name || '',
      browser_version: parserResults?.browser?.version || '',
      os: parserResults?.os?.name || '',
      os_version: parserResults?.os?.version || '',
      user_agent: parserResults?.ua,
    }
  }

  /** 更新 logger 資料 */
  update () {
    this.getIpAddress().then((ip) => {
      this.#ip = ip
      this.#user.ip = ip
    })

    this.#user.device_id = this.getDeviceId()
    this.#user.session_id = this.getSessionId()
  }

  /** 更新 currentRoute 相關參數 */
  setCurrentRoute (path: string, name?: string) {
    this.#currentRoutePath = path
    this.#currentRouteName = name
  }

  /** 只更新 user */
  updateUser (user?: StoreUser) {
    if (!user) this.clearUser()
    else if (this.#user) {
      this.#user.user_id = String(user.id)
    }
  }

  clearUser () {
    if (this.#user) { this.#user.user_id = String(-1) }
  }

  /** 只更新 i18n */
  updateLanguage (language: string) {
    if (this.#user) { this.#user.language = language }
  }

  private async send (data: LogData & Pick<LogCommon, 'log_name' | 'log_level' | 'http_request' | 'experiment' | 'feature'>) {
    try {
      /**
       * 在 Nuxt 的 logger 主要是發送 c-log
       * 因此非 client 端 (即 server 端) 不送 log
       */
      if (process.server) return

      // filter bot
      if (isbot(this.#user.user_agent)) return

      // 補上 primary_url
      if (data.http_request) {
        data.http_request.primary_url = data.http_request.primary_url || getPrimaryUrl(data.http_request.url)
      }

      // Feature toggling
      if (gbClient.client) {
        data.experiment = {
          value: gbClient.experimentMap,
        }
        data.feature = {
          value: gbClient.featureMap,
        }
      }
      const headers = {
        type: 'application/json',
      }
      const blob = new Blob([JSON.stringify(data)], headers)
      await navigator.sendBeacon(this.#fluentBitPath, blob)
    } catch (e) {
      console.log(e)
    }
  }

  /** INFO 日誌 */
  info (logName: LogName, data: LogData) {
    this.send({
      log_name: logName,
      log_level: LogLevel.INFO,
      client_type: this.#client_type,
      service: this.#service,
      host: this.#host,
      ip: this.#ip,
      timestamp: Date.now(),
      user: this.#user,
      ...(process.client &&
        { url: window.location.href }
      ),
      location: this.#currentRoutePath,
      router_name: this.#currentRouteName,
      ...(process.client && {
        referrer: document.referrer,
        referrer_name: getRouteNameFromUrl(document.referrer),
      }),
      ...data,
    })
  }

  /** 錯誤日誌 */
  error (data: DynamicErrorLog) {
    // FluentBit
    // remove `http_request`, add in the end
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { http_request: _, ...restData } = data
    const logData: ErrorLogData = {
      error_stack: data.error_stack || '',
      location: this.#currentRoutePath,
      log_name: LogName.ERROR,
      log_level: LogLevel.ERROR,
      client_type: this.#client_type,
      service: this.#service,
      host: this.#host,
      ip: this.#ip,
      timestamp: Date.now(),
      user: this.#user,
      ...restData,
      ...(data.http_request && {
        http_request: data.http_request,
      }),
    }
    this.send(logData)
  }
}

const ggoLogger = new GgoLogger()
export default ggoLogger

/**
 * 記錄訂單日誌. 未來將由後端記錄.
 * 缺陷如下:
 *  1.無法追蹤 LinePay 成交, 僅能記錄下單
 *  2.僅記錄訂單原價, 不計算使用優惠券/優惠碼之後的最終價格
 *  3.從個人頁【繼續支付】的訂單, 無法追蹤下單時間 (後端無返回下單時間)
 */
export const logOrder = ({
  orderNo,
  orderStatus,
  price,
  paymentMethod,
  orderTime,
}: {
  orderNo: string;
  orderStatus: 'PENDING' | 'RESERVED';
  price?: Price;
  paymentMethod?: keyof typeof PaymentMethod | '';
  orderTime: string;
}) => {
  ggoLogger.info(LogName.ORDER, {
    order: {
      order_id: orderNo,
      order_status: orderStatus,
      price: price?.value || 0,
      currency: price?.currency.code || '',
      payment_method: paymentMethod || '',
      order_time: orderTime,
    },
    ...(orderStatus === 'RESERVED' && {
      payment: {
        payment_method: paymentMethod || '',
        pay_time: dayjs().toISOString(),
      },
    }),
  })
}
