import { Context } from '@nuxt/types'
import { computed, reactive, ref, watch } from '@nuxtjs/composition-api'
import * as EmailValidator from 'email-validator'
import { isNationalIdentificationNumberValid, isResidentCertificateNumberValid } from 'taiwan-id-validator2'

import CarSource from '@/enums/CarSource'

import AddOn from '@/interfaces/AddOn'
import { Flight } from '@/interfaces/Flight'
import { Invoice } from '@/interfaces/Invoice'
import { ConfirmPayment } from '@/interfaces/Payment'
import { Promotion } from '@/interfaces/Promotion'

import useCheckCarSource from '@/composables/use-check-car-source'
import useField from '@/composables/use-field'
import useI18n from '@/composables/use-i18n'

import { parseOptionalItems, parseReferralAds, parseServiceItems } from '@/modules/route-query'
import PaymentMethod from '@/enums/PaymentMethod'
import PaymentType from '@/enums/PaymentType'
import Reference from '@/classes/Reference'

export default function () {
  const i18n = useI18n()
  const carSource = useCheckCarSource()
  const fields = reactive({
    /** 名 */
    firstName: useField('', (v) => {
      if (!v) return String(i18n.t('field.firstName.message.required'))
      if (!/^([^0-9]*)$/.test(v)) return String(i18n.t('field.firstName.message.noNumeric'))
      if (carSource === CarSource.HERTZ && !/^[a-zA-Z\s]+$/.test(v)) return String(i18n.t('field.personalName.message.passport'))
      if (v.length >= 32) return String(i18n.t('field.firstName.message.tooLong'))
      return true
    }),
    /** 姓 */
    lastName: useField('', (v) => {
      if (!v) return String(i18n.t('field.lastName.message.required'))
      if (!/^([^0-9]*)$/.test(v)) return String(i18n.t('field.lastName.message.noNumeric'))
      if (carSource === CarSource.HERTZ && !/^[a-zA-Z\s]+$/.test(v)) return String(i18n.t('field.personalName.message.passport'))
      if (v.length >= 32) return String(i18n.t('field.lastName.message.tooLong'))
      return true
    }),
    /** Email */
    email: useField('', (v) => {
      if (!v) return String(i18n.t('field.email.message.required'))
      if (!EmailValidator.validate(v)) return String(i18n.t('field.email.message.invalid'))
      return true
    }),
    /** 手機: 格式化後 */
    mobile: useField(''),
    /** 手機: 國家代碼 */
    phoneCode: useField(''),
    /** 手機: 手機號碼 */
    phoneNumber: useField(''),
    /** 生日 */
    birthday: useField('', (v) => {
      if (!v) return String(i18n.t('field.birthday.message.required'))
      return true
    }),
    /** 國籍: 是否為台灣 */
    isLocal: useField(true),
    /** 國籍: 身分證字號 */
    identity: useField('', (v) => {
      if (!fields.isLocal.value) return true
      if (!v.trim()) return String(i18n.t('field.identity.taiwanese.message.required'))
      if (
        !(isNationalIdentificationNumberValid(v.trim()) || isResidentCertificateNumberValid(v.trim()))
      ) {
        return String(i18n.t('field.identity.taiwanese.message.invalid'))
      }
      return true
    }),
    /** 國籍: 護照 */
    passport: useField('', (v) => {
      if (fields.isLocal.value && carSource === CarSource.GOGOOUT) return true
      // 避免全空白
      if (!v.trim()) return String(i18n.t('field.passport.notTaiwanese.notAcceptForeigner.message.required'))
      return true
    }),
    /** 密碼 */
    password: useField('', (v) => {
      if (!v) return String(i18n.t('field.newPassword.message.required'))
      const length = 8
      const maxLength = 20
      if (String(v).length < length || String(v).length > maxLength) return String(i18n.t('field.newPassword.message.lengthUnder', [length, maxLength]))
      return true
    }),
    /** 新密碼 */
    newPassword: useField('', (v) => {
      if (!v) return String(i18n.t('field.newPassword.message.required'))
      const length = 8
      const maxLength = 20
      if (String(v).length < length || String(v).length > maxLength) return String(i18n.t('field.newPassword.message.lengthUnder', [length, maxLength]))
      return true
    }),
    /** 是否勾選訂閱電子報 */
    isSendNews: useField(true),
    /** 信用卡: 卡號 */
    creditCardName: useField('', (v) => {
      if (!v) return String(i18n.t('field.creditCard.name.required'))
      if (!/^([^0-9]*)$/.test(v)) return String(i18n.t('field.firstName.message.noNumeric'))
      if (v.length >= 32) return String(i18n.t('field.firstName.message.tooLong'))
      return true
    }),
    /** 信用卡: Email */
    creditCardEmail: useField('', (v) => {
      if (!v) return String(i18n.t('field.creditCard.email.required'))
      if (!EmailValidator.validate(v)) return String(i18n.t('field.creditCard.email.required'))
      return true
    }),
    /** 信用卡: 手機 */
    creditCardMobile: useField('', (v) => {
      if (!v) return String(i18n.t('field.mobile.message.invalid'))
      return true
    }),
    /** 邀請人 ID */
    invitedId: useField(''),
    /** 使用的優惠券名稱 */
    couponName: useField(''),
    /** 金流方式 */
    payment: useField<ConfirmPayment>({
      id: 0,
      valid: false,
      paymentType: PaymentType.UNSPECIFIED,
    }, (v) => {
      // 若付款方式為信用卡，以及透過 TapPay 或 Payments91App，驗證透過 payload 裡的 valid 屬性
      if (v.id === PaymentMethod.CreditCard && (v.paymentType === PaymentType.TAPPAY || v.paymentType === PaymentType.PAYMENTS91APP)) {
        if (!v.valid) return String(i18n.t('field.paymentMethod.creditCard.message.invalid'))
      } if (v.id === PaymentMethod.DebitCard && (v.paymentType === PaymentType.TAPPAY || v.paymentType === PaymentType.PAYMENTS91APP)) {
        if (!v.valid) return String(i18n.t('field.paymentMethod.debitCard.message.invalid'))
      } else if (!v.id) {
        return String(i18n.t('field.paymentMethod.message.required'))
      }

      return true
    }),
  })

  // `fields` 之所有 key
  const fieldKeys = Object.keys(fields) as (keyof typeof fields)[]

  // 手動處理 `mobile` 欄位的驗證
  watch(() => fields.phoneNumber.value, fields.phoneNumber.handleChange)
  const validateMobile = (valid: boolean): boolean => {
    if (!fields.phoneNumber.dirty) return false

    if (!fields.phoneNumber.value) {
      fields.phoneNumber.setError(String(i18n.t('field.mobile.message.required')))
      return false
    } else if (!valid) {
      fields.phoneNumber.setError(String(i18n.t('field.mobile.message.invalid')))
      return false
    } else {
      fields.phoneNumber.resetField()
      return true
    }
  }

  // 在切換國籍時清空未被選中的欄位的錯誤狀態
  watch(() => fields.isLocal.value, (value) => {
    if (value) {
      fields.passport.resetField()
      fields.identity.handleBlur()
    } else {
      fields.identity.resetField()
      fields.passport.handleBlur()
    }
  })

  /** 發票 */
  const invoice = useField<Invoice | null>(null)

  /** gogoout 航班資訊 */
  const flight = reactive<Flight>({
    start: {
      date: '',
      number: '',
    },
    end: {
      date: '',
      number: '',
    },
  })

  /** Hertz member ID */
  const hertzMembershipId = ref<string | null>(null)

  /** Hertz 航班號碼 */
  const flightNumber = ref<string | null>(null)

  /** 日本保險資訊 */
  const JapanInsuranceService = ref<boolean>(false)

  /** 優惠相關 */
  const promotion = reactive<Promotion>({
    id: null,
    value: '',
  })

  /** 加選項 */
  const addOn = reactive<AddOn>({
    optionalItems: [],
    serviceItems: [],
    referralAds: [],
  })

  const addOnQueryObject = computed((): Context['query'] => ({
    optionalItems: addOn.optionalItems.map(String),
    serviceItems: addOn.serviceItems.map(String),
    referralAds: addOn.referralAds.map(String),
  }))
  const setAddOnFromQuery = (query: Context['query']) => {
    // TODO: 篩選掉不存在於表單列表的項目
    addOn.optionalItems = parseOptionalItems(query)
    addOn.serviceItems = parseServiceItems(query)
    addOn.referralAds = parseReferralAds(query)
  }
  const isAddOnActive = computed((): boolean =>
    Boolean([
      ...addOn.optionalItems,
      ...addOn.serviceItems,
      ...addOn.referralAds,
    ].length)
  )

  /** Reference Code */
  const reference = ref<Reference | null>(null)

  return {
    fields,
    fieldKeys,
    validateMobile,

    invoice,

    flight,

    hertzMembershipId,
    flightNumber,

    JapanInsuranceService,

    promotion,

    addOn,
    addOnQueryObject,
    setAddOnFromQuery,
    isAddOnActive,

    reference,
  }
}
